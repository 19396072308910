var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import { getSwapTokenInfo } from './blockchainUtils';
import { ZERO_ADDR } from './constants';
import { ChainConfigs, ChainIds } from './sharedTypes';
import { isValidAddress } from './utils';
import featuredTokens from './featuredTokens.json';
export var getTokenInfo = function (chainId, address) { return __awaiter(void 0, void 0, void 0, function () {
    var sanitizedAddress, predefined, swapTokenInfo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                sanitizedAddress = (address || '').trim().toLocaleLowerCase();
                if (!isValidAddress(sanitizedAddress)) {
                    return [2 /*return*/, undefined];
                }
                predefined = PREDEFINED_SWAP_TOKENS[chainId].find(function (swapTokenInfo) { return swapTokenInfo.token == sanitizedAddress; });
                if (predefined) {
                    return [2 /*return*/, predefined];
                }
                return [4 /*yield*/, getSwapTokenInfo(chainId, sanitizedAddress).catch(function (error) {
                        console.error(error);
                    })];
            case 1:
                swapTokenInfo = _a.sent();
                if (swapTokenInfo && swapTokenInfo.token != ZERO_ADDR) {
                    return [2 /*return*/, swapTokenInfo];
                }
                return [2 /*return*/, undefined];
        }
    });
}); };
export var DexMode;
(function (DexMode) {
    DexMode["DEX_MODE_ALL"] = "DEX_MODE_ALL";
    DexMode["DEX_MODE_CUSTOM"] = "DEX_MODE_CUSTOM";
})(DexMode || (DexMode = {}));
export var DEFAULT_SWAP_SETTINGS = {
    dexMode: DexMode.DEX_MODE_ALL,
    preferredDexes: (_a = {},
        _a[ChainIds.Avalanche] = [],
        _a[ChainIds.BSC] = [],
        _a[ChainIds.Cronos] = [],
        _a[ChainIds.Cube] = [],
        _a[ChainIds.Ethereum] = [],
        _a[ChainIds.Fantom] = [],
        _a[ChainIds.Heco] = [],
        _a[ChainIds.Polygon] = [],
        _a)
};
/**
 * Validate raw user input and return valid swap dialog content properties
 * @returns
 */
export var validateSwapOptions = function (options) { return __awaiter(void 0, void 0, void 0, function () {
    var props, chainsById, chainAsString, chainId, sanitizedInputToken, fromToken, sanitizedOutputToken, toToken, slippageAsNumber, filterChainIds, sanitizedReferrer, sanitizedFixedOutput, sanitizedShowCashBack, sanitizedShowSettings;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                props = {};
                chainsById = Object.values(ChainIds).reduce(function (acc, curr) {
                    var chainConfig = ChainConfigs[curr];
                    if (chainConfig) {
                        acc[chainConfig.chainId] = curr;
                    }
                    return acc;
                }, {});
                chainAsString = String(options.chain);
                if (!isNaN(parseInt(chainAsString)) && Object.keys(chainsById).includes(chainAsString)) {
                    chainId = chainsById[chainAsString];
                    props.chainId = chainId;
                }
                if (!props.chainId) return [3 /*break*/, 4];
                sanitizedInputToken = (options.inputToken || '').trim().toLocaleLowerCase();
                if (!isValidAddress(sanitizedInputToken)) return [3 /*break*/, 2];
                return [4 /*yield*/, getTokenInfo(props.chainId, sanitizedInputToken)];
            case 1:
                fromToken = _a.sent();
                if (fromToken) {
                    props.fromToken = fromToken;
                }
                _a.label = 2;
            case 2:
                sanitizedOutputToken = (options.outputToken || '').trim().toLocaleLowerCase();
                if (!isValidAddress(sanitizedOutputToken)) return [3 /*break*/, 4];
                return [4 /*yield*/, getTokenInfo(props.chainId, sanitizedOutputToken)];
            case 3:
                toToken = _a.sent();
                if (toToken) {
                    props.toToken = toToken;
                }
                _a.label = 4;
            case 4:
                slippageAsNumber = Number(options.slippage);
                if (!isNaN(slippageAsNumber)) {
                    props.slippage = slippageAsNumber;
                }
                if (Array.isArray(options.allowedChains)) {
                    filterChainIds = options.allowedChains
                        .map(function (chain) { return chainsById[String(chain)]; })
                        .filter(function (chainId) { return chainId; });
                    props.filterChainIds = filterChainIds;
                }
                sanitizedReferrer = (options.referrer || '').trim().toLocaleLowerCase();
                if (isValidAddress(sanitizedReferrer)) {
                    props.referrer = sanitizedReferrer;
                }
                sanitizedFixedOutput = (options.fixedOutput || '').trim();
                if (sanitizedFixedOutput) {
                    props.fixedOutput = sanitizedFixedOutput;
                }
                sanitizedShowCashBack = (options.showCashBack || 'true').trim().toLocaleLowerCase() == 'true';
                props.showCashBack = sanitizedShowCashBack;
                sanitizedShowSettings = (options.showSettings || 'true').trim().toLocaleLowerCase() == 'true';
                props.showSettings = sanitizedShowSettings;
                return [2 /*return*/, props];
        }
    });
}); };
/**
 * Given an array of chain ids, create an output list with only chains that supports WalletNow's swap.
 * If the resulting filtered list is empty, return all supported chains.
 *
 * @param filterChainIds array of chain ids wanted
 * @returns see description
 */
export var ensureValidChainIds = function (filterChainIds) {
    var allSupportedChainIds = Object.values(ChainIds).filter(function (chainId) { return (ChainConfigs[chainId] || {}).walletNowSwap; });
    var filteredChainIds = allSupportedChainIds.filter(function (chainId) { return (filterChainIds || []).includes(chainId); });
    return (filteredChainIds.length > 0) ? filteredChainIds : allSupportedChainIds;
};
export var numberOfDEX = function () {
    return Object.values(ChainConfigs).flatMap(function (cfg) { return cfg.distributedExchanges; }).length;
};
export var INTERNAL_PREDEFINED_SWAP_TOKENS = (_b = {},
    _b[ChainIds.BSC] = [
        {
            chainId: ChainIds.BSC,
            symbol: 'BNB',
            name: 'Binance Coin',
            token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
            decimals: 18,
        },
        {
            chainId: ChainIds.BSC,
            symbol: 'WNOW',
            name: 'WalletNow',
            token: '0x56aa0237244c67b9a854b4efe8479cca0b105289',
            decimals: 18,
        },
        {
            chainId: ChainIds.BSC,
            symbol: 'BUSD',
            name: 'Binance-Peg BUSD Token',
            token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            decimals: 18,
        },
        {
            chainId: ChainIds.BSC,
            symbol: 'USDT',
            name: 'Binance-Peg BSC-USD',
            token: '0x55d398326f99059ff775485246999027b3197955',
            decimals: 18,
        },
        {
            chainId: ChainIds.BSC,
            symbol: 'FCF',
            name: 'French connection finance',
            token: '0x4673f018cc6d401aad0402bdbf2abcbf43dd69f3',
            decimals: 18,
        },
        {
            chainId: ChainIds.BSC,
            symbol: 'IF1',
            name: 'InfiniteOne',
            token: '0xfcac1a3ede7b55cc51e3ebff2885a67fbfe01a1a',
            decimals: 9,
        },
        {
            chainId: ChainIds.BSC,
            symbol: 'IF2',
            name: 'InfiniteTwo',
            token: '0x308811b388b3b34f0f683bced45ee6b7b0e0c6f1',
            decimals: 18,
        },
    ],
    _b[ChainIds.Fantom] = [
        {
            chainId: ChainIds.Fantom,
            symbol: 'FTM',
            name: 'Fantom',
            token: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
            decimals: 18,
        },
        {
            chainId: ChainIds.Fantom,
            symbol: 'WNOW',
            name: 'WalletNow',
            token: '0xa9cad0165c155f3998b0001b3ef30bca0aa6b591',
            decimals: 18,
        },
        {
            chainId: ChainIds.Fantom,
            symbol: 'fUSDT',
            name: 'Frapped USDT',
            token: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
            decimals: 6,
        },
        {
            chainId: ChainIds.Fantom,
            symbol: 'USDC',
            name: 'USD Coin',
            token: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
            decimals: 6,
        },
    ],
    _b[ChainIds.Polygon] = [
        {
            chainId: ChainIds.Polygon,
            symbol: 'MATIC',
            name: 'MATIC',
            token: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
            decimals: 18,
        },
        {
            chainId: ChainIds.Polygon,
            symbol: 'USDC',
            name: 'USD Coin',
            token: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
            decimals: 6,
        },
        {
            chainId: ChainIds.Polygon,
            symbol: 'USDT',
            name: '(PoS) Tether USD',
            token: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
            decimals: 6,
        },
        {
            chainId: ChainIds.Polygon,
            symbol: 'WETH',
            name: 'Wrapped Ether',
            token: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
            decimals: 18,
        },
    ],
    _b[ChainIds.Ethereum] = [
        {
            chainId: ChainIds.Ethereum,
            symbol: 'ETH',
            name: 'Ether',
            token: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            decimals: 18,
        },
        {
            chainId: ChainIds.Ethereum,
            symbol: 'USDT',
            name: 'Tether USD',
            token: '0xdac17f958d2ee523a2206206994597c13d831ec7',
            decimals: 6,
        },
        {
            chainId: ChainIds.Ethereum,
            symbol: 'BNB',
            name: 'BNB',
            token: '0xb8c77482e45f1f44de1745f52c74426c631bdd52',
            decimals: 18,
        },
        {
            chainId: ChainIds.Ethereum,
            symbol: 'USDC',
            name: 'USD Coin',
            token: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
            decimals: 6,
        },
        {
            chainId: ChainIds.Ethereum,
            symbol: 'BUSD',
            name: 'Binance USD',
            token: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
            decimals: 18,
        },
        {
            chainId: ChainIds.Ethereum,
            symbol: 'UST',
            name: 'Wrapped UST Token',
            token: '0xa47c8bf37f92abed4a126bda807a7b7498661acd',
            decimals: 18,
        },
        {
            chainId: ChainIds.Ethereum,
            symbol: 'MATIC',
            name: 'Matic Token',
            token: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
            decimals: 18,
        },
        {
            chainId: ChainIds.Ethereum,
            symbol: 'WBTC',
            name: 'Wrapped BTC',
            token: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
            decimals: 8,
        },
        {
            chainId: ChainIds.Ethereum,
            symbol: 'DAI',
            name: 'Dai Stablecoin',
            token: '0x6b175474e89094c44da98b954eedeac495271d0f',
            decimals: 18,
        },
        {
            chainId: ChainIds.Ethereum,
            symbol: 'FTM',
            name: 'Fantom Token',
            token: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
            decimals: 18,
        },
    ],
    _b[ChainIds.Heco] = [
        {
            chainId: ChainIds.Heco,
            symbol: 'HT',
            name: 'Huobi Token',
            token: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
            decimals: 18,
        },
        {
            chainId: ChainIds.Heco,
            symbol: 'WNOW',
            name: 'WalletNow',
            token: '0x81c9309d8598fb863bbd337d35dcb6036bcd51ae',
            decimals: 18,
        },
        {
            chainId: ChainIds.Heco,
            symbol: 'USDT',
            name: 'Heco-Peg USDTHECO',
            token: '0xa71edc38d189767582c38a3145b5873052c3e47a',
            decimals: 18,
        },
        {
            chainId: ChainIds.Heco,
            symbol: 'HUSD',
            name: 'Heco-Peg HUSD',
            token: '0x0298c2b32eae4da002a15f36fdf7615bea3da047',
            decimals: 8,
        },
        {
            chainId: ChainIds.Heco,
            symbol: 'USDC',
            name: 'Heco-Peg USDCHECO',
            token: '0x9362bbef4b8313a8aa9f0c9808b80577aa26b73b',
            decimals: 6,
        },
        {
            chainId: ChainIds.Heco,
            symbol: 'TUSD',
            name: 'Heco-Peg TUSD',
            token: '0x5ee41ab6edd38cdfb9f6b4e6cf7f75c87e170d98',
            decimals: 18,
        },
    ],
    _b[ChainIds.Avalanche] = [
        {
            chainId: ChainIds.Avalanche,
            symbol: 'AVAX',
            name: 'AVAX',
            token: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
            decimals: 18,
        },
        {
            chainId: ChainIds.Avalanche,
            symbol: 'USDT.e',
            name: 'Tether USD',
            token: '0xc7198437980c041c805a1edcba50c1ce5db95118',
            decimals: 6,
        },
        {
            chainId: ChainIds.Avalanche,
            symbol: 'USDC.e',
            name: 'USD Coin',
            token: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
            decimals: 6,
        },
        {
            chainId: ChainIds.Avalanche,
            symbol: 'DAI.e',
            name: 'Dai Stablecoin',
            token: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
            decimals: 18,
        },
        {
            chainId: ChainIds.Avalanche,
            symbol: 'WBTC.e',
            name: 'Wrapped BTC',
            token: '0x50b7545627a5162f82a992c33b87adc75187b218',
            decimals: 8,
        },
        {
            chainId: ChainIds.Avalanche,
            symbol: 'WETH.e',
            name: 'Wrapped Ether',
            token: '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab',
            decimals: 18,
        },
    ],
    _b[ChainIds.Cronos] = [
        {
            chainId: ChainIds.Cronos,
            symbol: 'CRO',
            name: 'Cronos Token',
            token: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23',
            decimals: 18,
        },
        {
            chainId: ChainIds.Cronos,
            symbol: 'USDC',
            name: 'USD Coin',
            token: '0xc21223249ca28397b4b6541dffaecc539bff0c59',
            decimals: 6,
        },
        {
            chainId: ChainIds.Cronos,
            symbol: 'USDT',
            name: 'Tether USD',
            token: '0x66e428c3f67a68878562e79a0234c1f83c208770',
            decimals: 6,
        },
        {
            chainId: ChainIds.Cronos,
            symbol: 'TUSD',
            name: 'TrueUSD',
            token: '0x87efb3ec1576dec8ed47e58b832bedcd86ee186e',
            decimals: 18,
        },
        {
            chainId: ChainIds.Cronos,
            symbol: 'DAI',
            name: 'Dai Stablecoin',
            token: '0xf2001b145b43032aaf5ee2884e456ccd805f677d',
            decimals: 18,
        },
        {
            chainId: ChainIds.Cronos,
            symbol: 'WBTC',
            name: 'Wrapped BTC',
            token: '0x062e66477faf219f25d27dced647bf57c3107d52',
            decimals: 8,
        },
        {
            chainId: ChainIds.Cronos,
            symbol: 'WETH',
            name: 'Wrapped Ether',
            token: '0xe44fd7fcb2b1581822d0c862b68222998a0c299a',
            decimals: 18,
        },
    ],
    _b[ChainIds.Cube] = [],
    _b);
export var PREDEFINED_SWAP_TOKENS = INTERNAL_PREDEFINED_SWAP_TOKENS;
// append featured tokens
(featuredTokens || []).forEach(function (featuredToken) {
    var _a;
    PREDEFINED_SWAP_TOKENS[featuredToken.chain].push({
        chainId: featuredToken.chain,
        token: featuredToken.address,
        symbol: featuredToken.symbol,
        name: featuredToken.name,
        decimals: featuredToken.decimals,
        vetted: (_a = featuredToken.vetted) !== null && _a !== void 0 ? _a : false
    });
});
