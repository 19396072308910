import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import './material-icons.css';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        icon: {
            marginLeft: theme.spacing(1),
        }
    });
});
var RequestFeatureButton = function () {
    var classes = useStyles();
    var t = useTranslation().t;
    var handleFeature = function () {
        var w = window;
        if (w.$sleek) {
            w.$sleek.open();
        }
    };
    return (React.createElement(Chip, { icon: React.createElement("span", { className: classes.icon }, "\uD83D\uDCA1"), label: t('SUGGEST_A_FEATURE', 'Suggest a feature'), clickable: true, onClick: handleFeature, color: "primary" }));
};
export default RequestFeatureButton;
