import React from 'react';
import ReactDOM from 'react-dom';
import Swap from './Swap';
import { validateSwapOptions } from './swapUtils';
export var renderSwap = function (domElement, options) {
    validateSwapOptions(options).then(function (swapDialogContentProps) {
        var element = React.createElement(Swap, { swapDialogContentProps: swapDialogContentProps, swapOptions: options });
        ReactDOM.render(element, domElement);
    });
};
// @ts-ignore
global.WalletNow = {
    renderSwap: renderSwap
};
