var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var EthereumOutline = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 128 128", key: "ethereum_outline" }),
        React.createElement("path", { d: "M103.2 63.6v-.2c0-.1 0-.1-.1-.2V63c0-.1-.1-.1-.1-.2 0 0 0-.1-.1-.1L65.7 1c-.1-.1-.2-.3-.3-.4h-.1c-.1-.1-.2-.2-.3-.2 0 0-.1 0-.1-.1-.1-.1-.2-.1-.3-.1h-.1c-.2-.2-.3-.2-.5-.2s-.3 0-.5.1h-.1c-.1 0-.2.1-.3.1 0 0-.1 0-.1.1-.1.1-.2.1-.3.2h-.1c-.1.1-.2.2-.3.4L25.1 62.7s0 .1-.1.1c0 .1-.1.1-.1.2v.2c0 .1 0 .1-.1.2v.8c0 .1 0 .1.1.2v.1c.1.2.2.3.3.4l.1.1.3.3s.1 0 .1.1L63 87.2h.1c.1.1.2.1.3.1h.1c.2 0 .3.1.5.1s.3 0 .5-.1h.1c.1 0 .2-.1.3-.1h.1l37.2-21.7c.1 0 .1-.1.2-.1l.1-.1.2-.2.1-.1c0-.1.1-.1.1-.2s.1-.1.1-.2 0-.1.1-.2c0-.1 0-.1.1-.2V64v-.2-.2zM62 45.6L31.9 59.3 62 9.2v36.4zm0 4.4v32L31.2 64 62 50zm4 0l30.8 14L66 82V50zm0-4.4V9.2l30.1 50.1L66 45.6z" }),
        React.createElement("path", { d: "M100.1 72L64 93.7 27.9 72c-.8-.5-1.9-.3-2.5.4-.6.7-.7 1.7-.2 2.5l37.2 52.2.1.1c.1.1.1.2.2.2l.1.1c.1.1.2.1.2.2 0 0 .1 0 .1.1.1 0 .2.1.3.1h.1c.1 0 .3.1.4.1.2 0 .3 0 .4-.1h.1c.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.1 0 .2-.1.2-.2l.1-.1.2-.2.1-.1 37.2-52.2c.5-.8.5-1.8-.2-2.5s-1.4-.8-2.3-.4zM62 97.1v22.6L34 80.4l28 16.7zm4 22.6V97.1l28-16.8-28 39.4z" })));
};
export default EthereumOutline;
