var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NoSsr from '@material-ui/core/NoSsr';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import RepeatIcon from '@material-ui/icons/Repeat';
import CurrencyFlag from 'react-currency-flags';
import CandlestickChartIcon from '../icons/CandlestickChart';
import { changeSettings } from '../store/actions';
import { getHeaders, isFreePlan } from './utils';
import { API_BASE_URL, AVAILABLE_CURRENCIES } from './constants';
import { accountFromCurrentPath } from './routes';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { ALL_LOCALES } from '../i18n/config';
var useStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        toolbar: {
            padding: 0,
            backgroundColor: 'inherit',
            justifyContent: 'center'
        },
        menuWithIcon: (_a = {
                margin: theme.spacing(0, 0.5, 0, 1),
                display: 'none'
            },
            _a[theme.breakpoints.up('md')] = {
                display: 'block',
            },
            _a),
        flag: {
            height: '11px',
            border: (theme.palette.type == 'light') ? '1px solid #ddd' : 'none',
        },
        currency: {
            paddingLeft: theme.spacing(1),
            fontSize: '75%'
        },
    });
});
var GlobalActions = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var location = useLocation();
    var _a = props.settings, mode = _a.mode, maskedSummary = _a.maskedSummary;
    var _b = useState(undefined), languageMenu = _b[0], setLanguageMenu = _b[1];
    var _c = useState(undefined), currencyMenu = _c[0], setCurrencyMenu = _c[1];
    var underSettings = location.pathname.endsWith('/settings/') || location.pathname.endsWith('/settings');
    var underAccount = location.pathname.startsWith('/account/');
    var underAccountNotSettings = underAccount && !underSettings;
    var showAccount = underAccountNotSettings && !Boolean(props.hideAccount);
    var underLanding = !underAccount;
    var currentCurrency = props.settings.preferredCurrency || 'USD';
    var currentLocale = props.settings.locale || 'en-US';
    var currencies = ['USD'].concat((underLanding) ? AVAILABLE_CURRENCIES : Object.keys(props.accountData.conversionRates || {}).sort());
    var freePlan = isFreePlan(props.accountData);
    var handleLanguageIconClick = function (event) {
        setLanguageMenu(event.currentTarget);
    };
    var handleLanguageMenuClose = function () {
        setLanguageMenu(undefined);
    };
    var handleLanguageMenuClick = function (event) {
        saveSetting('locale', event.currentTarget.lang);
        setLanguageMenu(undefined);
    };
    var handleCurrencyIconClick = function (event) {
        setCurrencyMenu(event.currentTarget);
    };
    var handleCurrencyMenuClose = function () {
        setCurrencyMenu(undefined);
    };
    var handleCurrencyMenuClick = function (event) {
        saveSetting('preferredCurrency', event.currentTarget.dataset.currency);
        setCurrencyMenu(undefined);
    };
    var handleTogglePaletteType = function () {
        props.changeSettings({ mode: (mode == 'light') ? 'dark' : 'light' });
    };
    var handleToggleMaskedSummary = function () {
        props.changeSettings({ maskedSummary: !maskedSummary });
    };
    var handlePlansClick = function () {
        props.history.push('/plans');
        setCurrencyMenu(undefined);
    };
    var handleAccountClick = function () {
        var account = accountFromCurrentPath();
        if (account) {
            props.history.push("/account/".concat(account, "/settings"));
        }
    };
    var handleSwap = function () {
        props.history.push('/swap');
    };
    var handleChart = function () {
        props.history.push('/chart');
    };
    var saveSetting = function (settingName, settingValue) {
        var _a, _b;
        var account = accountFromCurrentPath();
        // sanity check
        if (!['preferredCurrency', 'locale'].includes(settingName)) {
            console.log('Invalid setting name');
            return;
        }
        // always save locally
        localStorage.setItem(settingName, settingValue);
        // update state
        props.changeSettings((_a = {}, _a[settingName] = settingValue, _a));
        // not logged - don't even try to save on the backend
        if (!props.connectedAccounts[account]) {
            return;
        }
        // best effort to save the setting on the backend
        fetch("".concat(API_BASE_URL, "/accounts/").concat(account, "/settings"), {
            method: 'POST',
            mode: 'cors',
            headers: __assign({ 'Content-Type': 'application/json' }, getHeaders(props.connectedAccounts, account)),
            body: JSON.stringify((_b = {},
                _b[settingName] = settingValue,
                _b))
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Toolbar, { className: classes.toolbar },
            showAccount && (React.createElement(Tooltip, { title: "".concat(t('ACCOUNT', 'Account')), enterDelay: 300 },
                React.createElement(IconButton, { color: "inherit", onClick: handleAccountClick, "aria-label": t('ACCOUNT', 'Account'), "data-ga-event-category": "header", "data-ga-event-action": "dark" },
                    React.createElement(AccountCircleIcon, null)))),
            React.createElement(Tooltip, { title: "".concat(t('CHANGE_LANGUAGE', 'Change Language')), enterDelay: 300 },
                React.createElement(Button, { color: "inherit", "aria-owns": languageMenu ? 'language-menu' : undefined, "aria-haspopup": "true", "aria-label": t('CHANGE_LANGUAGE', 'Change Language'), onClick: handleLanguageIconClick, "data-ga-event-category": "header", "data-ga-event-action": "language" },
                    React.createElement(LanguageIcon, null),
                    React.createElement("span", { className: classes.menuWithIcon }, (ALL_LOCALES[currentLocale] || ALL_LOCALES['en-US']).name),
                    React.createElement(ExpandMoreIcon, { fontSize: "small" }))),
            React.createElement(NoSsr, { defer: true },
                React.createElement(Menu, { id: "language-menu", anchorEl: languageMenu, open: Boolean(languageMenu), onClose: handleLanguageMenuClose }, Object.keys(ALL_LOCALES).map(function (localeCode) { return (React.createElement(MenuItem, { component: "a", "data-no-link": "true", key: localeCode, selected: currentLocale === localeCode, onClick: handleLanguageMenuClick, lang: localeCode, hrefLang: localeCode }, ALL_LOCALES[localeCode].name)); }))),
            React.createElement(Tooltip, { title: "".concat(t('CURRENCY', 'Currency')), enterDelay: 300 },
                React.createElement(Button, { color: "inherit", "aria-owns": currencyMenu ? 'currency-menu' : undefined, "aria-haspopup": "true", "aria-label": t('CHANGE_CURRENCY', 'Change Currency'), onClick: handleCurrencyIconClick, "data-ga-event-category": "header", "data-ga-event-action": "currency" },
                    React.createElement(MonetizationOnOutlinedIcon, null),
                    React.createElement("span", { className: classes.menuWithIcon }, currentCurrency),
                    React.createElement(ExpandMoreIcon, { fontSize: "small" }))),
            React.createElement(NoSsr, { defer: true },
                React.createElement(Menu, { id: "currency-menu", anchorEl: currencyMenu, open: Boolean(currencyMenu), onClose: handleCurrencyMenuClose },
                    currencies.map(function (currency) { return (React.createElement(MenuItem, { component: "a", "data-no-link": "true", key: currency, selected: currentCurrency === currency, onClick: handleCurrencyMenuClick, "data-currency": currency },
                        React.createElement(CurrencyFlag, { className: classes.flag, currency: currency, size: "sm" }),
                        React.createElement("span", { className: classes.currency }, currency))); }),
                    !underLanding && freePlan && (React.createElement(MenuItem, { component: "a", "data-no-link": "true", onClick: handlePlansClick }, t('MORE_CURRENCIES_AVAILABLE_UPGRADE_NOW', 'More currencies available, Upgrade now!'))))),
            React.createElement(Tooltip, { title: "".concat(t('TOGGLE_THEME', 'Toggle Theme')), enterDelay: 300 },
                React.createElement(IconButton, { color: "inherit", onClick: handleTogglePaletteType, "aria-label": t('TOGGLE_THEME', 'Toggle Theme'), "data-ga-event-category": "header", "data-ga-event-action": "dark" }, mode === 'light' ? React.createElement(Brightness4Icon, null) : React.createElement(Brightness7Icon, null))),
            React.createElement(Tooltip, { title: maskedSummary ? "".concat(t('SHOW_TOTALS', 'Show Totals')) : "".concat(t('HIDE_TOTALS', 'Hide Totals')), enterDelay: 300 },
                React.createElement(IconButton, { color: "inherit", onClick: handleToggleMaskedSummary, "aria-label": maskedSummary ? t('SHOW_TOTALS', 'Show Totals') : t('HIDE_TOTALS', 'Hide Totals'), "data-ga-event-category": "header", "data-ga-event-action": "masked_summary" }, maskedSummary ? React.createElement(VisibilityIcon, null) : React.createElement(VisibilityOffIcon, null))),
            !Boolean(props.hideSwap) && (React.createElement(Tooltip, { title: "".concat(t('SWAP', 'Swap')), enterDelay: 300 },
                React.createElement(IconButton, { color: "inherit", onClick: handleSwap, "aria-label": t('SWAP', 'Swap') },
                    React.createElement(RepeatIcon, null)))),
            !Boolean(props.hideChart) && (React.createElement(Tooltip, { title: "".concat(t('CHART', 'Chart')), enterDelay: 300 },
                React.createElement(Badge, { color: "primary", overlap: "circular", badgeContent: "new" },
                    React.createElement(IconButton, { color: "inherit", onClick: handleChart, "aria-label": t('CHART', 'Chart') },
                        React.createElement(CandlestickChartIcon, null))))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ changeSettings: changeSettings }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GlobalActions));
