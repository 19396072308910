var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ACTION_TYPES } from '../app/constants';
export var DefaultConnectedAccounts = JSON.parse(localStorage.getItem('connectedAccounts') || '{}');
export default function connectedAccountsReducer(state, action) {
    var _a;
    if (state === void 0) { state = DefaultConnectedAccounts; }
    var newState = state;
    switch (action.type) {
        case ACTION_TYPES.CONNECT_ACCOUNT:
            newState = __assign(__assign({}, state), (_a = {}, _a[action.account] = action.apiSignature, _a));
            break;
        case ACTION_TYPES.DISCONNECT_ACCOUNT:
            newState = __assign({}, state);
            delete newState[action.account];
            break;
    }
    if (newState !== state) {
        localStorage.setItem('connectedAccounts', JSON.stringify(newState));
    }
    return newState;
}
