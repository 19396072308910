var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { AccountPlan } from './sharedTypes';
import { currencyFormat, formatBalance } from './utils';
import { customTableStyles } from '../theme/themes';
import Medal from '../icons/Medal';
import { PLAN_COLORS, WNOW_CHAINS } from './constants';
import SwapDialog from './SwapDialog';
var useStyles = makeStyles(function (theme) {
    return createStyles(__assign(__assign({}, customTableStyles(theme)), { infoLink: {
            display: 'flex',
            marginRight: theme.spacing(3)
        }, popoverGrid: {
            padding: theme.spacing(1),
        }, openIcon: {
            marginLeft: theme.spacing(1),
        }, table: {
            marginBottom: theme.spacing(1)
        }, bottomInfo: {
            paddingBottom: theme.spacing(1)
        }, currentPlanContainer: {
            display: 'flex'
        }, currentPlanIcon: {
            width: theme.spacing(8),
            height: theme.spacing(8),
        }, currentPlanTitleContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }, currentPlanTitle: {
            fontWeight: 'bold',
            lineHeight: 1
        }, iconContainer: {
            display: 'flex',
        }, suggestedPlan: {
            display: 'flex',
            alignItems: 'center',
        }, divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }, benefitDescription: {
            textAlign: 'center',
        }, buttonContainer: {
            textAlign: 'center',
        } }));
});
;
var styleColumns = [
    { flexBasis: '80%', minWidth: 200, display: 'flex', alignItems: 'center' },
    { flexBasis: '20%', minWidth: 50, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },
];
var PlanDialogContent = function (props) {
    var classes = useStyles({});
    var t = useTranslation().t;
    var _a = useState(false), showSwapDialog = _a[0], setShowSwapDialog = _a[1];
    var locale = props.settings.locale;
    var membershipInfo = props.membershipInfo || {};
    var WNOW = membershipInfo.WNOW;
    var currency = props.settings.preferredCurrency || 'USD';
    var rate = (props.accountData.conversionRates || {})[currency] || 1.0;
    var activeMembershipPlan = membershipInfo.activeMembershipPlan || 0;
    var activeBenefit = membershipInfo.activeBenefit;
    var remainingBenefitDays = (activeBenefit && activeBenefit.expiresAt) ?
        Math.round((new Date(activeBenefit.expiresAt).getTime() - Date.now()) / 1000 / 60 / 60 / 24)
        : undefined;
    if (!WNOW) {
        return null;
    }
    var calcSuggested = function (value) {
        return value + (value / 10.0);
    };
    var getPlanTranslated = function (plan) {
        var plans = {
            0: t('PLAN_0', 'Free'),
            1: t('PLAN_1', 'Bronze'),
            2: t('PLAN_2', 'Silver'),
            3: t('PLAN_3', 'Gold'),
            4: t('PLAN_4', 'Diamond')
        };
        return plans[plan];
    };
    var handleCloseSwapDialog = function () {
        setShowSwapDialog(false);
    };
    var handleOpenSwapDialog = function () {
        setShowSwapDialog(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SwapDialog, { open: showSwapDialog, onClose: handleCloseSwapDialog, filterChainIds: WNOW_CHAINS, fixedOutput: "WNOW", showWalletConnectionWidget: true, showCashBack: true }),
        React.createElement("div", { className: classes.currentPlanContainer },
            React.createElement("div", null,
                React.createElement(Medal, __assign({ className: classes.currentPlanIcon }, PLAN_COLORS[activeMembershipPlan]))),
            React.createElement("div", { className: classes.currentPlanTitleContainer },
                React.createElement(Typography, { className: classes.currentPlanTitle, variant: "h6" }, t('CURRENT_PLAN', 'Current Plan')),
                React.createElement(Typography, { variant: "h6" },
                    React.createElement("span", null, getPlanTranslated(activeMembershipPlan)),
                    activeBenefit &&
                        React.createElement("span", null,
                            "\u00A0(",
                            t(activeBenefit.type),
                            ")")))),
        React.createElement("div", { className: classes.benefitDescription }, activeBenefit &&
            React.createElement("div", null,
                React.createElement("b", null, t(activeBenefit.type)),
                " ",
                t('OFFERED_BY', 'offered by'),
                " ",
                activeBenefit.offeredBy,
                remainingBenefitDays != undefined &&
                    React.createElement("span", null,
                        ": ",
                        Math.max(0, remainingBenefitDays),
                        " ",
                        t('DAYS_LEFT', 'days left')))),
        React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] }, t('AVERAGE_WNOW_PRICE', 'Average $WNOW price')),
            React.createElement("div", { style: styleColumns[1] }, currencyFormat(locale, currency, WNOW.priceUSD / rate))),
        React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] }, t('TOTAL_WNOW_TOKENS', 'Total $WNOW tokens')),
            React.createElement("div", { style: styleColumns[1] }, formatBalance(locale, parseFloat(WNOW.totalAmount)))),
        React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] }, t('LIQUIDITY_BONUS', 'Liquidity Bonus')),
            React.createElement("div", { style: styleColumns[1] }, formatBalance(locale, parseFloat(WNOW.liquidityBonusAmount)))),
        React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] }, t('YOUR_PORTFOLIO', 'Your Portfolio')),
            React.createElement("div", { style: styleColumns[1] }, currencyFormat(locale, currency, props.membershipInfo.portfolioAmountUSD / rate))),
        (activeMembershipPlan == AccountPlan.Silver) && (React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] },
                React.createElement("div", null, t('MAX_MONITORABLE_SILVER', 'Max. monitorable in Silver')),
                React.createElement("div", { className: classes.iconContainer },
                    React.createElement(Medal, __assign({}, PLAN_COLORS[AccountPlan.Silver])))),
            React.createElement("div", { style: styleColumns[1] }, currencyFormat(locale, currency, props.membershipInfo.maxSilverAmountUSD / rate)))),
        (WNOW.missingTokensToSilver > 0) && (React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] },
                React.createElement("div", null, t('TOKENS_NEEDED_SILVER', 'Tokens missing for Silver')),
                React.createElement("div", { className: classes.iconContainer },
                    React.createElement(Medal, __assign({}, PLAN_COLORS[AccountPlan.Silver])))),
            React.createElement("div", { style: styleColumns[1] },
                formatBalance(locale, calcSuggested(WNOW.missingTokensToSilver)),
                "*"))),
        (activeMembershipPlan == AccountPlan.Gold) && (React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] },
                React.createElement("div", null, t('MAX_MONITORABLE_GOLD', 'Max. monitorable in Gold')),
                React.createElement("div", { className: classes.iconContainer },
                    React.createElement(Medal, __assign({}, PLAN_COLORS[AccountPlan.Gold])))),
            React.createElement("div", { style: styleColumns[1] }, currencyFormat(locale, currency, props.membershipInfo.maxGoldAmountUSD / rate)))),
        (WNOW.missingTokensToGold > 0) && (React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] },
                React.createElement("div", null, t('TOKENS_NEEDED_GOLD', 'Tokens missing for Gold')),
                React.createElement("div", { className: classes.iconContainer },
                    React.createElement(Medal, __assign({}, PLAN_COLORS[AccountPlan.Gold])))),
            React.createElement("div", { style: styleColumns[1] },
                formatBalance(locale, calcSuggested(WNOW.missingTokensToGold)),
                "*"))),
        (activeMembershipPlan == AccountPlan.Diamond) && (React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] },
                React.createElement("div", null, t('MAX_MONITORABLE_DIAMOND', 'Max. monitorable in Diamond')),
                React.createElement("div", { className: classes.iconContainer },
                    React.createElement(Medal, __assign({}, PLAN_COLORS[AccountPlan.Diamond])))),
            React.createElement("div", { style: styleColumns[1] }, currencyFormat(locale, currency, props.membershipInfo.maxDiamondAmountUSD / rate)))),
        (WNOW.missingTokensToDiamond > 0) && (React.createElement("div", { className: classes.customTableRow },
            React.createElement("div", { style: styleColumns[0] },
                React.createElement("div", null, t('TOKENS_NEEDED_DIAMOND', 'Tokens missing for Diamond')),
                React.createElement("div", { className: classes.iconContainer },
                    React.createElement(Medal, __assign({}, PLAN_COLORS[AccountPlan.Diamond])))),
            React.createElement("div", { style: styleColumns[1] },
                formatBalance(locale, calcSuggested(WNOW.missingTokensToDiamond)),
                "*"))),
        React.createElement(Divider, { className: classes.divider }),
        (WNOW.missingTokensToSilver > 0 || WNOW.missingTokensToGold > 0 || WNOW.missingTokensToDiamond > 0) &&
            React.createElement("div", { className: classes.bottomInfo },
                React.createElement(Typography, { variant: "caption" },
                    "* ",
                    t('INCLUDES_MARGIN_FOR_PRICE_FLUCTUATION', 'Includes a {{percent}}% margin for price fluctuation', { percent: 10 }))),
        React.createElement("div", { className: classes.bottomInfo },
            React.createElement(Link, { className: classes.infoLink, target: "_blank", rel: "noopener", href: 'https://docs.walletnow.net/pricing' },
                t('LEARN_MORE_ABOUT_MEMBERSHIP_PLANS', 'Learn more about our membership plans'),
                React.createElement(OpenInNewIcon, { className: classes.openIcon, fontSize: "small" }))),
        React.createElement("div", { className: classes.buttonContainer },
            React.createElement(Button, { variant: "contained", color: "primary", onClick: handleOpenSwapDialog }, t('BUY_WNOW', 'Buy $WNOW')))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(PlanDialogContent);
