var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Multicall } from 'ethereum-multicall';
import { ContractContext } from '../../common/contract-context';
import { ErrorCodes } from '../../common/errors/error-codes';
import { PancakeswapError } from '../../common/errors/pancakeswap-error';
var TokensFactory = /** @class */ (function () {
    function TokensFactory(_ethersProvider, _multicallContractAddress) {
        this._ethersProvider = _ethersProvider;
        this._multicallContractAddress = _multicallContractAddress;
        this._multicall = new Multicall({
            ethersProvider: this._ethersProvider.provider,
            multicallCustomContractAddress: this._multicallContractAddress,
        });
    }
    /**
     * Get the tokens details
     */
    TokensFactory.prototype.getTokens = function (tokenContractAddresses) {
        return __awaiter(this, void 0, void 0, function () {
            var SYMBOL, DECIMALS, NAME, contractCallContexts, i, contractCallContext, contractCallResults, tokens, result, tokenInfo, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        SYMBOL = 0;
                        DECIMALS = 1;
                        NAME = 2;
                        contractCallContexts = [];
                        for (i = 0; i < tokenContractAddresses.length; i++) {
                            contractCallContext = {
                                reference: "token".concat(i),
                                contractAddress: tokenContractAddresses[i],
                                abi: ContractContext.erc20Abi,
                                calls: [
                                    {
                                        reference: "symbol",
                                        methodName: 'symbol',
                                        methodParameters: [],
                                    },
                                    {
                                        reference: "decimals",
                                        methodName: 'decimals',
                                        methodParameters: [],
                                    },
                                    {
                                        reference: "name",
                                        methodName: 'name',
                                        methodParameters: [],
                                    },
                                ],
                            };
                            contractCallContexts.push(contractCallContext);
                        }
                        return [4 /*yield*/, this._multicall.call(contractCallContexts)];
                    case 1:
                        contractCallResults = _a.sent();
                        tokens = [];
                        for (result in contractCallResults.results) {
                            tokenInfo = contractCallResults.results[result];
                            tokens.push({
                                chainId: this._ethersProvider.network().chainId,
                                contractAddress: tokenInfo.originalContractCallContext.contractAddress,
                                symbol: tokenInfo.callsReturnContext[SYMBOL].returnValues[0],
                                decimals: tokenInfo.callsReturnContext[DECIMALS].returnValues[0],
                                name: tokenInfo.callsReturnContext[NAME].returnValues[0],
                            });
                        }
                        return [2 /*return*/, tokens];
                    case 2:
                        error_1 = _a.sent();
                        throw new PancakeswapError('invalid from or to contract tokens', ErrorCodes.invalidFromOrToContractToken);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return TokensFactory;
}());
export { TokensFactory };
