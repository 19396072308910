var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var BSCOutline = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "3 3 14 14", key: "bsc_outline" }),
        React.createElement("path", { d: "M7.572 9.002L10 6.575l2.429 2.429L13.84 7.59 10 3.75 6.16 7.59l1.412 1.412zM3.75 10l1.412-1.412L6.575 10l-1.413 1.412L3.75 10zm3.822.998L10 13.425l2.429-2.429 1.412 1.412L10 16.25l-3.84-3.84-.002-.002 1.414-1.41zM13.425 10l1.412-1.412L16.25 10l-1.412 1.412L13.425 10zm-1.993-.001h.002V10L10 11.434l-1.432-1.431L8.566 10l.002-.002.25-.251.123-.122L10 8.566 11.433 10z", fill: "currentColor" })));
};
export default BSCOutline;
