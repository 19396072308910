import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
var SnackbarCloseButton = function (props) {
    var closeSnackbar = useSnackbar().closeSnackbar;
    return (React.createElement(IconButton, { onClick: function () { return closeSnackbar(props.id); } },
        React.createElement(CloseIcon, null)));
};
export default SnackbarCloseButton;
