var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Polygon = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 32 32", key: "polygon" }),
        React.createElement("circle", { cx: "16", cy: "16", r: "16", fill: "#8247E5" }),
        React.createElement("path", { d: "M21.144 12.89c-.366-.19-.836-.19-1.254 0l-2.924 1.567-1.984.997-2.872 1.567c-.366.19-.836.19-1.254 0l-2.245-1.235a1.142 1.142 0 01-.627-.997v-2.374c0-.38.21-.76.627-.997l2.245-1.186c.366-.19.836-.19 1.254 0l2.245 1.234c.366.19.627.57.627.997v1.567l1.984-1.045v-1.614c0-.38-.209-.76-.627-.997l-4.177-2.232c-.366-.19-.836-.19-1.253 0l-4.282 2.28c-.418.19-.627.57-.627.949v4.463c0 .38.209.76.627.997l4.23 2.231c.365.19.835.19 1.253 0l2.872-1.519 1.984-1.044 2.872-1.52c.366-.19.836-.19 1.253 0l2.246 1.187c.365.19.626.57.626.997v2.374c0 .38-.208.76-.626.997l-2.193 1.187c-.366.19-.836.19-1.254 0l-2.245-1.187a1.142 1.142 0 01-.627-.997v-1.52l-1.984 1.045v1.567c0 .38.209.76.627.997l4.23 2.232c.365.19.835.19 1.253 0l4.23-2.232c.365-.19.626-.57.626-.997v-4.51c0-.38-.209-.76-.627-.997l-4.23-2.232z", fill: "#fff" })));
};
export default Polygon;
