var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import i18n from 'i18next';
import { memoizeOne } from 'memoize-one';
import { v4 as uuidv4 } from 'uuid';
import { SortModes } from '../store/settingsReducer';
import { AccountPlan, ChainConfigs, ChainIds } from './sharedTypes';
import store from '../store/store';
import JSON5 from 'json5';
import { utils } from 'ethers';
export var ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
export var shortenAddress = function (address) {
    if (!address) {
        return '';
    }
    return "".concat(address.slice(0, 6), "...").concat(address.slice(address.length - 4));
};
export var isValidAddress = function (address) {
    if (!address) {
        return false;
    }
    var trimmed = (address || '').trim();
    return ADDRESS_REGEX.test(trimmed);
};
export var isSameAddress = function (address1, address2) {
    var valid1 = isValidAddress(address1);
    var valid2 = isValidAddress(address2);
    if (!valid1 || !valid2) {
        return false;
    }
    return (address1 === null || address1 === void 0 ? void 0 : address1.trim().toLowerCase()) == (address2 === null || address2 === void 0 ? void 0 : address2.trim().toLowerCase());
};
export var getHeaders = function (connectedAccounts, account) {
    var requestId = uuidv4();
    var headers = {
        'x-request-id': requestId
    };
    var signature = (connectedAccounts || {})[account];
    if (signature) {
        headers['x-api-signature'] = signature;
    }
    return headers;
};
var getCurrencyFormat = function (locale, currency, setSignificantDigitsMinMax) {
    if (locale === void 0) { locale = 'en-US'; }
    if (currency === void 0) { currency = 'USD'; }
    if (setSignificantDigitsMinMax === void 0) { setSignificantDigitsMinMax = false; }
    var significantDigits = setSignificantDigitsMinMax ?
        {
            minimumSignificantDigits: 2,
            maximumSignificantDigits: 2
        } : {};
    return new Intl.NumberFormat(locale, __assign({ style: 'currency', currency: currency }, significantDigits));
};
var memoizedIntlCurrencyFormat = memoizeOne(getCurrencyFormat);
var getIntlNumberFormat = function (locale, fractionDigits) {
    if (locale === void 0) { locale = 'en-US'; }
    if (fractionDigits === void 0) { fractionDigits = 2; }
    return new Intl.NumberFormat(locale, { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits });
};
export var memoizedIntlNumberFormat = memoizeOne(getIntlNumberFormat);
export var formatPercent = function (locale, value, showSignal) {
    if (locale === void 0) { locale = 'en-US'; }
    if (showSignal === void 0) { showSignal = false; }
    var intlNumberFormat = memoizedIntlNumberFormat(locale, 2);
    var text = intlNumberFormat.format(value) + '%';
    if (showSignal && value > 0) {
        text = '+' + text;
    }
    return text;
};
export var currencyFormat = function (locale, currency, value, showSignificantDigitsBelowOne) {
    if (locale === void 0) { locale = 'en-US'; }
    if (currency === void 0) { currency = 'USD'; }
    if (showSignificantDigitsBelowOne === void 0) { showSignificantDigitsBelowOne = false; }
    var setSignificantDigitsMinMax = (showSignificantDigitsBelowOne && value < 1);
    var intlNumberFormat = memoizedIntlCurrencyFormat(locale, currency, setSignificantDigitsMinMax);
    return intlNumberFormat.format(value);
};
var getCurrencyFormatOptions = function (locale, currency) {
    if (locale === void 0) { locale = 'en-US'; }
    if (currency === void 0) { currency = 'USD'; }
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    }).resolvedOptions();
};
var memoizedIntlCurrencyFormatOptions = memoizeOne(getCurrencyFormatOptions);
export var currencyFormatWithoutSymbol = function (locale, currency, value, showSignificantDigitsBelowOne) {
    if (locale === void 0) { locale = 'en-US'; }
    if (currency === void 0) { currency = 'USD'; }
    if (showSignificantDigitsBelowOne === void 0) { showSignificantDigitsBelowOne = false; }
    var setSignificantDigitsMinMax = (showSignificantDigitsBelowOne && value < 1);
    var significantDigits = setSignificantDigitsMinMax ?
        {
            minimumSignificantDigits: 2,
            maximumSignificantDigits: 2
        } : {};
    var intlCurrencyFormatOptions = memoizedIntlCurrencyFormatOptions(locale, currency);
    return (value).toLocaleString(locale, __assign(__assign(__assign({}, intlCurrencyFormatOptions), { style: 'decimal' }), significantDigits));
};
export var formatBalance = function (locale, balance) {
    if (locale === void 0) { locale = 'en-US'; }
    if (balance === void 0) { balance = 0; }
    var fractionDigits = 2;
    var suffix = '';
    var divider = 1;
    if (balance >= 1000000) {
        suffix = 'M';
        divider = 1000000;
    }
    else if (balance >= 1000) {
        suffix = 'k';
        divider = 1000;
    }
    else if (balance > 0 && balance < 1) {
        var i = 0;
        var value = balance;
        while (i < 20 && value < 1) {
            i++;
            value *= 10;
        }
        fractionDigits = i + 1;
    }
    var numberFormat = memoizedIntlNumberFormat(locale, fractionDigits);
    return numberFormat.format(balance / divider) + suffix;
};
export var localeStringToNumber = function (locale, value) {
    if (locale === void 0) { locale = 'en-US'; }
    var numberFormat = memoizedIntlNumberFormat(locale);
    var sample = numberFormat.format(1.1);
    var cleanPattern = new RegExp("[^-+0-9".concat(sample.charAt(1), "]"), 'g');
    var cleaned = value.replace(cleanPattern, '');
    var normalized = cleaned.replace(sample.charAt(1), '.');
    return parseFloat(normalized);
};
export var isValidNumber = function (locale, value) {
    if (locale === void 0) { locale = 'en-US'; }
    var num = localeStringToNumber(locale, value);
    return !isNaN(num);
};
export var isFreePlan = function (accountData) {
    return ((accountData.membershipInfo || {}).activeMembershipPlan || 0) == 0;
};
export var calculateNetProfit = function (accountData) {
    accountData.wallets.forEach(function (wallet) {
        wallet.investments.forEach(function (investment) {
            investment.totalNetProfitUSD = (investment.totalYieldUSD || 0) - (investment.totalFeeSpentUSD || 0);
        });
    });
    return accountData;
};
export var filterInvestments = function (investments, filterProtocol, searchText) {
    return investments.filter(function (investment) {
        // filter by protocol first
        if (filterProtocol != '' && filterProtocol != investment.protocol) {
            return false;
        }
        var trimmedSearchText = (searchText || '').trim().toLowerCase();
        if (trimmedSearchText === '') {
            return true;
        }
        // search the string in the invetment properties
        var found = ['protocol', 'chain', 'name', 'link'].some(function (prop) {
            return (investment[prop] || '').toLowerCase().includes(trimmedSearchText);
        });
        if (found) {
            return true;
        }
        // search the string in the tokens
        return (investment.tokens || []).some(function (token) {
            return Object.keys(token).some(function (prop) {
                return token[prop].toString().toLowerCase().includes(trimmedSearchText);
            });
        });
    });
};
export var sortInvestments = function (investments, sortColumn, sortMode) {
    var compareFunc = function (a, b) {
        var valueA = a[sortColumn];
        var valueB = b[sortColumn];
        if (valueA === undefined && valueB === undefined) {
            return 0;
        }
        if (valueA === undefined) {
            valueA = (typeof valueB === 'number') ? 0 : '';
        }
        if (valueB === undefined) {
            valueB = (typeof valueA === 'number') ? 0 : '';
        }
        if (typeof valueA === 'number' && typeof valueB === 'number') {
            if (sortMode == SortModes.ASC) {
                return valueA - valueB;
            }
            return valueB - valueA;
        }
        else {
            if (sortMode == SortModes.ASC) {
                return ('' + valueA).localeCompare('' + valueB);
            }
            return ('' + valueB).localeCompare('' + valueA);
        }
    };
    investments.sort(compareFunc);
};
export var sortTokens = function (tokens, sortColumn, sortMode) {
    var compareFunc = function (a, b) {
        var valueA = a[sortColumn];
        var valueB = b[sortColumn];
        if (valueA === undefined && valueB === undefined) {
            return 0;
        }
        if (valueA === undefined) {
            valueA = (typeof valueB === 'number') ? 0 : '';
        }
        if (valueB === undefined) {
            valueB = (typeof valueA === 'number') ? 0 : '';
        }
        if (typeof valueA === 'number' && typeof valueB === 'number') {
            if (sortMode == SortModes.ASC) {
                return valueA - valueB;
            }
            return valueB - valueA;
        }
        else {
            if (sortMode == SortModes.ASC) {
                return ('' + valueA).localeCompare('' + valueB);
            }
            return ('' + valueB).localeCompare('' + valueA);
        }
    };
    tokens.sort(compareFunc);
};
export var filterNfts = function (nfts) {
    return (nfts || []).filter(function (nft) { return nft.uri && nft.uri != ''; });
};
export var fixExternalUrl = function (url) {
    var lowerUrl = (url || '').toLowerCase();
    if (lowerUrl.startsWith('http://') || lowerUrl.startsWith('https://')) {
        return url;
    }
    return "https://".concat(url);
};
export var getColumnTranslated = function (column) {
    var columns = {
        'PROTOCOL': i18n.t('COLUMN_PROTOCOL', 'Protocol'),
        'NAME': i18n.t('COLUMN_NAME', 'Name'),
        'TOTALAMOUNTUSD': i18n.t('COLUMN_TOTALAMOUNTUSD', 'Amount'),
        'TOTALYIELDUSD': i18n.t('COLUMN_TOTALYIELDUSD', 'Yield'),
        'PENDINGYIELDUSD': i18n.t('COLUMN_PENDINGYIELDUSD', 'Pending'),
        'TOTALDEBTUSD': i18n.t('COLUMN_TOTALDEBTUSD', 'Debt'),
        'TOTALFEESPENTUSD': i18n.t('COLUMN_TOTALFEESPENTUSD', 'Fee Spent'),
        'TOTALNETPROFITUSD': i18n.t('COLUMN_TOTALNETPROFITUSD', 'Net Profit'),
    };
    return columns[column];
};
var csvEscape = function (s) {
    return '"' + s.toString().replace(/\\/g, '\\\\').replace(/\"/g, '\\""') + '"';
};
var writeCsvLine = function (csvLines, fields) {
    var line = fields.map(function (field) { return csvEscape(field || ''); }).join(',');
    csvLines.push(line);
};
export var generateCsvFile = function (data) {
    var columns = ['wallet', 'chain', 'protocol', 'investment', 'tokenAddress', 'tokenSymbol', 'type', 'quantity', 'amountUSD', 'amountLocalCurrency'];
    var csvLines = [];
    writeCsvLine(csvLines, columns);
    var preferredCurrency = data.userSettings.preferredCurrency;
    var rates = data.conversionRates;
    for (var _i = 0, _a = data.wallets; _i < _a.length; _i++) {
        var wallet = _a[_i];
        var walletAddress = wallet.address;
        for (var _b = 0, _c = wallet.investments; _b < _c.length; _b++) {
            var investment = _c[_b];
            var chain = investment.chain, protocol = investment.protocol, name_1 = investment.name;
            for (var _d = 0, _e = investment.tokens; _d < _e.length; _d++) {
                var investmentToken = _e[_d];
                var type = investmentToken.type;
                if (investmentToken.subTokens && investmentToken.subTokens.length > 0) {
                    for (var _f = 0, _g = investmentToken.subTokens; _f < _g.length; _f++) {
                        var subToken = _g[_f];
                        var token = subToken.token, symbol = subToken.symbol, amount = subToken.amount, amountUSD = subToken.amountUSD;
                        var amountLocalCurrency = toPreferredCurrency(amountUSD, preferredCurrency, rates);
                        writeCsvLine(csvLines, [walletAddress, chain, protocol, name_1, token, symbol, type, amount, amountUSD, amountLocalCurrency]);
                    }
                }
                else {
                    var token = investmentToken.token, symbol = investmentToken.symbol, amount = investmentToken.amount, amountUSD = investmentToken.amountUSD;
                    var amountLocalCurrency = toPreferredCurrency(amountUSD, preferredCurrency, rates);
                    writeCsvLine(csvLines, [walletAddress, chain, protocol, name_1, token, symbol, type, amount, amountUSD, amountLocalCurrency]);
                }
            }
        }
    }
    var csvFile = csvLines.join('\n');
    return csvFile;
};
export var isJSON = function (str) {
    try {
        parseJSON(str);
        return true;
    }
    catch (_a) {
    }
    return false;
};
export var parseJSON = function (str) {
    try {
        return JSON.parse(str);
    }
    catch (_a) {
        // For some reason JSON5 doesn't like '\n' but we can safely remove them
        return JSON5.parse(str.replaceAll('\n', ''));
    }
};
export var toPreferredCurrency = function (amountUSD, preferredCurrency, conversionRates) {
    if (!conversionRates || !preferredCurrency)
        return amountUSD;
    var rate = conversionRates[preferredCurrency];
    if (!rate)
        return amountUSD;
    return amountUSD / rate;
};
export var downloadFile = function (filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
export var downloadCsv = function () {
    var currentState = store.getState();
    var accountData = currentState.accountData, summaryData = currentState.summaryData;
    if ((accountData.membershipInfo || {}).activeMembershipPlan < AccountPlan.Diamond) {
        return false;
    }
    var csv = generateCsvFile(accountData);
    var date = (summaryData.isSnapshot) ?
        new Date(summaryData.snapshotTimestamp * 1000) : new Date();
    var tz = date.getTimezoneOffset() * 60 * 1000;
    var localDate = new Date(date.getTime() - tz);
    var timeStamp = localDate.toISOString().slice(0, 19).replaceAll(':', '_');
    downloadFile("WalletNow-".concat(accountData.address, "-").concat(timeStamp, ".csv"), csv);
    return true;
};
export var createHarvestCall = function (web3, walletData, investment) {
    if (!investment.harvest)
        return undefined;
    var _a = investment.harvest, abi = _a.abi, ca = _a.ca, call = _a.call;
    abi.inputs = abi.inputs || [];
    abi.outputs = abi.outputs || [];
    abi.type = abi.type || 'function';
    var w = window;
    w.contract = new web3.eth.Contract([abi], ca);
    w.wallet = walletData.address;
    var txCall = eval("contract.methods.".concat(call));
    delete w.contract;
    delete w.wallet;
    return txCall;
};
export var getTokenIconUrl = function (chainId, address) {
    try {
        var checksumAddress = utils.getAddress(address);
        return "https://cdn.jsdelivr.net/gh/wallet-now/assets@master/blockchains/".concat(ChainConfigs[chainId || ChainIds.BSC].trustChainName, "/assets/").concat(checksumAddress, "/logo.png");
    }
    catch (error) {
        console.error("Error computing checksum address for ".concat(address));
    }
    return '';
};
export var getFiatIconUrl = function (code) {
    return "https://cdn.jsdelivr.net/gh/wallet-now/assets@master/fiat/".concat(code.toLowerCase(), ".png");
};
export var isSwapEmbeddedUrl = function () {
    return location.pathname.startsWith('/swap_embedded');
};
