import { ACTION_TYPES } from '../app/constants';
export default function busyReducer(state, action) {
    if (state === void 0) { state = 0; }
    switch (action.type) {
        case ACTION_TYPES.INCREMENT_BUSY_COUNTER:
            return state + 1;
        case ACTION_TYPES.DECREMENT_BUSY_COUNTER:
            return state - 1;
    }
    return state;
}
