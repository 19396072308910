import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { sendErrorReport } from '../store/actions';
import { bindActionCreators } from 'redux';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        permanentErrorTitle: {
            paddingBottom: theme.spacing(1),
        },
        permanentErrorSubtitle: {
            paddingBottom: theme.spacing(2),
        },
        buttonSpacer: {
            marginLeft: theme.spacing(2),
        }
    });
});
var ErrorReport = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var handleClickSubmitReport = function () {
        props.sendErrorReport((props.error || {}).stack);
    };
    var handleClickReload = function () {
        location.reload();
    };
    return (React.createElement(Box, { p: 3, width: "100%", textAlign: "center" },
        React.createElement(Typography, { className: classes.permanentErrorTitle, variant: "h5" }, t('OOPS_ERROR_RENDERING_PAGE', 'Oops! There was an error rendering the page.')),
        React.createElement(Typography, { className: classes.permanentErrorSubtitle, variant: "h6" }, t('HELP_US_SUBMIT_REPORT', 'You can help us to improve WalletNow by submitting the error report to us.')),
        React.createElement(Button, { variant: "contained", color: "primary", size: "small", onClick: handleClickSubmitReport }, t('SUBMIT_ERROR_REPORT', 'Submit error report')),
        React.createElement(Button, { className: classes.buttonSpacer, variant: "contained", color: "primary", size: "small", onClick: handleClickReload }, t('RELOAD_PAGE', 'Reload page'))));
};
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ sendErrorReport: sendErrorReport }, dispatch);
};
export default connect(null, mapDispatchToProps)(ErrorReport);
