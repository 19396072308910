var ContractContext = /** @class */ (function () {
    function ContractContext() {
    }
    /**
     * PancakeSwap v2 router
     */
    ContractContext.routerAbi = require('../ABI/pancakeswap-router-v2.json');
    /**
     * PancakeSwap v2 factory
     */
    ContractContext.factoryAbi = require('../ABI/pancakeswap-factory-v2.json');
    /**
     * PancakeSwap v2 pair
     */
    ContractContext.pairAbi = require('../ABI/pancakeswap-pair-v2.json');
    /**
     * ERC20 abi
     */
    ContractContext.erc20Abi = require('../ABI/erc-20-abi.json');
    return ContractContext;
}());
export { ContractContext };
