var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'muibox';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ChainConfigs, ChainIds } from './sharedTypes';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import TokenImage from './TokenImage';
import { isValidAddress, shortenAddress } from './utils';
import { ZERO_ADDR } from './constants';
import { getSwapTokenInfo } from './blockchainUtils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        iconButtonClose: {
            float: 'right',
            marginTop: 2,
        },
        searchInput: {
            marginBottom: theme.spacing(2),
        },
        spacer: {
            flexGrow: 1,
        },
        infoLink: {
            display: 'inline-block',
        },
        openIcon: {
            verticalAlign: 'middle',
        },
        importContainer: {
            display: 'flex',
            alignItems: 'center',
        }
    });
});
var DEFAULT_USER_DEFINED_TOKENS = Object.values(ChainIds).reduce(function (acc, curr) {
    acc[curr] = [];
    return acc;
}, {});
var DEFAULT_CACHE = Object.values(ChainIds).reduce(function (acc, curr) {
    acc[curr] = {};
    return acc;
}, {});
var TokenSelector = function (props) {
    var classes = useStyles({});
    var t = useTranslation().t;
    var dialog = useDialog();
    var _a = useState(''), search = _a[0], setSearch = _a[1];
    var chainConfig = ChainConfigs[props.chainId];
    var cache = useRef(DEFAULT_CACHE);
    var _b = useState(DEFAULT_USER_DEFINED_TOKENS), userDefinedTokens = _b[0], setUserDefinedTokens = _b[1];
    var _c = useState(), tokenForAddress = _c[0], setTokenForAddress = _c[1];
    useEffect(function () {
        if (props.allowUserDefinedTokens) {
            // read user defined tokens from storage
            var storedUserDefinedTokens = DEFAULT_USER_DEFINED_TOKENS;
            try {
                var value = localStorage.getItem('userDefinedTokens');
                if (value) {
                    storedUserDefinedTokens = JSON.parse(value);
                }
            }
            catch (_a) {
                // ignore pasing errors, use default
            }
            setUserDefinedTokens(storedUserDefinedTokens);
        }
    }, [props.allowUserDefinedTokens]);
    useEffect(function () {
        var address = search.trim().toLowerCase();
        if (isValidAddress(address) && props.allowUserDefinedTokens) {
            if (Object.keys(cache.current[props.chainId]).includes(address)) {
                setTokenForAddress(cache.current[props.chainId][address]);
                return;
            }
            (function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    getSwapTokenInfo(props.chainId, address)
                        .then(function (swapTokenInfo) {
                        cache.current[props.chainId][address] = swapTokenInfo;
                        setTokenForAddress(cache.current[props.chainId][address]);
                    })
                        .catch(function (error) {
                        console.error(error);
                    });
                    return [2 /*return*/];
                });
            }); })();
        }
        else {
            if (tokenForAddress) {
                setTokenForAddress(undefined);
            }
        }
    }, [search, props.allowUserDefinedTokens]);
    var handleSelectToken = function (token) { return function () {
        props.onSelectToken(token);
        props.onCloseDialog();
    }; };
    var handleClose = function () {
        props.onCloseDialog();
    };
    var handleChangeSearch = function (event) {
        setSearch(event.target.value);
    };
    var tokenMatches = function (token, q) {
        var sanitized = (q || '').trim().toLowerCase();
        if (sanitized == '') {
            return true;
        }
        // TODO: optimize by converting to lower-case everything only once
        return (token.symbol.toLowerCase().includes(sanitized) ||
            token.name.toLowerCase().includes(sanitized) ||
            token.token.toLowerCase() == sanitized);
    };
    var handleClickImport = function () {
        if (tokenForAddress) {
            var newUserDefinedTokens = __assign({}, userDefinedTokens);
            newUserDefinedTokens[props.chainId] = __spreadArray(__spreadArray([], (userDefinedTokens[props.chainId] || []), true), [tokenForAddress], false);
            localStorage.setItem('userDefinedTokens', JSON.stringify(newUserDefinedTokens));
            setUserDefinedTokens(newUserDefinedTokens);
        }
    };
    var handleClickDeleteUserDefinedToken = function (token) { return function (event) {
        event.preventDefault();
        event.stopPropagation();
        dialog.confirm({
            title: t('REMOVE_USER_DEFINED_TOKEN', 'Remove User Defined Token'),
            message: t('CONFIRM_REMOVE', "Confirm remove '{{name}}'", { name: token.symbol }),
            ok: {
                text: t('OK', 'Ok'),
                variant: 'contained',
                color: 'primary'
            },
            cancel: {
                text: t('CANCEL', 'Cancel'),
                variant: 'outlined',
                color: 'default'
            }
        })
            .then(function () {
            var newUserDefinedTokens = __assign({}, userDefinedTokens);
            newUserDefinedTokens[props.chainId] = userDefinedTokens[props.chainId]
                .filter(function (userDefinedToken) { return userDefinedToken.token != token.token; });
            localStorage.setItem('userDefinedTokens', JSON.stringify(newUserDefinedTokens));
            setUserDefinedTokens(newUserDefinedTokens);
        });
    }; };
    var renderTokens = function (tokens, isUserDefined) {
        var items = tokens.map(function (token) {
            if (!tokenMatches(token, search)) {
                return;
            }
            return (React.createElement(MenuItem, { key: "".concat(token.symbol, "_").concat(token.token), onClick: handleSelectToken(token) },
                React.createElement(TokenImage, { showSymbol: true, showName: true, token: token, chainId: props.chainId }),
                (isUserDefined) && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classes.spacer }),
                    React.createElement(IconButton, { size: "small", onClick: handleClickDeleteUserDefinedToken(token) },
                        React.createElement(DeleteOutlinedIcon, null))))));
        }).filter(function (el) { return el; });
        var found = items.length > 0;
        if (found && isUserDefined) {
            // add separator for user defined tokens
            items.unshift((React.createElement(Box, { key: "separator" },
                React.createElement(Typography, { variant: "caption" }, t('USER_DEFINED_TOKENS', 'User defined tokens')),
                React.createElement(Divider, null))));
        }
        return {
            items: items,
            found: found
        };
    };
    var manualAddress = search.trim().toLowerCase();
    var isAddress = isValidAddress(manualAddress);
    var tokens = renderTokens(props.tokens || []);
    var userDefined = props.allowUserDefinedTokens
        ? renderTokens(userDefinedTokens[props.chainId] || [], true)
        : { items: undefined, found: true };
    var tryToAdd = isAddress && !tokens.found && !userDefined.found;
    return (React.createElement(Dialog, { onClose: handleClose, "aria-labelledby": "dialog-title", open: props.open, maxWidth: "xs", fullWidth: true },
        React.createElement(DialogTitle, { id: "dialog-title", disableTypography: true },
            React.createElement(Typography, { variant: "h6", color: "inherit" },
                t('SELECT_TOKEN', 'Select Token'),
                React.createElement(IconButton, { color: "inherit", className: classes.iconButtonClose, "aria-label": t('CLOSE', 'Close'), size: "small", onClick: handleClose },
                    React.createElement(CloseIcon, null)))),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { className: classes.searchInput, label: (props.allowUserDefinedTokens) ? t('SEARCH_OR_ADD_ADDRESS', 'Search or add address') : t('SEARCH', 'Search'), id: "value", value: search, onChange: handleChangeSearch, variant: "outlined", fullWidth: true }),
            tokens.items,
            userDefined.items,
            (tryToAdd && (tokenForAddress === null || tokenForAddress === void 0 ? void 0 : tokenForAddress.token) == ZERO_ADDR) && (React.createElement(Typography, { variant: "caption", color: "error" }, t('TOKEN_INFO_NOT_FOUND_CHECK_ADDRESS', 'Token information was not found. Please check that the address provided is correct.'))),
            (tryToAdd && (tokenForAddress === null || tokenForAddress === void 0 ? void 0 : tokenForAddress.token) == manualAddress) && (React.createElement(React.Fragment, null,
                React.createElement(MenuItem, null,
                    React.createElement(TokenImage, { showSymbol: true, showName: true, token: tokenForAddress, chainId: props.chainId })),
                React.createElement(Box, { className: classes.importContainer },
                    React.createElement(Button, { size: "small", color: "primary", variant: "contained", onClick: handleClickImport }, t('IMPORT', 'Import')),
                    React.createElement("div", { className: classes.spacer }),
                    React.createElement(Link, { className: classes.infoLink, target: "_blank", rel: "noopener", href: "".concat(chainConfig.explorerUrl, "/token/").concat(tokenForAddress.token) },
                        shortenAddress(tokenForAddress.token),
                        " ",
                        React.createElement(OpenInNewIcon, { className: classes.openIcon, fontSize: "small" }))))))));
};
export default TokenSelector;
