import { lazy } from 'react';
import { matchRoutes } from "react-router-config";
var baseRoutes = [
    {
        path: '/account/:account/settings',
        component: lazy(function () { return import('./settings/Settings'); })
    },
    {
        path: '/account/:account',
        component: lazy(function () { return import('./Account'); })
    },
    {
        path: '/plans',
        component: lazy(function () { return import('./Plans'); })
    },
    {
        path: '/private_sale',
        component: lazy(function () { return import('./PrivateSale'); })
    },
    {
        path: '/swap',
        component: lazy(function () { return import('./SwapPage'); })
    },
    {
        path: '/chart/:chain/:token',
        component: lazy(function () { return import('./ChartPage'); })
    },
    {
        path: '/chart',
        component: lazy(function () { return import('./ChartPage'); })
    },
    {
        path: '/protocols',
        component: lazy(function () { return import('./ProtocolList'); })
    },
    {
        path: '/',
        component: lazy(function () { return import('./Landing'); })
    },
    {
        component: lazy(function () { return import('./Landing'); })
    }
];
export var getRoutes = function () { return baseRoutes; };
export var accountFromCurrentPath = function () {
    var branch = matchRoutes(getRoutes(), location.pathname);
    if (branch.length > 0) {
        return (((branch[0].match || {}).params || {}).account || '').toLowerCase();
    }
    return '';
};
