var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var FantomOutline = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "3 3 14 14", key: "fantom_outline" }),
        React.createElement("path", { d: "M9.877 3.134a1.394 1.394 0 011.191 0l3.45 1.817c.207.108.319.27.337.438h.002v9.174c-.009.178-.122.353-.339.469l-3.45 1.833c-.338.18-.852.18-1.19 0l-3.45-1.833c-.221-.117-.327-.299-.332-.48v.011V5.39c.014-.17.12-.327.331-.438l3.45-1.817zM6.62 10.5v4.023l3.258 1.722c.188.101.384.2.575.207h.02c.174.001.342-.078.511-.161l.061-.03 3.29-1.753v-3.973l-3.227 1.696c-.333.174-.836.178-1.175.01l-.019-.01L6.62 10.5zm-1.075 3.871c0 .35.041.579.122.74.067.134.168.237.353.361l.01.007c.04.027.085.056.14.089l.063.039.197.117-.282.463-.22-.132-.037-.023a4.916 4.916 0 01-.165-.105c-.525-.351-.721-.735-.725-1.532v-.024h.544zm5.218-6.305v3.722a.524.524 0 00.09-.036l3.459-1.817a.317.317 0 00.01-.006l.003-.002-.005-.003-.008-.004-3.458-1.817a.522.522 0 00-.091-.037zm-.524.007a.497.497 0 00-.07.03L6.709 9.92a.38.38 0 00-.01.006l-.003.001.005.003.008.005 3.459 1.817a.5.5 0 00.07.03v-3.71zM6.62 6.024v3.332l3.167-1.664-3.167-1.668zm7.714-.004l-3.136 1.652 3.136 1.648v-3.3zm-3.518-2.408a.86.86 0 00-.684 0L6.681 5.43a.406.406 0 00-.01.005l-.003.002.005.003.008.004 3.45 1.817a.86.86 0 00.684 0l3.45-1.817a.47.47 0 00.01-.005l.003-.002-.005-.003-.008-.004-3.45-1.818zm4.038.2l.22.132.037.023c.063.039.116.072.165.105.525.351.721.735.725 1.532v.024h-.544c0-.35-.041-.579-.122-.74-.067-.134-.168-.237-.353-.361l-.01-.007a4.226 4.226 0 00-.14-.089l-.063-.039-.197-.117.282-.463z", fill: "currentColor" })));
};
export default FantomOutline;
