export var ChainId;
(function (ChainId) {
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["Polygon"] = 137] = "Polygon";
    ChainId[ChainId["Ethereum"] = 1] = "Ethereum";
    ChainId[ChainId["Fantom"] = 250] = "Fantom";
    ChainId[ChainId["Heco"] = 128] = "Heco";
    ChainId[ChainId["Avalanche"] = 43114] = "Avalanche";
    ChainId[ChainId["Cronos"] = 25] = "Cronos";
})(ChainId || (ChainId = {}));
export var ChainNames = new Map([
    [ChainId.BSC, 'bsc'],
    [ChainId.Polygon, 'poly'],
    [ChainId.Ethereum, 'eth'],
    [ChainId.Fantom, 'ftm'],
    [ChainId.Heco, 'heco'],
    [ChainId.Avalanche, 'avax'],
    [ChainId.Cronos, 'cro'],
]);
