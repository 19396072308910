var _a, _b, _c, _d, _e, _f, _g, _h;
import { ChainId } from '../../enums/chain-id';
import { BSCToken, PolygonToken, EthereumToken, FantomToken, HecoToken, AvalancheToken, CronosToken } from '../../enums/tokens';
export var MAIN_TOKENS = (_a = {},
    _a[ChainId.BSC] = (_b = {},
        _b[BSCToken.BUSD] = {
            chainId: ChainId.BSC,
            contractAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            decimals: 18,
            symbol: "BUSD",
            name: "BUSD Token"
        },
        _b[BSCToken.USDT] = {
            chainId: ChainId.BSC,
            contractAddress: "0x55d398326f99059ff775485246999027b3197955",
            decimals: 18,
            symbol: "USDT",
            name: "Tether USD"
        },
        _b[BSCToken.WBNB] = {
            chainId: ChainId.BSC,
            contractAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
            decimals: 18,
            symbol: "WBNB",
            name: "Wrapped BNB"
        },
        _b[BSCToken.DAI] = {
            chainId: ChainId.BSC,
            contractAddress: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
            decimals: 18,
            symbol: "DAI",
            name: "Dai Token"
        },
        _b[BSCToken.VAI] = {
            chainId: ChainId.BSC,
            contractAddress: "0x4bd17003473389a42daf6a0a729f6fdb328bbbd7",
            decimals: 18,
            symbol: "VAI",
            name: "VAI Stablecoin"
        },
        _b[BSCToken.USDC] = {
            chainId: ChainId.BSC,
            contractAddress: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
            decimals: 18,
            symbol: "USDC",
            name: "USD Coin"
        },
        _b[BSCToken.TUSD] = {
            chainId: ChainId.BSC,
            contractAddress: "0x14016e85a25aeb13065688cafb43044c2ef86784",
            decimals: 18,
            symbol: "TUSD",
            name: "TrueUSD"
        },
        _b),
    _a[ChainId.Polygon] = (_c = {},
        _c[PolygonToken.WMATIC] = {
            chainId: ChainId.Polygon,
            contractAddress: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
            decimals: 18,
            symbol: "WMATIC",
            name: "Wrapped Matic"
        },
        _c[PolygonToken.USDT] = {
            chainId: ChainId.Polygon,
            contractAddress: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
            decimals: 6,
            symbol: "USDT",
            name: "(PoS) Tether USD"
        },
        _c[PolygonToken.USDC] = {
            chainId: ChainId.Polygon,
            contractAddress: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
            decimals: 6,
            symbol: "USDC",
            name: "USD Coin (PoS)"
        },
        _c[PolygonToken.WETH] = {
            chainId: ChainId.Polygon,
            contractAddress: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
            decimals: 18,
            symbol: "WETH",
            name: "Wrapped Ether"
        },
        _c[PolygonToken.DAI] = {
            chainId: ChainId.Polygon,
            contractAddress: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
            decimals: 18,
            symbol: "DAI",
            name: "(PoS) Dai Stablecoin"
        },
        _c[PolygonToken.WBTC] = {
            chainId: ChainId.Polygon,
            contractAddress: "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
            decimals: 8,
            symbol: "WBTC",
            name: "(PoS) Wrapped BTC"
        },
        _c),
    _a[ChainId.Ethereum] = (_d = {},
        _d[EthereumToken.WETH] = {
            chainId: ChainId.Ethereum,
            contractAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
            decimals: 18,
            symbol: "WETH",
            name: "Wrapped Ether"
        },
        _d[EthereumToken.USDT] = {
            chainId: ChainId.Ethereum,
            contractAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
            decimals: 6,
            symbol: "USDT",
            name: "Tether USD"
        },
        _d[EthereumToken.USDC] = {
            chainId: ChainId.Ethereum,
            contractAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            decimals: 6,
            symbol: "USDC",
            name: "USD Coin"
        },
        _d),
    _a[ChainId.Fantom] = (_e = {},
        _e[FantomToken.WFTM] = {
            chainId: ChainId.Fantom,
            contractAddress: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
            decimals: 18,
            symbol: "WFTM",
            name: "Wrapped Fantom"
        },
        _e[FantomToken.fUSDT] = {
            chainId: ChainId.Fantom,
            contractAddress: "0x049d68029688eabf473097a2fc38ef61633a3c7a",
            decimals: 6,
            symbol: "fUSDT",
            name: "Frapped USDT"
        },
        _e[FantomToken.USDC] = {
            chainId: ChainId.Fantom,
            contractAddress: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
            decimals: 6,
            symbol: "USDC",
            name: "USD Coin"
        },
        _e[FantomToken.DAI] = {
            chainId: ChainId.Fantom,
            contractAddress: "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
            decimals: 18,
            symbol: "DAI",
            name: "Dai Stablecoin"
        },
        _e[FantomToken.BTC] = {
            chainId: ChainId.Fantom,
            contractAddress: "0x321162Cd933E2Be498Cd2267a90534A804051b11",
            decimals: 8,
            symbol: "BTC",
            name: "Bitcoin"
        },
        _e[FantomToken.ETH] = {
            chainId: ChainId.Fantom,
            contractAddress: "0x74b23882a30290451a17c44f4f05243b6b58c76d",
            decimals: 18,
            symbol: "ETH",
            name: "Ethereum"
        },
        _e[FantomToken.MIM] = {
            chainId: ChainId.Fantom,
            contractAddress: "0x82f0b8b456c1a451378467398982d4834b6829c1",
            decimals: 18,
            symbol: "MIM",
            name: "Magic Internet Money"
        },
        _e),
    _a[ChainId.Heco] = (_f = {},
        _f[HecoToken.WHT] = {
            chainId: ChainId.Heco,
            contractAddress: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
            decimals: 18,
            symbol: "WHT",
            name: "Wrapped HT"
        },
        _f[HecoToken.USDT] = {
            chainId: ChainId.Heco,
            contractAddress: "0xa71edc38d189767582c38a3145b5873052c3e47a",
            decimals: 18,
            symbol: "USDT",
            name: "Heco-Peg USDT Token"
        },
        _f[HecoToken.HUSD] = {
            chainId: ChainId.Heco,
            contractAddress: "0x0298c2b32eae4da002a15f36fdf7615bea3da047",
            decimals: 8,
            symbol: "HUSD",
            name: "Heco-Peg HUSD Token"
        },
        _f[HecoToken.USDC_HECO] = {
            chainId: ChainId.Heco,
            contractAddress: "0x9362bbef4b8313a8aa9f0c9808b80577aa26b73b",
            decimals: 6,
            symbol: "USDC-HECO",
            name: "Heco-Peg USDCHECO Token"
        },
        _f[HecoToken.TUSD] = {
            chainId: ChainId.Heco,
            contractAddress: "0x5ee41ab6edd38cdfb9f6b4e6cf7f75c87e170d98",
            decimals: 18,
            symbol: "TUSD",
            name: "Heco-Peg TUSD Token"
        },
        _f),
    _a[ChainId.Avalanche] = (_g = {},
        _g[AvalancheToken.WAVAX] = {
            chainId: ChainId.Avalanche,
            contractAddress: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
            decimals: 18,
            symbol: "WAVAX",
            name: "Wrapped AVAX"
        },
        _g[AvalancheToken.USDT_e] = {
            chainId: ChainId.Avalanche,
            contractAddress: "0xc7198437980c041c805a1edcba50c1ce5db95118",
            decimals: 6,
            symbol: "USDT.e",
            name: "Tether USD"
        },
        _g[AvalancheToken.USDC_e] = {
            chainId: ChainId.Avalanche,
            contractAddress: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
            decimals: 6,
            symbol: "USDC.e",
            name: "USD Coin"
        },
        _g[AvalancheToken.DAI_e] = {
            chainId: ChainId.Avalanche,
            contractAddress: "0xd586e7f844cea2f87f50152665bcbc2c279d8d70",
            decimals: 18,
            symbol: "DAI.e",
            name: "Dai Stablecoin"
        },
        _g[AvalancheToken.WBTC_e] = {
            chainId: ChainId.Avalanche,
            contractAddress: "0x50b7545627a5162f82a992c33b87adc75187b218",
            decimals: 8,
            symbol: "WBTC.e",
            name: "Wrapped BTC"
        },
        _g[AvalancheToken.WETH_e] = {
            chainId: ChainId.Avalanche,
            contractAddress: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
            decimals: 18,
            symbol: "WETH.e",
            name: "Wrapped Ether"
        },
        _g),
    _a[ChainId.Cronos] = (_h = {},
        _h[CronosToken.WCRO] = {
            chainId: ChainId.Cronos,
            contractAddress: "0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23",
            decimals: 18,
            symbol: "WCRO",
            name: "Wrapped CRO"
        },
        _h[CronosToken.USDT] = {
            chainId: ChainId.Cronos,
            contractAddress: "0x66e428c3f67a68878562e79a0234c1f83c208770",
            decimals: 6,
            symbol: "USDT",
            name: "Tether USD"
        },
        _h[CronosToken.USDC] = {
            chainId: ChainId.Cronos,
            contractAddress: "0xc21223249ca28397b4b6541dffaecc539bff0c59",
            decimals: 6,
            symbol: "USDC",
            name: "USD Coin"
        },
        _h[CronosToken.DAI] = {
            chainId: ChainId.Cronos,
            contractAddress: "0xf2001b145b43032aaf5ee2884e456ccd805f677d",
            decimals: 18,
            symbol: "DAI",
            name: "Dai Stablecoin"
        },
        _h[CronosToken.TUSD] = {
            chainId: ChainId.Cronos,
            contractAddress: "0x87efb3ec1576dec8ed47e58b832bedcd86ee186e",
            decimals: 18,
            symbol: "TUSD",
            name: "TrueUSD"
        },
        _h[CronosToken.WBTC] = {
            chainId: ChainId.Cronos,
            contractAddress: "0x062e66477faf219f25d27dced647bf57c3107d52",
            decimals: 8,
            symbol: "WBTC",
            name: "Wrapped BTC"
        },
        _h[CronosToken.WETH] = {
            chainId: ChainId.Cronos,
            contractAddress: "0xe44fd7fcb2b1581822d0c862b68222998a0c299a",
            decimals: 18,
            symbol: "WETH",
            name: "Wrapped Ether"
        },
        _h),
    _a);
