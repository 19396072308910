import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ConnectContext } from "./ConnectionProvider";
import { IS_BETA } from "./constants";
var REDIRECT_SECONDS = 5;
var SwapFiatIframe = function () {
    var connection = useContext(ConnectContext);
    var t = useTranslation().t;
    var banxa = useRef();
    var banxaDiv = useRef(null);
    var banxaTrigger = useRef(null);
    var banxaRendered = useRef(false);
    var _a = useState(0), redirectSeconds = _a[0], setRedirectSeconds = _a[1];
    useEffect(function () {
        var banxaSdk = function (callback) {
            var _a;
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.async = !0;
            script.src = "https://sdk.banxa.com/js/banxa-sdk-latest.js";
            var scriptElement = document.getElementsByTagName("script")[0];
            (_a = scriptElement.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(script, scriptElement);
            script.addEventListener("load", function () { callback(); });
        };
        banxaSdk(function () {
            banxa.current = new Banxa("dcu", (IS_BETA) ? "sandbox" : undefined);
        });
    }, []);
    useEffect(function () {
        var _a, _b;
        if (banxaDiv && banxaTrigger && banxa.current && !banxaRendered.current && connection.address) {
            banxaRendered.current = true;
            (_a = banxa.current) === null || _a === void 0 ? void 0 : _a.iframe("#banxaIframe", "#banxaIframeTarget", {
                walletAddress: connection.address,
                blockchain: 'BSC',
                coinType: 'BNB',
                fiatType: 'USD',
            }, "100%", "400px");
            (_b = banxaTrigger.current) === null || _b === void 0 ? void 0 : _b.click();
        }
    }, [banxaDiv, banxaTrigger, banxa.current, connection.address]);
    useEffect(function () {
        if (redirectSeconds < REDIRECT_SECONDS) {
            setTimeout(function () { return setRedirectSeconds(redirectSeconds + 1); }, 1000);
        }
    }, [redirectSeconds]);
    return (React.createElement(Grid, { item: true, xs: 12 },
        React.createElement(Button, { hidden: true, ref: banxaTrigger, id: "banxaIframe" }),
        (redirectSeconds < REDIRECT_SECONDS) && (React.createElement(Box, { height: 400 },
            React.createElement(Typography, { variant: "subtitle1", align: "center" }, t('BANXA_DISCLAIMER', 'You are about to be redirected to Banxa site...')))),
        React.createElement("div", { ref: banxaDiv, id: "banxaIframeTarget", style: { display: (redirectSeconds === REDIRECT_SECONDS) ? "block" : "none" } })));
};
export default SwapFiatIframe;
