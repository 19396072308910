var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Container, createStyles, makeStyles, MuiThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { DialogProvider } from 'muibox';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ConnectionProvider } from './ConnectionProvider';
import SnackbarCloseButton from './SnackbarCloseButton';
import SwapDialogContent from './SwapDialogContent';
import { initI18n } from '../i18n/config';
import store from '../store/store';
import themes from '../theme/themes';
initI18n();
var useStyles = makeStyles(function (theme) {
    return createStyles({
        snackContainer: {
            left: theme.spacing(2),
            right: theme.spacing(2),
        },
    });
});
var Swap = function (props) {
    var classes = useStyles();
    return (React.createElement(Provider, { store: store },
        React.createElement(ConnectionProvider, null,
            React.createElement(MuiThemeProvider, { theme: themes[props.swapOptions.mode || 'light'] },
                React.createElement(CssBaseline, null),
                React.createElement(DialogProvider, null,
                    React.createElement(SnackbarProvider, { preventDuplicate: true, action: function (key) { return React.createElement(SnackbarCloseButton, { id: key }); }, classes: { containerAnchorOriginBottomLeft: classes.snackContainer } },
                        React.createElement(Container, null,
                            React.createElement(SwapDialogContent, __assign({}, props.swapDialogContentProps, { showWalletConnectionWidget: true })))))))));
};
export default Swap;
