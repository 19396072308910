var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import BigNumber from 'bignumber.js';
import { Multicall, } from 'ethereum-multicall';
import { ContractContext } from '../../common/contract-context';
import { ErrorCodes } from '../../common/errors/error-codes';
import { PancakeswapError } from '../../common/errors/pancakeswap-error';
import { formatEther } from '../../common/utils/format-ether';
import { hexlify } from '../../common/utils/hexlify';
import { onlyUnique } from '../../common/utils/only-unique';
import { parseEther } from '../../common/utils/parse-ether';
import { getTradePath } from '../../common/utils/trade-path';
import { ChainId } from '../../enums/chain-id';
import { TradePath } from '../../enums/trade-path';
import { BSCToken, PolygonToken, EthereumToken, FantomToken, HecoToken, AvalancheToken, CronosToken } from '../../enums/tokens';
import { MAIN_TOKENS } from '../../common/tokens/main-tokens';
import { RouterDirection } from './enums/router-direction';
var PancakeswapRouterFactory = /** @class */ (function () {
    function PancakeswapRouterFactory(_fromToken, _toToken, _disableMultihops, _ethersProvider, _routerAddress, _pairAddress, _nativeTokenAddress, _multicallContractAddress) {
        this._fromToken = _fromToken;
        this._toToken = _toToken;
        this._disableMultihops = _disableMultihops;
        this._ethersProvider = _ethersProvider;
        this._routerAddress = _routerAddress;
        this._pairAddress = _pairAddress;
        this._nativeTokenAddress = _nativeTokenAddress;
        this._multicallContractAddress = _multicallContractAddress;
        this._multicall = new Multicall({
            ethersProvider: this._ethersProvider.provider,
            multicallCustomContractAddress: this._multicallContractAddress,
        });
    }
    /**
     * Get all possible routes will only go up to 4 due to gas increase the more routes
     * you go.
     */
    PancakeswapRouterFactory.prototype.getAllPossibleRoutes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var findPairs, contractCallContext, pairs, tokenPairs, fromToken, toToken, contractCallResults, results, availablePairs, fromTokenRoutes, toTokenRoutes, allMainRoutes, i, fromTokenPairs, toTokenPairs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        findPairs = [];
                        if (!this._disableMultihops) {
                            findPairs = this.getMultihopPairs;
                        }
                        else {
                            // multihops turned off so only go direct
                            findPairs = [[[this._fromToken, this._toToken]]];
                        }
                        contractCallContext = {
                            reference: 'pancakeswap-pairs',
                            contractAddress: this._pairAddress,
                            abi: ContractContext.pairAbi,
                            calls: [],
                        };
                        for (pairs = 0; pairs < findPairs.length; pairs++) {
                            for (tokenPairs = 0; tokenPairs < findPairs[pairs].length; tokenPairs++) {
                                fromToken = findPairs[pairs][tokenPairs][0];
                                toToken = findPairs[pairs][tokenPairs][1];
                                contractCallContext.calls.push({
                                    reference: "".concat(fromToken.contractAddress, "-").concat(toToken.contractAddress, "-").concat(fromToken.symbol, "/").concat(toToken.symbol),
                                    methodName: 'getPair',
                                    methodParameters: [
                                        fromToken.contractAddress,
                                        toToken.contractAddress,
                                    ],
                                });
                            }
                        }
                        return [4 /*yield*/, this._multicall.call(contractCallContext)];
                    case 1:
                        contractCallResults = _a.sent();
                        results = contractCallResults.results[contractCallContext.reference];
                        availablePairs = results.callsReturnContext.filter(function (c) { return c.returnValues[0] !== '0x0000000000000000000000000000000000000000'; });
                        fromTokenRoutes = {
                            token: this._fromToken,
                            pairs: {
                                fromTokenPairs: this.getTokenAvailablePairs(this._fromToken, availablePairs, RouterDirection.from),
                            },
                        };
                        toTokenRoutes = {
                            token: this._toToken,
                            pairs: {
                                toTokenPairs: this.getTokenAvailablePairs(this._toToken, availablePairs, RouterDirection.to),
                            },
                        };
                        allMainRoutes = [];
                        for (i = 0; i < this.allMainTokens.length; i++) {
                            fromTokenPairs = this.getTokenAvailablePairs(this.allMainTokens[i], availablePairs, RouterDirection.from);
                            toTokenPairs = this.getTokenAvailablePairs(this.allMainTokens[i], availablePairs, RouterDirection.to);
                            allMainRoutes.push({
                                token: this.allMainTokens[i],
                                pairs: { fromTokenPairs: fromTokenPairs, toTokenPairs: toTokenPairs },
                            });
                        }
                        return [2 /*return*/, this.workOutAllPossibleRoutes(fromTokenRoutes, toTokenRoutes, allMainRoutes)];
                }
            });
        });
    };
    PancakeswapRouterFactory.prototype.getAllPossibleRoutesWithQuotes = function (amountToTrade) {
        return __awaiter(this, void 0, void 0, function () {
            var tradeAmount, routes, contractCallContext, i, routeCombo, contractCallResults, results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tradeAmount = this.formatAmountToTrade(amountToTrade);
                        return [4 /*yield*/, this.getAllPossibleRoutes()];
                    case 1:
                        routes = _a.sent();
                        contractCallContext = {
                            reference: 'pancakeswap-route-quotes',
                            contractAddress: this._routerAddress,
                            abi: ContractContext.routerAbi,
                            calls: [],
                            context: routes,
                        };
                        for (i = 0; i < routes.length; i++) {
                            routeCombo = routes[i];
                            contractCallContext.calls.push({
                                reference: "route".concat(i),
                                methodName: 'getAmountsOut',
                                methodParameters: [
                                    tradeAmount,
                                    routeCombo.map(function (c) {
                                        return c.contractAddress;
                                    }),
                                ],
                            });
                        }
                        return [4 /*yield*/, this._multicall.call(contractCallContext)];
                    case 2:
                        contractCallResults = _a.sent();
                        results = contractCallResults.results[contractCallContext.reference];
                        return [2 /*return*/, this.buildRouteQuotesFromResults(results)];
                }
            });
        });
    };
    /**
     * Finds the best route
     * @param amountToTrade The amount they want to trade
     */
    PancakeswapRouterFactory.prototype.findBestRoute = function (amountToTrade) {
        return __awaiter(this, void 0, void 0, function () {
            var allRoutes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAllPossibleRoutesWithQuotes(amountToTrade)];
                    case 1:
                        allRoutes = _a.sent();
                        if (allRoutes.length === 0) {
                            throw new PancakeswapError("No routes found for ".concat(this._fromToken.contractAddress, " > ").concat(this._toToken.contractAddress), ErrorCodes.noRoutesFound);
                        }
                        return [2 /*return*/, {
                                bestRouteQuote: allRoutes[0],
                                triedRoutesQuote: allRoutes.map(function (route) {
                                    return {
                                        expectedConvertQuote: route.expectedConvertQuote,
                                        routePathArrayTokenMap: route.routePathArrayTokenMap,
                                        routeText: route.routeText,
                                        routePathArray: route.routePathArray,
                                    };
                                }),
                            }];
                }
            });
        });
    };
    /**
     * Works out every possible route it can take
     * @param fromTokenRoutes The from token routes
     * @param toTokenRoutes The to token routes
     * @param allMainRoutes All the main routes
     */
    PancakeswapRouterFactory.prototype.workOutAllPossibleRoutes = function (fromTokenRoutes, toTokenRoutes, allMainRoutes) {
        var jointCompatibleRoutes = toTokenRoutes.pairs.toTokenPairs.filter(function (t) {
            return fromTokenRoutes.pairs.fromTokenPairs.find(function (f) { return f.contractAddress === t.contractAddress; });
        });
        var routes = [];
        if (fromTokenRoutes.pairs.fromTokenPairs.find(function (t) { return t.contractAddress === toTokenRoutes.token.contractAddress; })) {
            routes.push([fromTokenRoutes.token, toTokenRoutes.token]);
        }
        var _loop_1 = function (i) {
            var tokenRoute = allMainRoutes[i];
            if (jointCompatibleRoutes.find(function (c) { return c.contractAddress === tokenRoute.token.contractAddress; })) {
                routes.push([
                    fromTokenRoutes.token,
                    tokenRoute.token,
                    toTokenRoutes.token,
                ]);
                var _loop_2 = function (f) {
                    var fromSupportedToken = fromTokenRoutes.pairs.fromTokenPairs[f];
                    if (tokenRoute.pairs.toTokenPairs.find(function (pair) {
                        return pair.contractAddress === fromSupportedToken.contractAddress;
                    })) {
                        var workedOutFromRoute = [
                            fromTokenRoutes.token,
                            fromSupportedToken,
                            tokenRoute.token,
                            toTokenRoutes.token,
                        ];
                        if (workedOutFromRoute.filter(onlyUnique).length ===
                            workedOutFromRoute.length) {
                            routes.push(workedOutFromRoute);
                        }
                    }
                };
                for (var f = 0; f < fromTokenRoutes.pairs.fromTokenPairs.length; f++) {
                    _loop_2(f);
                }
                var _loop_3 = function (f) {
                    var toSupportedToken = toTokenRoutes.pairs.toTokenPairs[f];
                    if (tokenRoute.pairs.fromTokenPairs.find(function (pair) {
                        return pair.contractAddress === toSupportedToken.contractAddress;
                    })) {
                        var workedOutToRoute = [
                            fromTokenRoutes.token,
                            tokenRoute.token,
                            toSupportedToken,
                            toTokenRoutes.token,
                        ];
                        if (workedOutToRoute.filter(onlyUnique).length ===
                            workedOutToRoute.length) {
                            routes.push(workedOutToRoute);
                        }
                    }
                };
                for (var f = 0; f < toTokenRoutes.pairs.toTokenPairs.length; f++) {
                    _loop_3(f);
                }
            }
        };
        for (var i = 0; i < allMainRoutes.length; i++) {
            _loop_1(i);
        }
        return routes;
    };
    PancakeswapRouterFactory.prototype.getTokenAvailablePairs = function (token, allAvailablePairs, direction) {
        switch (direction) {
            case RouterDirection.from:
                return this.getFromRouterDirectionAvailablePairs(token, allAvailablePairs);
            case RouterDirection.to:
                return this.getToRouterDirectionAvailablePairs(token, allAvailablePairs);
        }
    };
    PancakeswapRouterFactory.prototype.getFromRouterDirectionAvailablePairs = function (token, allAvailablePairs) {
        var fromRouterDirection = allAvailablePairs.filter(function (c) { return c.reference.split('-')[0] === token.contractAddress; });
        var tokens = [];
        var _loop_4 = function (index) {
            var context_1 = fromRouterDirection[index];
            tokens.push(this_1.allTokens.find(function (t) { return t.contractAddress === context_1.reference.split('-')[1]; }));
        };
        var this_1 = this;
        for (var index = 0; index < fromRouterDirection.length; index++) {
            _loop_4(index);
        }
        return tokens;
    };
    PancakeswapRouterFactory.prototype.getToRouterDirectionAvailablePairs = function (token, allAvailablePairs) {
        var toRouterDirection = allAvailablePairs.filter(function (c) { return c.reference.split('-')[1] === token.contractAddress; });
        var tokens = [];
        var _loop_5 = function (index) {
            var context_2 = toRouterDirection[index];
            tokens.push(this_2.allTokens.find(function (t) { return t.contractAddress === context_2.reference.split('-')[0]; }));
        };
        var this_2 = this;
        for (var index = 0; index < toRouterDirection.length; index++) {
            _loop_5(index);
        }
        return tokens;
    };
    /**
     * Build up route quotes from results
     * @param pancakeswapFactoryContext The pancakeswap factory context
     * @param contractCallReturnContext The contract call return context
     */
    PancakeswapRouterFactory.prototype.buildRouteQuotesFromResults = function (contractCallReturnContext) {
        var tradePath = this.tradePath();
        var result = [];
        if (contractCallReturnContext) {
            for (var i = 0; i < contractCallReturnContext.callsReturnContext.length; i++) {
                var callReturnContext = contractCallReturnContext.callsReturnContext[i];
                switch (tradePath) {
                    case TradePath.ethToErc20:
                        result.push(this.buildRouteQuoteForEthToErc20(callReturnContext));
                        break;
                    case TradePath.erc20ToEth:
                        result.push(this.buildRouteQuoteForErc20ToEth(callReturnContext));
                        break;
                    case TradePath.erc20ToErc20:
                        result.push(this.buildRouteQuoteForErc20ToErc20(callReturnContext));
                        break;
                    default:
                        throw new PancakeswapError("".concat(tradePath, " not found"), ErrorCodes.tradePathIsNotSupported);
                }
            }
            return result.sort(function (a, b) {
                if (new BigNumber(a.expectedConvertQuote).isGreaterThan(b.expectedConvertQuote)) {
                    return -1;
                }
                return new BigNumber(a.expectedConvertQuote).isLessThan(b.expectedConvertQuote)
                    ? 1
                    : 0;
            });
        }
        return result;
    };
    /**
     * Build up the route quote for erc20 > erc20
     * @param callReturnContext The call return context
     */
    PancakeswapRouterFactory.prototype.buildRouteQuoteForErc20ToErc20 = function (callReturnContext) {
        return this.buildRouteQuoteForEthToErc20(callReturnContext);
    };
    /**
     * Build up route quote for eth > erc20
     * @param callReturnContext The call return context
     */
    PancakeswapRouterFactory.prototype.buildRouteQuoteForEthToErc20 = function (callReturnContext) {
        var _this = this;
        var convertQuoteUnformatted = new BigNumber(callReturnContext.returnValues[callReturnContext.returnValues.length - 1].hex);
        return {
            expectedConvertQuote: convertQuoteUnformatted
                .shiftedBy(this._toToken.decimals * -1)
                .toFixed(this._toToken.decimals),
            routePathArrayTokenMap: callReturnContext.methodParameters[1].map(function (c) {
                return _this.allTokens.find(function (t) { return t.contractAddress === c; });
            }),
            routeText: callReturnContext.methodParameters[1]
                .map(function (c) {
                return _this.allTokens.find(function (t) { return t.contractAddress === c; }).symbol;
            })
                .join(' > '),
            // route array is always in the 1 index of the method parameters
            routePathArray: callReturnContext.methodParameters[1],
        };
    };
    /**
     * Build up the route quote for erc20 > eth
     * @param callReturnContext The call return context
     */
    PancakeswapRouterFactory.prototype.buildRouteQuoteForErc20ToEth = function (callReturnContext) {
        var _this = this;
        var convertQuoteUnformatted = new BigNumber(callReturnContext.returnValues[callReturnContext.returnValues.length - 1].hex);
        return {
            expectedConvertQuote: new BigNumber(formatEther(convertQuoteUnformatted)).toFixed(this._toToken.decimals),
            routePathArrayTokenMap: callReturnContext.methodParameters[1].map(function (c) {
                return _this.allTokens.find(function (t) { return t.contractAddress === c; });
            }),
            routeText: callReturnContext.methodParameters[1]
                .map(function (c) {
                return _this.allTokens.find(function (t) { return t.contractAddress === c; }).symbol;
            })
                .join(' > '),
            // route array is always in the 1 index of the method parameters
            routePathArray: callReturnContext.methodParameters[1],
        };
    };
    /**
     * Format amount to trade into callable formats
     * @param amountToTrade The amount to trade
     * @param pancakeswapFactoryContext The pancakeswap factory context
     */
    PancakeswapRouterFactory.prototype.formatAmountToTrade = function (amountToTrade) {
        switch (this.tradePath()) {
            case TradePath.ethToErc20:
                var amountToTradeWei = parseEther(amountToTrade);
                return hexlify(amountToTradeWei);
            case TradePath.erc20ToEth:
            case TradePath.erc20ToErc20:
                return hexlify(amountToTrade.shiftedBy(this._fromToken.decimals));
            default:
                throw new PancakeswapError("Internal trade path ".concat(this.tradePath(), " is not supported"), ErrorCodes.tradePathIsNotSupported);
        }
    };
    /**
     * Get the trade path
     */
    PancakeswapRouterFactory.prototype.tradePath = function () {
        return getTradePath(this._fromToken, this._toToken, this._nativeTokenAddress);
    };
    Object.defineProperty(PancakeswapRouterFactory.prototype, "allTokens", {
        get: function () {
            return __spreadArray([this._fromToken, this._toToken], this.allMainTokens, true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "getMultihopPairs", {
        /**
         * BEGIN: Code generated by get-router-paths script
         */
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.BSC:
                    return [
                        this.mainCurrenciesPairsForFromToken,
                        this.mainCurrenciesPairsForToToken,
                        this.mainCurrenciesPairsForBUSD,
                        this.mainCurrenciesPairsForUSDT,
                        this.mainCurrenciesPairsForWBNB,
                        this.mainCurrenciesPairsForDAI,
                        this.mainCurrenciesPairsForVAI,
                        this.mainCurrenciesPairsForUSDC,
                        this.mainCurrenciesPairsForTUSD,
                        [[this._fromToken, this._toToken]],
                    ];
                case ChainId.Polygon:
                    return [
                        this.mainCurrenciesPairsForFromToken,
                        this.mainCurrenciesPairsForToToken,
                        this.mainCurrenciesPairsForWMATIC,
                        this.mainCurrenciesPairsForUSDT,
                        this.mainCurrenciesPairsForUSDC,
                        this.mainCurrenciesPairsForWETH,
                        this.mainCurrenciesPairsForDAI,
                        this.mainCurrenciesPairsForWBTC,
                        [[this._fromToken, this._toToken]],
                    ];
                case ChainId.Ethereum:
                    return [
                        this.mainCurrenciesPairsForFromToken,
                        this.mainCurrenciesPairsForToToken,
                        this.mainCurrenciesPairsForWETH,
                        this.mainCurrenciesPairsForUSDT,
                        this.mainCurrenciesPairsForUSDC,
                        [[this._fromToken, this._toToken]],
                    ];
                case ChainId.Fantom:
                    return [
                        this.mainCurrenciesPairsForFromToken,
                        this.mainCurrenciesPairsForToToken,
                        this.mainCurrenciesPairsForWFTM,
                        this.mainCurrenciesPairsForfUSDT,
                        this.mainCurrenciesPairsForUSDC,
                        this.mainCurrenciesPairsForDAI,
                        this.mainCurrenciesPairsForBTC,
                        this.mainCurrenciesPairsForETH,
                        this.mainCurrenciesPairsForMIM,
                        [[this._fromToken, this._toToken]],
                    ];
                case ChainId.Heco:
                    return [
                        this.mainCurrenciesPairsForFromToken,
                        this.mainCurrenciesPairsForToToken,
                        this.mainCurrenciesPairsForWHT,
                        this.mainCurrenciesPairsForUSDT,
                        this.mainCurrenciesPairsForHUSD,
                        this.mainCurrenciesPairsForUSDC_HECO,
                        this.mainCurrenciesPairsForTUSD,
                        [[this._fromToken, this._toToken]],
                    ];
                case ChainId.Avalanche:
                    return [
                        this.mainCurrenciesPairsForFromToken,
                        this.mainCurrenciesPairsForToToken,
                        this.mainCurrenciesPairsForWAVAX,
                        this.mainCurrenciesPairsForUSDT_e,
                        this.mainCurrenciesPairsForUSDC_e,
                        this.mainCurrenciesPairsForDAI_e,
                        this.mainCurrenciesPairsForWBTC_e,
                        this.mainCurrenciesPairsForWETH_e,
                        [[this._fromToken, this._toToken]],
                    ];
                case ChainId.Cronos:
                    return [
                        this.mainCurrenciesPairsForFromToken,
                        this.mainCurrenciesPairsForToToken,
                        this.mainCurrenciesPairsForWCRO,
                        this.mainCurrenciesPairsForUSDT,
                        this.mainCurrenciesPairsForUSDC,
                        this.mainCurrenciesPairsForDAI,
                        this.mainCurrenciesPairsForTUSD,
                        this.mainCurrenciesPairsForWBTC,
                        this.mainCurrenciesPairsForWETH,
                        [[this._fromToken, this._toToken]],
                    ];
            }
            return [[[this._fromToken, this._toToken]]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "allMainTokens", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.BSC:
                    return [
                        MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD],
                        MAIN_TOKENS[ChainId.BSC][BSCToken.USDT],
                        MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB],
                        MAIN_TOKENS[ChainId.BSC][BSCToken.DAI],
                        MAIN_TOKENS[ChainId.BSC][BSCToken.VAI],
                        MAIN_TOKENS[ChainId.BSC][BSCToken.USDC],
                        MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD],
                    ];
                case ChainId.Polygon:
                    return [
                        MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC],
                        MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT],
                        MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC],
                        MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH],
                        MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI],
                        MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC],
                    ];
                case ChainId.Ethereum:
                    return [
                        MAIN_TOKENS[ChainId.Ethereum][EthereumToken.WETH],
                        MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDT],
                        MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDC],
                    ];
                case ChainId.Fantom:
                    return [
                        MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM],
                        MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT],
                        MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC],
                        MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI],
                        MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC],
                        MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH],
                        MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM],
                    ];
                case ChainId.Heco:
                    return [
                        MAIN_TOKENS[ChainId.Heco][HecoToken.WHT],
                        MAIN_TOKENS[ChainId.Heco][HecoToken.USDT],
                        MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD],
                        MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO],
                        MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD],
                    ];
                case ChainId.Avalanche:
                    return [
                        MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX],
                        MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e],
                        MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e],
                        MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e],
                        MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e],
                        MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e],
                    ];
                case ChainId.Cronos:
                    return [
                        MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO],
                        MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT],
                        MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC],
                        MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI],
                        MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD],
                        MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC],
                        MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH],
                    ];
            }
            return [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForFromToken", {
        get: function () {
            var _a;
            var chainId = this._ethersProvider.provider.network.chainId;
            var pairsByChainId = (_a = {},
                _a[ChainId.BSC] = [
                    [this._fromToken, MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD]],
                    [this._fromToken, MAIN_TOKENS[ChainId.BSC][BSCToken.USDT]],
                    [this._fromToken, MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB]],
                    [this._fromToken, MAIN_TOKENS[ChainId.BSC][BSCToken.DAI]],
                    [this._fromToken, MAIN_TOKENS[ChainId.BSC][BSCToken.VAI]],
                    [this._fromToken, MAIN_TOKENS[ChainId.BSC][BSCToken.USDC]],
                    [this._fromToken, MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD]],
                ],
                _a[ChainId.Polygon] = [
                    [this._fromToken, MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC]],
                ],
                _a[ChainId.Ethereum] = [
                    [this._fromToken, MAIN_TOKENS[ChainId.Ethereum][EthereumToken.WETH]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDT]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDC]],
                ],
                _a[ChainId.Fantom] = [
                    [this._fromToken, MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM]],
                ],
                _a[ChainId.Heco] = [
                    [this._fromToken, MAIN_TOKENS[ChainId.Heco][HecoToken.WHT]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Heco][HecoToken.USDT]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD]],
                ],
                _a[ChainId.Avalanche] = [
                    [this._fromToken, MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e]],
                ],
                _a[ChainId.Cronos] = [
                    [this._fromToken, MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC]],
                    [this._fromToken, MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH]],
                ],
                _a);
            return pairsByChainId[chainId].filter(function (t) { return t[0].contractAddress.toLowerCase() !== t[1].contractAddress.toLowerCase(); });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForToToken", {
        get: function () {
            var _a;
            var chainId = this._ethersProvider.provider.network.chainId;
            var pairsByChainId = (_a = {},
                _a[ChainId.BSC] = [
                    [MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD], this._toToken],
                    [MAIN_TOKENS[ChainId.BSC][BSCToken.USDT], this._toToken],
                    [MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB], this._toToken],
                    [MAIN_TOKENS[ChainId.BSC][BSCToken.DAI], this._toToken],
                    [MAIN_TOKENS[ChainId.BSC][BSCToken.VAI], this._toToken],
                    [MAIN_TOKENS[ChainId.BSC][BSCToken.USDC], this._toToken],
                    [MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD], this._toToken],
                ],
                _a[ChainId.Polygon] = [
                    [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC], this._toToken],
                    [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT], this._toToken],
                    [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC], this._toToken],
                    [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH], this._toToken],
                    [MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI], this._toToken],
                    [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC], this._toToken],
                ],
                _a[ChainId.Ethereum] = [
                    [MAIN_TOKENS[ChainId.Ethereum][EthereumToken.WETH], this._toToken],
                    [MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDT], this._toToken],
                    [MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDC], this._toToken],
                ],
                _a[ChainId.Fantom] = [
                    [MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM], this._toToken],
                    [MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT], this._toToken],
                    [MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC], this._toToken],
                    [MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI], this._toToken],
                    [MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC], this._toToken],
                    [MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH], this._toToken],
                    [MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM], this._toToken],
                ],
                _a[ChainId.Heco] = [
                    [MAIN_TOKENS[ChainId.Heco][HecoToken.WHT], this._toToken],
                    [MAIN_TOKENS[ChainId.Heco][HecoToken.USDT], this._toToken],
                    [MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD], this._toToken],
                    [MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO], this._toToken],
                    [MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD], this._toToken],
                ],
                _a[ChainId.Avalanche] = [
                    [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX], this._toToken],
                    [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e], this._toToken],
                    [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e], this._toToken],
                    [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e], this._toToken],
                    [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e], this._toToken],
                    [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e], this._toToken],
                ],
                _a[ChainId.Cronos] = [
                    [MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO], this._toToken],
                    [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT], this._toToken],
                    [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC], this._toToken],
                    [MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI], this._toToken],
                    [MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD], this._toToken],
                    [MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC], this._toToken],
                    [MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH], this._toToken],
                ],
                _a);
            return pairsByChainId[chainId].filter(function (t) { return t[0].contractAddress.toLowerCase() !== t[1].contractAddress.toLowerCase(); });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForBUSD", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.BSC:
                    return [
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.USDT]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.DAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.VAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.USDC]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForUSDT", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.BSC:
                    return [
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDT], MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDT], MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDT], MAIN_TOKENS[ChainId.BSC][BSCToken.DAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDT], MAIN_TOKENS[ChainId.BSC][BSCToken.VAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDT], MAIN_TOKENS[ChainId.BSC][BSCToken.USDC]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDT], MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD]],
                    ];
                case ChainId.Polygon:
                    return [
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT], MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC]],
                    ];
                case ChainId.Ethereum:
                    return [
                        [MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDT], MAIN_TOKENS[ChainId.Ethereum][EthereumToken.WETH]],
                        [MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDT], MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDC]],
                    ];
                case ChainId.Heco:
                    return [
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.USDT], MAIN_TOKENS[ChainId.Heco][HecoToken.WHT]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.USDT], MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.USDT], MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.USDT], MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD]],
                    ];
                case ChainId.Cronos:
                    return [
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT], MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT], MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT], MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT], MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT], MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWBNB", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.BSC:
                    return [
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB], MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB], MAIN_TOKENS[ChainId.BSC][BSCToken.USDT]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB], MAIN_TOKENS[ChainId.BSC][BSCToken.DAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB], MAIN_TOKENS[ChainId.BSC][BSCToken.VAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB], MAIN_TOKENS[ChainId.BSC][BSCToken.USDC]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB], MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForDAI", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.BSC:
                    return [
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.DAI], MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.DAI], MAIN_TOKENS[ChainId.BSC][BSCToken.USDT]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.DAI], MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.DAI], MAIN_TOKENS[ChainId.BSC][BSCToken.VAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.DAI], MAIN_TOKENS[ChainId.BSC][BSCToken.USDC]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.DAI], MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD]],
                    ];
                case ChainId.Polygon:
                    return [
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC]],
                    ];
                case ChainId.Fantom:
                    return [
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI], MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI], MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI], MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI], MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI], MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI], MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM]],
                    ];
                case ChainId.Cronos:
                    return [
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI], MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI], MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI], MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI], MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForVAI", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.BSC:
                    return [
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.VAI], MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.VAI], MAIN_TOKENS[ChainId.BSC][BSCToken.USDT]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.VAI], MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.VAI], MAIN_TOKENS[ChainId.BSC][BSCToken.DAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.VAI], MAIN_TOKENS[ChainId.BSC][BSCToken.USDC]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.VAI], MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForUSDC", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.BSC:
                    return [
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDC], MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDC], MAIN_TOKENS[ChainId.BSC][BSCToken.USDT]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDC], MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDC], MAIN_TOKENS[ChainId.BSC][BSCToken.DAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDC], MAIN_TOKENS[ChainId.BSC][BSCToken.VAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.USDC], MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD]],
                    ];
                case ChainId.Polygon:
                    return [
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC]],
                    ];
                case ChainId.Ethereum:
                    return [
                        [MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDC], MAIN_TOKENS[ChainId.Ethereum][EthereumToken.WETH]],
                        [MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDC], MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDT]],
                    ];
                case ChainId.Fantom:
                    return [
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC], MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC], MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC], MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC], MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC], MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC], MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM]],
                    ];
                case ChainId.Cronos:
                    return [
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC], MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC], MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC], MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC], MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC], MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForTUSD", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.BSC:
                    return [
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.BUSD]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.USDT]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.WBNB]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.DAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.VAI]],
                        [MAIN_TOKENS[ChainId.BSC][BSCToken.TUSD], MAIN_TOKENS[ChainId.BSC][BSCToken.USDC]],
                    ];
                case ChainId.Heco:
                    return [
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD], MAIN_TOKENS[ChainId.Heco][HecoToken.WHT]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD], MAIN_TOKENS[ChainId.Heco][HecoToken.USDT]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD], MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD], MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO]],
                    ];
                case ChainId.Cronos:
                    return [
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD], MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD], MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD], MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD], MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWMATIC", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Polygon:
                    return [
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWETH", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Polygon:
                    return [
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH], MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC]],
                    ];
                case ChainId.Ethereum:
                    return [
                        [MAIN_TOKENS[ChainId.Ethereum][EthereumToken.WETH], MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDT]],
                        [MAIN_TOKENS[ChainId.Ethereum][EthereumToken.WETH], MAIN_TOKENS[ChainId.Ethereum][EthereumToken.USDC]],
                    ];
                case ChainId.Cronos:
                    return [
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH], MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH], MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH], MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH], MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWBTC", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Polygon:
                    return [
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WMATIC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDT]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.USDC]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.WETH]],
                        [MAIN_TOKENS[ChainId.Polygon][PolygonToken.WBTC], MAIN_TOKENS[ChainId.Polygon][PolygonToken.DAI]],
                    ];
                case ChainId.Cronos:
                    return [
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC], MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC], MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC], MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC], MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWFTM", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Fantom:
                    return [
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM], MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM], MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM], MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM], MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM], MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM], MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForfUSDT", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Fantom:
                    return [
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT], MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT], MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT], MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT], MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT], MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT], MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForBTC", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Fantom:
                    return [
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC], MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC], MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC], MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC], MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC], MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC], MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForETH", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Fantom:
                    return [
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH], MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH], MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH], MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH], MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH], MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH], MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForMIM", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Fantom:
                    return [
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM], MAIN_TOKENS[ChainId.Fantom][FantomToken.WFTM]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM], MAIN_TOKENS[ChainId.Fantom][FantomToken.fUSDT]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM], MAIN_TOKENS[ChainId.Fantom][FantomToken.USDC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM], MAIN_TOKENS[ChainId.Fantom][FantomToken.DAI]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM], MAIN_TOKENS[ChainId.Fantom][FantomToken.BTC]],
                        [MAIN_TOKENS[ChainId.Fantom][FantomToken.MIM], MAIN_TOKENS[ChainId.Fantom][FantomToken.ETH]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWHT", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Heco:
                    return [
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.WHT], MAIN_TOKENS[ChainId.Heco][HecoToken.USDT]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.WHT], MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.WHT], MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.WHT], MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForHUSD", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Heco:
                    return [
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD], MAIN_TOKENS[ChainId.Heco][HecoToken.WHT]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD], MAIN_TOKENS[ChainId.Heco][HecoToken.USDT]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD], MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD], MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForUSDC_HECO", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Heco:
                    return [
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO], MAIN_TOKENS[ChainId.Heco][HecoToken.WHT]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO], MAIN_TOKENS[ChainId.Heco][HecoToken.USDT]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO], MAIN_TOKENS[ChainId.Heco][HecoToken.HUSD]],
                        [MAIN_TOKENS[ChainId.Heco][HecoToken.USDC_HECO], MAIN_TOKENS[ChainId.Heco][HecoToken.TUSD]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWAVAX", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Avalanche:
                    return [
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForUSDT_e", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Avalanche:
                    return [
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForUSDC_e", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Avalanche:
                    return [
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForDAI_e", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Avalanche:
                    return [
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWBTC_e", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Avalanche:
                    return [
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWETH_e", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Avalanche:
                    return [
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WAVAX]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDT_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.USDC_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.DAI_e]],
                        [MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WETH_e], MAIN_TOKENS[ChainId.Avalanche][AvalancheToken.WBTC_e]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PancakeswapRouterFactory.prototype, "mainCurrenciesPairsForWCRO", {
        get: function () {
            var chainId = this._ethersProvider.provider.network.chainId;
            switch (chainId) {
                case ChainId.Cronos:
                    return [
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDT]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO], MAIN_TOKENS[ChainId.Cronos][CronosToken.USDC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO], MAIN_TOKENS[ChainId.Cronos][CronosToken.DAI]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO], MAIN_TOKENS[ChainId.Cronos][CronosToken.TUSD]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO], MAIN_TOKENS[ChainId.Cronos][CronosToken.WBTC]],
                        [MAIN_TOKENS[ChainId.Cronos][CronosToken.WCRO], MAIN_TOKENS[ChainId.Cronos][CronosToken.WETH]],
                    ];
            }
            return [[]];
        },
        enumerable: false,
        configurable: true
    });
    return PancakeswapRouterFactory;
}());
export { PancakeswapRouterFactory };
