var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDialog } from 'muibox';
import { Trans, useTranslation } from 'react-i18next';
import Web3 from 'web3';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SettingsIcon from '@material-ui/icons/Settings';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TollIcon from '@material-ui/icons/Toll';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { PancakeswapPair, PancakeswapPairSettings } from '../swap';
import { ConnectContext } from './ConnectionProvider';
import BigNumberJS from 'bignumber.js';
import { BigNumber, FixedNumber } from 'ethers';
import TokenInput from './TokenInput';
import TokenImage from './TokenImage';
import { ChainConfigs, ChainIds } from './sharedTypes';
import { isSameAddress } from './utils';
import DexQuoteProgress, { DexQuoteProgressStates } from './DexQuoteProgress';
import walletNowSwapAbi from '../swap/ABI/walletnow-swap.json';
import erc20Abi from '../swap/ABI/erc-20-abi.json';
import { hexlify } from '../swap/common/utils/hexlify';
import { TradePath } from '../swap/enums/trade-path';
import { getTradePath } from '../swap/common/utils/trade-path';
import { API_BASE_URL, IS_BETA, ZERO_ADDR } from './constants';
import { DEFAULT_SWAP_SETTINGS, DexMode, ensureValidChainIds, PREDEFINED_SWAP_TOKENS } from './swapUtils';
import { checkWalletConnected } from './blockchainUtils';
import ChainSelector from './ChainSelector';
import WalletConnection from './WalletConnection';
import SwapCashBack from './SwapCashBack';
import SwapSettingsDialog from './SwapSettingsDialog';
import SwapFiatIframe from './SwapFiatIframe';
import SwapFiatNative from './SwapFiatNative';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            display: 'flex',
        },
        walletConnectionContainer: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            display: 'flex',
        },
        centralize: {
            justifyContent: 'center',
        },
        slippageLabel: {
            marginRight: theme.spacing(1),
        },
        chip: {
            marginRight: theme.spacing(1),
        },
        chipEditor: {
            maxWidth: theme.spacing(5),
        },
        chipInput: {
            color: 'white',
            fontSize: '75%',
        },
        percentSelected: {
            color: 'white',
        },
        percent: {
            color: theme.palette.text.primary,
        },
        progress: {
            display: 'inline-block',
            marginRight: theme.spacing(1),
        },
        listItem: {
            listStyle: 'none',
            display: 'flex',
            alignItems: 'center'
        },
        fabContainer: {
            position: 'relative',
        },
        swapFromToButton: {
            backgroundColor: 'transparent',
            position: 'absolute',
            bottom: theme.spacing(-1),
            left: 'calc(50% - 20px)',
            zIndex: 1,
        },
        swapFromTo: {
            transitionDuration: '0.25s',
            transitionProperty: 'transform',
        },
        swapFromToInvert: {
            transform: 'rotate(360deg)',
            WebkitTransform: 'rotate(360deg)',
        },
        openIcon: {
            verticalAlign: 'middle'
        },
        beta: {
            backgroundColor: '#993799',
            color: 'white',
            fontWeight: 'bold',
        },
        spacer: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1),
            border: "1px solid ".concat(theme.palette.divider),
            backgroundColor: 'transparent',
            display: 'inline-block',
        },
        button: {
            marginRight: theme.spacing(0.5),
            marginLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            textTransform: 'none',
        },
        swapStepButtonDefault: {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            borderRadius: theme.spacing(2),
            whiteSpace: 'nowrap',
            color: 'rgb(81, 81, 109)',
            backgroundImage: 'linear-gradient(-180deg, rgb(248, 248, 249) 0%, rgb(240, 242, 245) 96%)',
            backgroundPosition: 'initial',
            backgroundSize: 'initial',
            backgroundAttachment: 'initial',
            backgroundOrigin: 'initial',
            backgroundClip: 'initial',
            backgroundColor: 'initial',
            boxShadow: 'rgb(160 160 187 / 30%) 0px 4px 10px 0px',
            padding: '0px 40px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box',
            textAlign: 'center',
            cursor: 'pointer',
            fontWeight: 400,
            fontSize: '14px',
            display: 'block',
            textDecoration: 'none !important',
            backgroundRepeat: 'no-repeat !important',
            textTransform: 'none',
            '&:active': {
                color: 'rgb(81, 81, 109)',
                background: 'rgb(240, 242, 245)',
                boxShadow: 'none',
                transition: 'none',
            }
        },
        swapStepButtonPrimary: {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            borderRadius: theme.spacing(2),
            whiteSpace: 'nowrap',
            color: 'rgb(255, 255, 255)',
            backgroundImage: 'linear-gradient(-180deg, rgb(199, 76, 193) 0%, rgb(110, 38, 135) 96%)',
            backgroundPosition: 'initial',
            backgroundSize: 'initial',
            backgroundAttachment: 'initial',
            backgroundOrigin: 'initial',
            backgroundClip: 'initial',
            backgroundColor: 'initial',
            boxShadow: 'rgb(110 38 135 / 50%) 0px 4px 10px 0px',
            padding: '0px 40px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box',
            textAlign: 'center',
            cursor: 'pointer',
            fontWeight: 400,
            fontSize: '14px',
            display: 'block',
            textDecoration: 'none !important',
            backgroundRepeat: 'no-repeat !important',
            textTransform: 'none',
            '&:active': {
                color: 'rgb(255, 255, 255)',
                background: 'rgb(110, 38, 135)',
                boxShadow: 'none',
                transition: 'none',
            },
            '&:disabled': {
                backgroundImage: 'linear-gradient(-180deg, rgb(221, 221, 221) 0%, rgb(240, 240, 240) 96%)',
            }
        },
        swapTypeIcon: {
            verticalAlign: 'middle',
        },
        swapTypeLink: {
            color: theme.palette.text.primary,
            '&:hover': {
                cursor: 'pointer',
            }
        },
    });
});
var PREDEFINED_SLIPPAGES = [2, 3];
var MAX_RETRIES = 30;
var DEADLINE_MINUTES = 20;
var WALLETNOW_FEE = 0.0075;
var SwapSteps;
(function (SwapSteps) {
    SwapSteps[SwapSteps["INPUT"] = 0] = "INPUT";
    SwapSteps[SwapSteps["RETRIEVING_QUOTES"] = 1] = "RETRIEVING_QUOTES";
    SwapSteps[SwapSteps["NO_QUOTES_AVAILABLE"] = 2] = "NO_QUOTES_AVAILABLE";
    SwapSteps[SwapSteps["ERROR_IN_TRANSACTION"] = 3] = "ERROR_IN_TRANSACTION";
    SwapSteps[SwapSteps["WAITING_TRANSACTION"] = 4] = "WAITING_TRANSACTION";
    SwapSteps[SwapSteps["REVIEW_SWAP"] = 5] = "REVIEW_SWAP";
    SwapSteps[SwapSteps["TRANSACTION_FINISHED"] = 6] = "TRANSACTION_FINISHED";
})(SwapSteps || (SwapSteps = {}));
var SwapType;
(function (SwapType) {
    SwapType["Token"] = "TOKEN";
    SwapType["Fiat"] = "FIAT";
})(SwapType || (SwapType = {}));
var FiatSwapMode;
(function (FiatSwapMode) {
    FiatSwapMode["IFRAME"] = "IFRAME";
    FiatSwapMode["NATIVE"] = "NATIVE";
})(FiatSwapMode || (FiatSwapMode = {}));
var FIAT_SWAP_MODE = (IS_BETA && false) ? FiatSwapMode.NATIVE : FiatSwapMode.IFRAME;
var SwapDialogContent = function (props) {
    var classes = useStyles(props);
    var t = useTranslation().t;
    var dialog = useDialog();
    var connection = useContext(ConnectContext);
    var isConnected = connection.connect && connection.address;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var amountFrom = useRef(FixedNumber.fromString('0'));
    var amountTo = useRef(FixedNumber.fromString('0'));
    var _a = useState(SwapSteps.INPUT), step = _a[0], setStep = _a[1];
    var _b = useState(), balance = _b[0], setBalance = _b[1];
    var _c = useState(''), inputAmount = _c[0], setInputAmount = _c[1];
    var _d = useState(''), outputAmount = _d[0], setOutputAmount = _d[1];
    var _e = useState(), inputToken = _e[0], setInputToken = _e[1];
    var _f = useState(), outputToken = _f[0], setOutputToken = _f[1];
    var _g = useState(false), inputError = _g[0], setInputError = _g[1];
    var customSlippageNumber = useRef();
    var _h = useState(""), customSlippage = _h[0], setCustomSlippage = _h[1];
    var _j = useState(2), slippage = _j[0], setSlippage = _j[1];
    var _k = useState(false), editingCustomSlippage = _k[0], setEditingCustomSlippage = _k[1];
    var _l = useState(0), quotesMonitorCounter = _l[0], setQuotesMonitorCounter = _l[1];
    var _m = useState(false), swapFromTo = _m[0], setSwapFromTo = _m[1];
    var _o = useState(false), allowanceRequired = _o[0], setAllowanceRequired = _o[1];
    var dexQuoteStates = useRef([]);
    var pairFactories = useRef([]);
    var tradeContexts = useRef([]);
    var _p = useState(-1), bestDexIndex = _p[0], setBestDexIndex = _p[1];
    var _q = useState(ChainIds.BSC), chainId = _q[0], setChainId = _q[1];
    var _r = useState([]), filterChainIds = _r[0], setFilterChainIds = _r[1];
    var _s = useState([ChainIds.BSC, ChainIds.Ethereum, ChainIds.Polygon]), fiatFilterChainIds = _s[0], setFiatFilterChainIds = _s[1];
    var _t = useState(0), timeOffset = _t[0], setTimeOffset = _t[1];
    var chainConfig = ChainConfigs[chainId];
    var cashBackRewardToken = useRef();
    var _u = useState(false), showSwapSettings = _u[0], setShowSwapSettings = _u[1];
    var _v = useState(DEFAULT_SWAP_SETTINGS), swapSettings = _v[0], setSwapSettings = _v[1];
    var _w = useState(SwapType.Token), swapType = _w[0], setSwapType = _w[1];
    var _x = useState(), swapFiatToTokenData = _x[0], setSwapFiatToTokenData = _x[1];
    var distributedExchanges = chainConfig.distributedExchanges.filter(function (dex) {
        if (swapSettings.dexMode === DexMode.DEX_MODE_ALL) {
            return true;
        }
        return swapSettings.preferredDexes[chainId].includes(dex.factory);
    });
    var dexName = (distributedExchanges[bestDexIndex] || {}).name;
    useEffect(function () {
        var _a;
        var settings = JSON.parse((_a = localStorage.getItem('swapSettings')) !== null && _a !== void 0 ? _a : JSON.stringify(DEFAULT_SWAP_SETTINGS));
        setSwapSettings(settings);
    }, [showSwapSettings]);
    useEffect(function () {
        fetch('/robots.txt')
            .then(function (response) {
            var dateHeader = response.headers.get('date');
            if (dateHeader) {
                try {
                    var serverTime = new Date(dateHeader);
                    var localTime = new Date();
                    var offset = serverTime.getTime() - localTime.getTime();
                    setTimeOffset(offset);
                }
                catch (error) {
                    console.log("Unable to get server time", error);
                }
            }
        });
    }, []);
    useEffect(function () {
        if (swapType === SwapType.Fiat && !swapFiatToTokenData) {
            fetch("".concat(API_BASE_URL, "/swap/fiat_to_token/data"))
                .then(function (response) { return response.json(); })
                .then(function (data) {
                if (data.success) {
                    var fiatToTokenData = data.result;
                    // TODO: extract supported chains from response -- then use setFiatFilterChainIds();
                    setSwapFiatToTokenData(fiatToTokenData);
                }
            });
        }
    }, [swapType, swapFiatToTokenData]);
    useEffect(function () {
        setFilterChainIds(ensureValidChainIds(props.filterChainIds || []));
    }, [props.filterChainIds]);
    useEffect(function () {
        if (props.chainId) {
            setChainId(props.chainId);
        }
    }, [props.chainId]);
    useEffect(function () {
        if (props.fromToken) {
            setInputToken(props.fromToken);
        }
        if (props.toToken) {
            setOutputToken(props.toToken);
        }
    }, [props.fromToken, props.toToken]);
    useEffect(function () {
        if (props.slippage) {
            if (!PREDEFINED_SLIPPAGES.includes(props.slippage)) {
                customSlippageNumber.current = props.slippage;
                setCustomSlippage("".concat(props.slippage));
                setEditingCustomSlippage(true);
            }
            setSlippage(props.slippage);
        }
    }, [props.slippage]);
    useEffect(function () {
        if (!chainConfig.walletNowSwap || !inputToken || !outputToken || step != SwapSteps.INPUT) {
            return;
        }
        var address = props.wallet || connection.address;
        pairFactories.current = Array(distributedExchanges.length);
        tradeContexts.current = Array(distributedExchanges.length);
        dexQuoteStates.current = distributedExchanges.map(function () { return DexQuoteProgressStates.CREATING_PAIR_FACTORY; });
        setBestDexIndex(-1);
        if (address) {
            distributedExchanges.forEach(function (dex, index) {
                var pancakeswapPair = new PancakeswapPair({
                    factoryAddress: dex.factory,
                    routerAddress: dex.router,
                    pairAddress: dex.factory,
                    multicallContractAddress: chainConfig.multiCall,
                    nativeTokenAddress: chainConfig.nativeToken,
                    providerUrl: chainConfig.rpc,
                    // the contract address of the token you want to convert FROM
                    fromTokenContractAddress: inputToken.token,
                    // the contract address of the token you want to convert TO
                    toTokenContractAddress: outputToken.token,
                    // the ethereum address of the user using this part of the dApp
                    ethereumAddress: address,
                    // you can pass in the provider url as well if you want
                    // providerUrl: YOUR_PROVIDER_URL,
                    settings: new PancakeswapPairSettings({
                        // if not supplied it will use `0.005` which is 0.5%
                        // please pass it in as a full number decimal so 0.7%
                        // would be 0.007
                        slippage: (slippage / 100) + WALLETNOW_FEE,
                        // if not supplied it will use 20 a deadline minutes
                        deadlineMinutes: DEADLINE_MINUTES,
                        // if not supplied it will try to use multihops
                        // if this is true it will require swaps to direct
                        // pairs
                        disableMultihops: false,
                    }),
                });
                console.log("Create factory for: ".concat(dex.name));
                // create factories, one for each dex
                pancakeswapPair.createFactory()
                    .then(function (pancakeswapPairFactory) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        pairFactories.current[index] = pancakeswapPairFactory;
                        updateDexQuoteState(index, DexQuoteProgressStates.PAIR_FACTORY_CREATED);
                        return [2 /*return*/];
                    });
                }); })
                    .catch(function (error) {
                    console.error(error);
                    updateDexQuoteState(index, DexQuoteProgressStates.FAILED);
                });
            });
        }
    }, [inputToken, outputToken, slippage, chainId, props.wallet, connection.address, swapSettings]);
    useEffect(function () {
        if (inputToken && inputToken.chainId != chainId) {
            setInputToken(undefined);
        }
        if (outputToken && outputToken.chainId != chainId) {
            setOutputToken(undefined);
        }
        setFixedOutput();
    }, [chainId, props.fixedOutput]);
    useEffect(function () {
        if (step == SwapSteps.RETRIEVING_QUOTES) {
            if (quotesMonitorCounter >= MAX_RETRIES) {
                // too much retries, give up
                setStep(SwapSteps.NO_QUOTES_AVAILABLE);
                setQuotesMonitorCounter(0);
            }
            var allFinished_1 = true;
            var startDelay_1 = 0;
            dexQuoteStates.current.forEach(function (dexQuoteState, index) {
                switch (dexQuoteState) {
                    case DexQuoteProgressStates.PAIR_FACTORY_CREATED:
                        // trigger trade calculation for this dex
                        updateDexQuoteState(index, DexQuoteProgressStates.RETRIEVING_AMOUNT);
                        var pairFactory_1 = pairFactories.current[index];
                        if (pairFactory_1) {
                            setTimeout(function () {
                                pairFactory_1.trade(inputAmount).then(function (trade) {
                                    tradeContexts.current[index] = trade;
                                    console.log("Trade for index ".concat(index, " = ").concat(trade.expectedConvertQuote));
                                    console.log(trade);
                                    updateDexQuoteState(index, DexQuoteProgressStates.SUCCEEDED);
                                })
                                    .catch(function (reason) {
                                    updateDexQuoteState(index, DexQuoteProgressStates.FAILED);
                                    console.error(reason);
                                });
                            }, startDelay_1);
                            startDelay_1 += 150;
                        }
                        allFinished_1 = false;
                        break;
                    case DexQuoteProgressStates.SUCCEEDED:
                    case DexQuoteProgressStates.FAILED:
                        // finished
                        break;
                    default:
                        allFinished_1 = false;
                }
            });
            if (allFinished_1) {
                var quoteAvailable_1 = false;
                var bestDexIndex_1 = -1;
                var maxTrade_1 = 0;
                dexQuoteStates.current.forEach(function (dexQuoteState, index) {
                    if (DexQuoteProgressStates.SUCCEEDED == dexQuoteState) {
                        quoteAvailable_1 = true;
                        var trade = tradeContexts.current[index];
                        if (trade && Number(trade.expectedConvertQuote) > maxTrade_1) {
                            maxTrade_1 = Number(trade.expectedConvertQuote);
                            bestDexIndex_1 = index;
                        }
                    }
                });
                if (quoteAvailable_1) {
                    setStep(SwapSteps.REVIEW_SWAP);
                    setBestDexIndex(bestDexIndex_1);
                    var bestTradeContext = tradeContexts.current[bestDexIndex_1];
                    if (bestTradeContext) {
                        setOutputAmount(bestTradeContext.expectedConvertQuote);
                    }
                }
                else {
                    setStep(SwapSteps.NO_QUOTES_AVAILABLE);
                    setQuotesMonitorCounter(0);
                }
            }
            else {
                // keep monitoring
                setTimeout(function () { return setQuotesMonitorCounter(quotesMonitorCounter + 1); }, 500);
            }
        }
    }, [quotesMonitorCounter, step]);
    useEffect(function () {
        var address = props.wallet || connection.address;
        if (!inputToken || !address) {
            if (balance) {
                setBalance(undefined);
            }
            return;
        }
        var chainConfig = ChainConfigs[chainId];
        var web3 = new Web3(chainConfig.rpc);
        if (isSameAddress(chainConfig.nativeToken, inputToken.token)) {
            web3.eth.getBalance(address)
                .then(function (balance) {
                setBalance(FixedNumber.fromValue(BigNumber.from(balance), inputToken.decimals).toString());
            })
                .catch(function (error) {
                console.error(error);
                setBalance('0');
            });
        }
        else {
            var contract = new web3.eth.Contract(erc20Abi, inputToken.token);
            contract.methods
                .balanceOf(address)
                .call()
                .then(function (balance) {
                setBalance(FixedNumber.fromValue(BigNumber.from(balance), inputToken.decimals).toString());
            })
                .catch(function (error) {
                console.error(error);
                setBalance('0');
            });
        }
    }, [inputToken, chainId, props.wallet, connection.address]);
    var setFixedOutput = function () {
        // try to set output token to a fixed one, if specified
        if (props.fixedOutput) {
            var fixedOutputToken = PREDEFINED_SWAP_TOKENS[chainId].find(function (token) { return token.symbol == props.fixedOutput; });
            if (fixedOutputToken) {
                setOutputToken(fixedOutputToken);
            }
        }
    };
    var updateDexQuoteState = function (index, newDexQuoteState) {
        dexQuoteStates.current[index] = newDexQuoteState;
        console.log("index: ".concat(index, " quote state updated to ").concat(newDexQuoteState));
    };
    var handleChangeAmountFrom = function (amount, amountFN) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            amountFrom.current = amountFN;
            setInputAmount(amount);
            return [2 /*return*/];
        });
    }); };
    var handleChangeTokenFrom = function (tokenInfo) {
        setBalance(undefined);
        if (outputToken && isSameAddress(tokenInfo.token, outputToken.token)) {
            setOutputToken(undefined);
        }
        setInputToken(tokenInfo);
    };
    var handleChangeAmountTo = function (amount, amountFN) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // output amount is hidden in the first version
            amountTo.current = amountFN;
            setOutputAmount(amount);
            return [2 /*return*/];
        });
    }); };
    var handleChangeTokenTo = function (tokenInfo) {
        if (inputToken && isSameAddress(tokenInfo.token, inputToken.token)) {
            setBalance(undefined);
            setInputToken(undefined);
        }
        setOutputToken(tokenInfo);
    };
    var handleCashBackRewardTokenChange = function (address) {
        cashBackRewardToken.current = address;
    };
    var handleChangeError = function (hasInputError) {
        setInputError(hasInputError);
    };
    var handleClickPredefinedSlippage = function (value) { return function () {
        setEditingCustomSlippage(false);
        setSlippage(value);
    }; };
    var handleEditCustomSlippage = function () {
        setEditingCustomSlippage(true);
    };
    var handleCustomSlippageChange = function (event) {
        var slippageString = event.target.value;
        var slippageParsed = parseInt(slippageString);
        customSlippageNumber.current = isNaN(slippageParsed) ? undefined : slippageParsed;
        if (customSlippageNumber.current !== undefined) {
            setSlippage(customSlippageNumber.current);
        }
        setCustomSlippage(slippageString);
    };
    var handleClickReviewSwap = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(!isConnected && connection.connect)) return [3 /*break*/, 2];
                    return [4 /*yield*/, connection.connect()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2:
                    setStep(SwapSteps.RETRIEVING_QUOTES);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClickBack = function () {
        if (inputToken) {
            // this looks weird, but it is to cause useEffect to be called and thus reset the quotes,
            // like if a new token were just selected.
            setInputToken(__assign({}, inputToken));
        }
        setQuotesMonitorCounter(0);
        setStep(SwapSteps.INPUT);
    };
    /**
     * Generates the trade datetime unix time
     */
    var generateTradeDeadlineUnixTime = function () {
        var now = new Date();
        var expiryDate = new Date(now.getTime() +
            timeOffset +
            (DEADLINE_MINUTES * 60000));
        return (expiryDate.getTime() / 1e3) | 0;
    };
    var decimalToBigNumberJS = function (amount, decimals) {
        return new BigNumberJS(amount)
            .shiftedBy(decimals)
            .decimalPlaces(0);
    };
    var handleClickSwap = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dex, provider, web3, contract, methods, tradeContext, addressToCheck, address, amountIn, amountInHex, amountOutMin, amountOutMinHex, path, to, deadline, referrer, tradePath, method_1, tokenContract_1, allowance, allowanceBN, error_1, value_1;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    dex = distributedExchanges[bestDexIndex];
                    if (!(chainConfig.walletNowSwap && ((_a = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _a === void 0 ? void 0 : _a.provider) && dex)) return [3 /*break*/, 7];
                    provider = (_b = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _b === void 0 ? void 0 : _b.provider;
                    web3 = new Web3(provider);
                    contract = new web3.eth.Contract(walletNowSwapAbi, chainConfig.walletNowSwap);
                    methods = contract.methods;
                    tradeContext = tradeContexts.current[bestDexIndex];
                    addressToCheck = props.wallet || connection.address;
                    if (!addressToCheck) {
                        // no valid address to check, probably wallet not connected
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, checkWalletConnected(connection, dialog, chainId, addressToCheck)];
                case 1:
                    address = _g.sent();
                    if (!address || (props.wallet && address.toLowerCase() != props.wallet.toLowerCase())) {
                        return [2 /*return*/];
                    }
                    amountIn = decimalToBigNumberJS(inputAmount, tradeContext === null || tradeContext === void 0 ? void 0 : tradeContext.fromToken.decimals);
                    amountInHex = hexlify(amountIn);
                    amountOutMin = decimalToBigNumberJS(tradeContext === null || tradeContext === void 0 ? void 0 : tradeContext.minAmountConvertQuote, tradeContext === null || tradeContext === void 0 ? void 0 : tradeContext.toToken.decimals);
                    amountOutMinHex = hexlify(amountOutMin);
                    path = tradeContext === null || tradeContext === void 0 ? void 0 : tradeContext.routePath;
                    to = connection.address;
                    deadline = generateTradeDeadlineUnixTime().toString();
                    referrer = props.referrer || ZERO_ADDR;
                    console.log("router: ".concat(dex.router, "\nto: ").concat(to, "\npath: ").concat(path, "\namountOutMinHex: ").concat(amountOutMinHex, "\ndeadline: ").concat(deadline, "\nreferrer: ").concat(referrer));
                    tradePath = getTradePath(tradeContext === null || tradeContext === void 0 ? void 0 : tradeContext.fromToken, tradeContext === null || tradeContext === void 0 ? void 0 : tradeContext.toToken, chainConfig.nativeToken);
                    switch (tradePath) {
                        case TradePath.ethToErc20:
                            if (!chainConfig.cashBackEnabled) {
                                method_1 = methods
                                    .swapExactETHForTokens(dex.router, referrer, amountOutMinHex, path, to, deadline);
                            }
                            else {
                                method_1 = methods
                                    .swapExactETHForTokens_V2(dex.router, referrer, amountOutMinHex, path, to, deadline, (_c = cashBackRewardToken.current) !== null && _c !== void 0 ? _c : ZERO_ADDR);
                            }
                            break;
                        case TradePath.erc20ToEth:
                            if (!chainConfig.cashBackEnabled) {
                                method_1 = methods
                                    .swapExactTokensForETH(dex.router, referrer, amountInHex, amountOutMinHex, path, to, deadline);
                            }
                            else {
                                method_1 = methods
                                    .swapExactTokensForETH_V2(dex.router, referrer, amountInHex, amountOutMinHex, path, to, deadline, (_d = cashBackRewardToken.current) !== null && _d !== void 0 ? _d : ZERO_ADDR);
                            }
                            break;
                        case TradePath.erc20ToErc20:
                            if (!chainConfig.cashBackEnabled) {
                                method_1 = methods
                                    .swapExactTokensForTokens(dex.router, referrer, amountInHex, amountOutMinHex, path, to, deadline);
                            }
                            else {
                                method_1 = methods
                                    .swapExactTokensForTokens_V2(dex.router, referrer, amountInHex, amountOutMinHex, path, to, deadline, (_e = cashBackRewardToken.current) !== null && _e !== void 0 ? _e : ZERO_ADDR);
                            }
                            break;
                    }
                    if (!(tradePath != TradePath.ethToErc20)) return [3 /*break*/, 5];
                    tokenContract_1 = new web3.eth.Contract(erc20Abi, (_f = tradeContext === null || tradeContext === void 0 ? void 0 : tradeContext.fromToken) === null || _f === void 0 ? void 0 : _f.contractAddress);
                    _g.label = 2;
                case 2:
                    _g.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, tokenContract_1.methods.allowance(connection.address, chainConfig.walletNowSwap).call()];
                case 3:
                    allowance = _g.sent();
                    allowanceBN = new BigNumberJS(allowance);
                    if (allowanceBN.lt(amountIn)) {
                        setAllowanceRequired(true);
                        dialog.confirm({
                            title: t('ALLOWANCE_REQUIRED', 'Allowance Required'),
                            message: t('ALLOWANCE_DISCLAIMER', 'Before swapping, please approve allowance for this token'),
                            ok: {
                                text: t('ENABLE_TOKEN', 'Enable {{token}}', { token: tradeContext === null || tradeContext === void 0 ? void 0 : tradeContext.fromToken.symbol }),
                                variant: 'contained',
                                color: 'primary'
                            },
                            cancel: {
                                text: t('CANCEL', 'Cancel'),
                                variant: 'outlined',
                                color: 'default'
                            }
                        })
                            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                            var approve, opts, gas;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        approve = tokenContract_1.methods.approve(chainConfig.walletNowSwap, '0xffffffffffffffffffffffffffffffffffffffff');
                                        opts = { from: connection.address };
                                        return [4 /*yield*/, approve.estimateGas(opts)];
                                    case 1:
                                        gas = _a.sent();
                                        console.log("Gas for approve: ".concat(gas));
                                        return [4 /*yield*/, approve.send(opts)];
                                    case 2:
                                        _a.sent();
                                        setAllowanceRequired(false);
                                        return [2 /*return*/];
                                }
                            });
                        }); })
                            .catch(function () {
                            setAllowanceRequired(false);
                        });
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _g.sent();
                    console.log(error_1);
                    enqueueSnackbar(t('ERROR_APPROVING_TOKEN', 'Error approving token for swapping on WalletNow.'), { variant: 'error' });
                    setAllowanceRequired(false);
                    return [2 /*return*/];
                case 5:
                    value_1 = tradePath == TradePath.ethToErc20 ? amountIn : undefined;
                    return [4 /*yield*/, method_1.estimateGas({
                            from: connection.address,
                            value: value_1
                        })
                            .then(function (gas) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.log("Estimated gas: " + gas);
                                        setStep(SwapSteps.WAITING_TRANSACTION);
                                        return [4 /*yield*/, method_1
                                                .send({
                                                from: connection.address,
                                                value: value_1
                                            })
                                                .on('receipt', function (receipt) {
                                                enqueueSnackbar(t('TRANSACTION_CONFIRMED', 'Transaction confirmed!'), { variant: 'success' });
                                                console.log(receipt);
                                                try {
                                                    if (props.onSwapFinished) {
                                                        props.onSwapFinished(true);
                                                    }
                                                    else {
                                                        // if there is no callback, we need to display the finished status
                                                        try {
                                                            // dialog might be unmounted at this point
                                                            setStep(SwapSteps.TRANSACTION_FINISHED);
                                                        }
                                                        catch (_a) {
                                                        }
                                                    }
                                                }
                                                catch (_b) {
                                                }
                                            })
                                                .on('error', function (error) {
                                                enqueueSnackbar(error.message, { variant: 'error' });
                                                console.log(error);
                                                try {
                                                    // dialog might be unmounted at this point
                                                    setStep(SwapSteps.ERROR_IN_TRANSACTION);
                                                }
                                                catch (_a) {
                                                }
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })
                            .catch(function (error) {
                            console.log(error);
                            if (error.code != 4001) {
                                var errorMessage = error.message;
                                try {
                                    // Try to parse the inner error string
                                    var json = JSON.parse(error.message.replace('Internal JSON-RPC error.', ''));
                                    if (json.message) {
                                        errorMessage = json.message;
                                    }
                                }
                                catch (_a) { }
                                dialog.alert({
                                    title: t('ERROR_TESTING_SWAP_TRANSACTION', 'Error testing the swap transaction'),
                                    message: t('ERROR_TESTING_SWAP_TRANSACTION_MSG', 'If necessary, adjust the slippage. Error details: {{errorMessage}}', { errorMessage: errorMessage }),
                                });
                            }
                        })];
                case 6:
                    _g.sent();
                    _g.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleClickDismiss = function () {
        if (props.onSwapFinished) {
            props.onSwapFinished(false);
        }
    };
    var handleClickSwapFromTo = function () {
        var newInputToken = (outputToken) ? __assign({}, outputToken) : undefined;
        var newOutputToken = (inputToken) ? __assign({}, inputToken) : undefined;
        setInputToken(newInputToken);
        setOutputToken(newOutputToken);
        setSwapFromTo(!swapFromTo);
    };
    var onClickChain = function (chainId) {
        setChainId(chainId);
    };
    var isHighSlippage = function (num) {
        return !isNaN(num) && num > 5 && (parsedCustomSlippage > (props.slippage || 5));
    };
    var handleSwapSettingsClick = function () {
        setShowSwapSettings(true);
    };
    var handleCloseSwapSettings = function () {
        setShowSwapSettings(false);
    };
    var getSwapTypeButtonColor = function (type) {
        return (type == swapType) ? 'primary' : 'secondary';
    };
    var handleChangeSwapType = function (type) { return function (event) {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (type === SwapType.Fiat && !isConnected) {
            dialog.alert({
                title: t('CONNECT_YOUR_WALLET', 'Connect your wallet'),
                message: t('PLEASE_CONNECT_YOUR_WALLET_FIRST', 'Please, connect your wallet first'),
            });
            return;
        }
        setSwapType(type);
    }; };
    var parsedCustomSlippage = parseInt(customSlippage);
    var slippageError = editingCustomSlippage && (isNaN(parsedCustomSlippage) || parsedCustomSlippage < 0);
    var slippageErrorMessage = (slippageError) ? t('INPUT_VALID_SLIPPAGE', 'Please set a valid slippage') : undefined;
    // non blocking slippage error
    if (editingCustomSlippage && isHighSlippage(parsedCustomSlippage)) {
        slippageErrorMessage = t('SLIPPAGE_VERY_HIGH', 'Slippage is very high');
    }
    var reviewDisabled = (amountFrom.current.isZero() ||
        amountFrom.current.isNegative() ||
        !inputToken ||
        !outputToken ||
        inputError ||
        slippageError) && Boolean(isConnected);
    return (React.createElement(React.Fragment, null,
        React.createElement(SwapSettingsDialog, { show: showSwapSettings, onClose: handleCloseSwapSettings }),
        React.createElement("div", { className: classes.container },
            React.createElement(Grid, { container: true, spacing: 2 },
                (swapType == SwapType.Token || FIAT_SWAP_MODE === FiatSwapMode.NATIVE) && (React.createElement(React.Fragment, null,
                    (props.showSettings) && (React.createElement(Grid, { item: true, xs: (props.showWalletConnectionWidget) ? 1 : 12 },
                        React.createElement(IconButton, { color: "inherit", onClick: handleSwapSettingsClick, "aria-label": t('SWAP_SETTINGS', 'Swap Settings'), "data-ga-event-category": "header", "data-ga-event-action": "dark" },
                            React.createElement(SettingsIcon, null)))),
                    (props.showWalletConnectionWidget) && (React.createElement(Grid, { item: true, xs: (props.showSettings) ? 11 : 12 },
                        React.createElement(Box, { className: classes.walletConnectionContainer },
                            React.createElement("div", { className: classes.spacer }),
                            React.createElement(WalletConnection, null)))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Box, { className: classes.container },
                            React.createElement("div", { className: classes.spacer }),
                            React.createElement(ChainSelector, { chainId: chainId, chainIds: (swapType === SwapType.Token) ? filterChainIds : fiatFilterChainIds, onClickChain: onClickChain, disabled: !isConnected }))))),
                (swapType == SwapType.Token) && (React.createElement(React.Fragment, null,
                    (step == SwapSteps.INPUT) && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TokenInput, { tokens: PREDEFINED_SWAP_TOKENS[chainId], autoFocus: true, allowUserDefinedTokens: true, amount: inputAmount, token: inputToken, label: t('SWAP_FROM', 'From'), chainId: chainId, balance: balance, onChangeAmount: handleChangeAmountFrom, onChangeToken: handleChangeTokenFrom, onChangeError: handleChangeError, disabled: !isConnected })),
                        React.createElement(Grid, { item: true, xs: 12, className: classes.fabContainer },
                            React.createElement(IconButton, { onClick: handleClickSwapFromTo, "aria-label": t('SWAP_FROM_TO', 'Swap from/to'), disabled: !isConnected, className: classes.swapFromToButton, size: "small" },
                                React.createElement(ArrowDownwardIcon, { className: "".concat(classes.swapFromTo, " ").concat((swapFromTo) ? classes.swapFromToInvert : '') }))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(TokenInput, { tokens: PREDEFINED_SWAP_TOKENS[chainId], allowUserDefinedTokens: true, amount: outputAmount, token: outputToken, label: t('SWAP_TO', 'To'), chainId: chainId, onChangeAmount: handleChangeAmountTo, onChangeToken: handleChangeTokenTo, hiddenInput: true, disabled: !isConnected })),
                        React.createElement(Grid, { item: true, xs: 12 }, props.showCashBack && (React.createElement(SwapCashBack, { chainId: chainId, token: outputToken, onCashBackRewardTokenChange: handleCashBackRewardTokenChange }))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement("span", { className: classes.slippageLabel }, t('SLIPPAGE_TOLERANCE', 'Slippage Tolerance')),
                            PREDEFINED_SLIPPAGES.map(function (predefinedSlippage) {
                                var selected = (predefinedSlippage == slippage && !editingCustomSlippage);
                                return (React.createElement(Chip, { className: classes.chip, key: predefinedSlippage, onClick: handleClickPredefinedSlippage(predefinedSlippage), variant: (selected) ? "default" : "outlined", color: (selected) ? "primary" : "default", size: "small", label: predefinedSlippage, onDelete: handleClickPredefinedSlippage(predefinedSlippage), deleteIcon: React.createElement("div", { className: (selected) ? classes.percentSelected : classes.percent }, "%"), disabled: !isConnected }));
                            }),
                            React.createElement(Chip, { className: classes.chip, variant: (editingCustomSlippage || (customSlippageNumber === null || customSlippageNumber === void 0 ? void 0 : customSlippageNumber.current) == slippage) ? "default" : "outlined", color: (editingCustomSlippage || (customSlippageNumber === null || customSlippageNumber === void 0 ? void 0 : customSlippageNumber.current) == slippage) ? "primary" : "default", onClick: handleEditCustomSlippage, size: "small", label: (!editingCustomSlippage) ?
                                    t('CUSTOM', 'Custom') :
                                    React.createElement(TextField, { type: "number", autoFocus: true, inputMode: "decimal", InputProps: {
                                            disableUnderline: true,
                                            classes: { input: classes.chipInput },
                                        }, className: classes.chipEditor, value: customSlippage, onChange: handleCustomSlippageChange }), onDelete: handleEditCustomSlippage, deleteIcon: React.createElement("div", { className: (editingCustomSlippage || (customSlippageNumber === null || customSlippageNumber === void 0 ? void 0 : customSlippageNumber.current) == slippage) ? classes.percentSelected : classes.percent }, "%"), disabled: !isConnected }),
                            slippageErrorMessage && (React.createElement(Typography, { variant: "caption", color: "error" }, slippageErrorMessage))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Button, { className: classes.swapStepButtonPrimary, disabled: reviewDisabled, variant: "contained", color: "primary", fullWidth: true, onClick: handleClickReviewSwap }, (isConnected) ? t('REVIEW_SWAP', 'Review Swap') : t('CONNECT_WALLET', 'Connect Wallet'))))),
                    (step == SwapSteps.RETRIEVING_QUOTES) && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Typography, { variant: "subtitle1", align: "center" }, t('RETRIEVING_QUOTES_FROM_DEXES', 'Retrieving quotes from multiple de-centralized exchanges ({{done}}/{{total}})', { done: dexQuoteStates.current.filter(function (quoteState) { return [DexQuoteProgressStates.SUCCEEDED, DexQuoteProgressStates.FAILED].includes(quoteState); }).length, total: dexQuoteStates.current.length }))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(LinearProgress, { color: "primary" })),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement("ul", null, distributedExchanges.map(function (dex, index) {
                                return (React.createElement("li", { key: "".concat(dex.name, "_").concat(index), className: classes.listItem },
                                    React.createElement(DexQuoteProgress, { state: dexQuoteStates.current[index], className: classes.progress }),
                                    dex.name));
                            }))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Typography, { variant: "subtitle2", align: "center" }, t('PLEASE_WAIT', 'Please wait...'))))),
                    (step == SwapSteps.NO_QUOTES_AVAILABLE || step == SwapSteps.ERROR_IN_TRANSACTION || step == SwapSteps.TRANSACTION_FINISHED) && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: 12 },
                            (step == SwapSteps.NO_QUOTES_AVAILABLE) && (React.createElement(Typography, { variant: "subtitle1", align: "center" }, t('NO_QUOTES_AVAILABLE', 'No quotes available for given tokens. Please try again later.'))),
                            (step == SwapSteps.ERROR_IN_TRANSACTION) && (React.createElement(Typography, { variant: "subtitle1", align: "center" }, t('ERROR_IN_TRANSACTION', 'Transaction not completed. Please try again.'))),
                            (step == SwapSteps.TRANSACTION_FINISHED) && (React.createElement(Typography, { variant: "subtitle1", align: "center" }, t('TRANSACTION_FINISHED', 'Transaction completed successfully!')))),
                        React.createElement(Grid, { item: true, xs: 3 }),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(Button, { className: classes.swapStepButtonDefault, variant: "outlined", color: "default", fullWidth: true, onClick: handleClickBack }, (step == SwapSteps.TRANSACTION_FINISHED) ? t('NEW_SWAP', 'New Swap') : t('BACK', 'Back'))),
                        React.createElement(Grid, { item: true, xs: 3 }))),
                    (step == SwapSteps.WAITING_TRANSACTION) && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Typography, { variant: "subtitle1", align: "center" }, t('WAITING_FOR_TRANSACTION_TO_FINISH', 'Waiting for transaction to finish.'))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(LinearProgress, { color: "primary" })),
                        React.createElement(Grid, { item: true, xs: 3 }),
                        React.createElement(Grid, { item: true, xs: 6 }, props.onSwapFinished && (React.createElement(Button, { className: classes.swapStepButtonDefault, variant: "outlined", color: "default", fullWidth: true, onClick: handleClickDismiss }, t('CONTINUE_IN_BACKGROUND', 'Continue in background')))),
                        React.createElement(Grid, { item: true, xs: 3 }))),
                    (step == SwapSteps.REVIEW_SWAP) && (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Box, { textAlign: "center" },
                                React.createElement(Typography, { variant: "caption", align: "center" },
                                    React.createElement(Trans, { i18nKey: "BEST_SWAP_DEX" },
                                        "We found the best deal for you! Your swap will use: ",
                                        React.createElement("b", null, { dexName: dexName }))))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Typography, { variant: "subtitle1", align: "center" }, inputAmount)),
                        React.createElement(Grid, { item: true, xs: 12 }, inputToken && (React.createElement(TokenImage, { className: classes.centralize, showSymbol: true, token: inputToken, chainId: chainId }))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Box, { textAlign: "center" },
                                React.createElement(ArrowDownwardIcon, null))),
                        React.createElement(Grid, { item: true, xs: 12 }, outputToken && (React.createElement(TokenImage, { className: classes.centralize, showSymbol: true, token: outputToken, chainId: chainId }))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Typography, { variant: "h5", align: "center" }, outputAmount)),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Box, { textAlign: "center" },
                                React.createElement(Typography, { variant: "caption" },
                                    React.createElement(Trans, { i18nKey: "ALL_FEES_INCLUDED_SEE_TOS" },
                                        "All fees included. See our ",
                                        React.createElement(Link, { target: "_blank", rel: "noopener", href: "https://docs.walletnow.net/features/swap/terms-of-service" },
                                            "terms of service",
                                            React.createElement(OpenInNewIcon, { className: classes.openIcon, fontSize: "small" })))))),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(Button, { className: classes.swapStepButtonDefault, variant: "outlined", color: "default", fullWidth: true, onClick: handleClickBack }, t('BACK', 'Back'))),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(Button, { className: classes.swapStepButtonPrimary, disabled: allowanceRequired, variant: "contained", color: "primary", fullWidth: true, onClick: handleClickSwap }, t('SWAP', 'Swap'))))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Box, { textAlign: "center" },
                            React.createElement(Link, { href: "#", onClick: handleChangeSwapType(SwapType.Fiat), className: classes.swapTypeLink },
                                React.createElement(CreditCardIcon, { className: classes.swapTypeIcon }),
                                "\u00A0",
                                t('BUY_BNB_WITH_CC', 'Buy BNB with Credit Card')))))),
                (swapType == SwapType.Fiat) && (FIAT_SWAP_MODE === FiatSwapMode.IFRAME) &&
                    React.createElement(React.Fragment, null,
                        React.createElement(SwapFiatIframe, null),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Box, { textAlign: "center" },
                                React.createElement(Link, { href: "#", onClick: handleChangeSwapType(SwapType.Token), className: classes.swapTypeLink },
                                    React.createElement(TollIcon, { className: classes.swapTypeIcon }),
                                    "\u00A0",
                                    t('SWAP_TOKENS', 'Swap tokens'))))),
                (swapType == SwapType.Fiat) && (FIAT_SWAP_MODE === FiatSwapMode.NATIVE) && (React.createElement(SwapFiatNative, { chainId: chainId, swapFiatToTokenData: swapFiatToTokenData }))))));
};
export default SwapDialogContent;
