var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { setShowPlan } from '../store/actions';
import PlanDialogContent from './PlanDialogContent';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        iconButtonClose: {
            float: 'right',
            marginTop: 2,
        },
    });
});
var PlanDialog = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var membershipInfo = props.accountData.membershipInfo;
    var handleClose = function () {
        props.setShowPlan(false);
    };
    return (React.createElement(Dialog, { onClose: handleClose, "aria-labelledby": "dialog-title", open: props.showPlan },
        React.createElement(DialogTitle, { id: "dialog-title", disableTypography: true },
            React.createElement(Typography, { variant: "h6", color: "inherit" },
                t('PLAN', 'Plan'),
                React.createElement(IconButton, { color: "inherit", className: classes.iconButtonClose, "aria-label": t('CLOSE', 'Close'), size: "small", onClick: handleClose },
                    React.createElement(CloseIcon, null)))),
        React.createElement(DialogContent, null,
            React.createElement(PlanDialogContent, { membershipInfo: membershipInfo }))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ setShowPlan: setShowPlan }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(PlanDialog);
