import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        adContainer: {
            cursor: 'pointer',
        },
        desktopContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        mobileContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        imageWrapper: {
            fontSize: 0,
        },
        image: {
            width: '100%',
        },
    });
});
var AdTypes;
(function (AdTypes) {
    AdTypes["A_ADS"] = "A-ADS";
    AdTypes["SELF_HOSTED"] = "SELF-HOSTED";
})(AdTypes || (AdTypes = {}));
var ads = [
    {
        type: AdTypes.A_ADS,
        from: '2022-05-01',
        to: '2030-01-01',
    },
    {
        type: AdTypes.SELF_HOSTED,
        desktop: '/olympus_ad_1024x140.v1.gif',
        mobile: '/olympus_ad_600x200.v1.gif',
        from: '2022-01-05',
        to: '2022-02-13',
        link: 'https://olympustoken.io/',
    },
    {
        type: AdTypes.SELF_HOSTED,
        desktop: '/ad_xhash_1024x140.v1.png',
        mobile: '/ad_xhash_600x200.v1.png',
        from: '2022-02-14',
        to: '2022-02-21',
        link: 'http://xhash.farm/',
    },
    {
        type: AdTypes.SELF_HOSTED,
        desktop: '/ad_fcfpay_desktop.gif',
        mobile: '/ad_fcfpay_mobile.gif',
        from: '2022-03-06',
        to: '2022-03-12',
        link: 'https://www.fcfpay.com',
    },
    {
        type: AdTypes.SELF_HOSTED,
        desktop: '/ad_streetpunks_desktop.v2.gif',
        mobile: '/ad_streetpunks_mobile.v2.gif',
        from: '2022-03-07',
        to: '2022-03-13',
        link: 'https://street-punks.com/',
    },
    {
        type: AdTypes.SELF_HOSTED,
        desktop: '/fcf_casino_1024x140.v1.gif',
        mobile: '/fcf_casino_1024x140.v1.gif',
        from: '2022-06-27',
        to: '2022-06-29',
        link: 'https://www.fcfpoker.com/',
    },
    {
        type: AdTypes.SELF_HOSTED,
        desktop: '/fcfpay_1024x140.v1.gif',
        mobile: '/fcfpay_600x200.v1.gif',
        from: '2022-11-12',
        to: '2022-11-23',
        link: 'https://fcfpay.com/prepaid-crypto-debit-cards/',
    },
];
var Ad = function () {
    var theme = useTheme();
    var classes = useStyles();
    var location = useLocation();
    var _a = useState(-1), selectedAd = _a[0], setSelectedAd = _a[1];
    var queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    var isXs = useMediaQuery(theme.breakpoints.only('xs')) || queryString.res == 'xs';
    var isSm = useMediaQuery(theme.breakpoints.only('sm')) || queryString.res == 'sm';
    var isMd = useMediaQuery(theme.breakpoints.only('md')) || queryString.res == 'md';
    var isLgOrLarger = !isXs && !isSm && !isMd;
    useEffect(function () {
        if (queryString.embed == 'true') {
            // do not show ads on embedded
            return;
        }
        var now = (new Date()).toISOString().substring(0, 10);
        var activeAds = [];
        ads.filter(function (ad, index) {
            if (now >= ad.from && (ad.to == null || now <= ad.to)) {
                activeAds.push(index);
            }
        });
        if (activeAds.length > 0) {
            setSelectedAd(activeAds[Math.floor(Math.random() * activeAds.length)]);
        }
    }, []);
    var handleClickAd = function () {
        window.open(ads[selectedAd].link, '_blank');
    };
    if (selectedAd == -1) {
        return null;
    }
    // Force type a-ads when res query string parameter is specified, so a-ads scanner doesn't fail.
    var adType = (!queryString.res) ? ads[selectedAd].type : AdTypes.A_ADS;
    switch (adType) {
        case AdTypes.SELF_HOSTED:
            return (React.createElement(Box, { className: classes.adContainer },
                React.createElement(Hidden, { xsDown: true },
                    React.createElement(Box, { className: classes.desktopContainer },
                        React.createElement("div", { className: classes.imageWrapper, onClick: handleClickAd },
                            React.createElement("img", { className: classes.image, src: ads[selectedAd].desktop })))),
                React.createElement(Hidden, { smUp: true },
                    React.createElement(Box, { className: classes.mobileContainer },
                        React.createElement("div", { className: classes.imageWrapper, onClick: handleClickAd },
                            React.createElement("img", { className: classes.image, src: ads[selectedAd].mobile }))))));
        case AdTypes.A_ADS:
        default:
            return (React.createElement(Box, { px: "20px", display: "flex", justifyContent: "center" },
                isXs && React.createElement("iframe", { "data-aa": '1994431', src: '//ad.a-ads.com/1994431?size=320x100', style: { width: '320px', height: '100px', border: 0, padding: 0, overflow: 'hidden', backgroundColor: 'transparent' } }),
                isSm && React.createElement("iframe", { "data-aa": '1994433', src: '//ad.a-ads.com/1994433?size=468x60', style: { width: '468px', height: '60px', border: 0, padding: 0, overflow: 'hidden', backgroundColor: 'transparent' } }),
                isMd && React.createElement("iframe", { "data-aa": '1994430', src: '//ad.a-ads.com/1994430?size=728x90', style: { width: '728px', height: '90px', border: 0, padding: 0, overflow: 'hidden', backgroundColor: 'transparent' } }),
                isLgOrLarger && React.createElement("iframe", { "data-aa": '1994434', src: '//ad.a-ads.com/1994434?size=970x90', style: { width: '970px', height: '90px', border: 0, padding: 0, overflow: 'hidden', backgroundColor: 'transparent' } })));
    }
};
export default Ad;
