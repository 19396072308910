var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var CandlestickChart = function (props) {
    return (React.createElement(SvgIcon, __assign({ viewBox: "0 0 24 24", width: 24, height: 24 }, props, { key: "candlestick-chart" }),
        React.createElement("path", { d: "M9 4H7v2H5v12h2v2h2v-2h2V6H9zm10 4h-2V4h-2v4h-2v7h2v5h2v-5h2z" })));
};
export default CandlestickChart;
