var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Cronos = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 41 40", key: "cronos" }),
        React.createElement("path", { d: "M20.464 1.667l-16.63 9.457v18.908l16.63 9.45 16.618-9.45V11.124L20.464 1.666zM32.16 27.23l-11.695 6.65-11.7-6.65V13.92l11.7-6.65 11.695 6.65v13.31z", fill: "#002D74" }),
        React.createElement("path", { d: "M20.464 39.483l16.619-9.451V11.124L20.464 1.666v5.61l11.695 6.65v13.31L20.464 33.88v5.604z", fill: "url(#cronos_svg__paint0_linear)" }),
        React.createElement("path", { d: "M20.454 1.667l-16.618 9.45v18.909l16.618 9.457v-5.61L8.76 27.224V13.913L20.454 7.27V1.667z", fill: "url(#cronos_svg__paint1_linear)" }),
        React.createElement("path", { d: "M28.219 24.992l-7.762 4.412-7.769-4.412v-8.83l7.769-4.417 7.762 4.418L24.988 18l-4.531-2.58-4.531 2.58v5.147l4.53 2.58 4.532-2.58 3.23 1.844z", fill: "#002D74" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "cronos_svg__paint0_linear", x1: "28.773", y1: "39.483", x2: "28.773", y2: "20.575", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#002D74" }),
                React.createElement("stop", { offset: "1", "stop-color": "#002D74", "stop-opacity": "0" })),
            React.createElement("linearGradient", { id: "cronos_svg__paint1_linear", x1: "12.145", y1: "1.667", x2: "12.145", y2: "20.575", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { "stop-color": "#002D74" }),
                React.createElement("stop", { offset: "1", "stop-color": "#002D74", "stop-opacity": "0" })))));
};
export default Cronos;
