var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import '../app/open-sans-font.css';
import '../app/web3modal-overrides.css';
import { isSwapEmbeddedUrl } from '../app/utils';
var isSwapEmbedded = isSwapEmbeddedUrl();
var labelColor = '#1E293B';
var textHighlightColor = '#D04FCD';
var lightBackground = '#f0e7ff';
var fontFamily = [
    '"Open Sans"',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(',');
var commonProps = {
    typography: {
        fontFamily: fontFamily
    },
};
var commonOverrides = {
    MuiDialog: {
        paper: {
            borderRadius: 16,
            margin: 8,
        }
    },
    MuiDialogTitle: {
        root: {
            color: 'white',
            backgroundColor: '#732973',
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
            '& h2': {
                color: 'white'
            }
        }
    },
    MuiDialogContent: {
        root: {
            padding: 16,
        }
    },
    MuiDialogActions: {
        root: {
            padding: 16,
        }
    },
    MuiTabs: {
        indicator: {
            backgroundColor: textHighlightColor
        }
    },
    MuiTab: {
        textColorInherit: {
            '&$selected': {
                color: textHighlightColor,
                fontWeight: 'bold'
            }
        }
    },
    MuiLink: {
        root: {
            color: textHighlightColor,
            textDecoration: 'underline',
            display: 'inline-block',
            cursor: 'pointer',
        },
    },
};
export var customTableStyles = function (theme) { return ({
    customTableRow: {
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        overflowX: 'auto',
        fontSize: 12,
        '&:nth-child(even)': {
            backgroundColor: (theme.palette.type == 'light') ? theme.palette.background.default : '#2F3A4C',
        }
    },
    customTableRowHeader: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        overflowX: 'auto',
        fontSize: 12,
        fontWeight: 'bold',
    },
}); };
var themes = {
    light: responsiveFontSizes(createTheme(__assign(__assign({}, commonProps), { overrides: __assign(__assign({}, commonOverrides), { MuiAppBar: {
                colorPrimary: {
                    backgroundColor: '#583592'
                }
            }, MuiToolbar: {
                root: {
                    backgroundColor: lightBackground
                }
            }, MuiTypography: {
                h4: {
                    color: textHighlightColor
                },
                h6: {
                    color: labelColor
                }
            }, MuiTableSortLabel: {
                root: {
                    color: labelColor
                },
                icon: {
                    width: 20,
                    height: 20,
                    fontSize: '1.2rem',
                }
            }, MuiInputLabel: {
                root: {
                    color: labelColor
                }
            }, MuiTableCell: {
                root: {
                    borderBottomColor: lightBackground
                },
                sizeSmall: {
                    paddingRight: 2,
                    paddingLeft: 2,
                }
            } }), palette: {
            type: 'light',
            primary: {
                main: '#c74bc4',
                dark: '#c74bc4'
            },
            secondary: {
                main: '#ffffff',
                dark: '#ffffff'
            },
            error: {
                main: red.A400,
            },
            background: {
                default: (isSwapEmbedded) ? "hsla(0, 0%, 0%, 0)" : "#F0F2F5",
            },
        } }))),
    dark: responsiveFontSizes(createTheme(__assign(__assign({}, commonProps), { overrides: __assign(__assign({}, commonOverrides), { MuiAppBar: {
                colorPrimary: {
                    backgroundColor: '#0b0b0b'
                }
            }, MuiTableSortLabel: {
                icon: {
                    width: 20,
                    height: 20,
                    fontSize: '1.2rem',
                }
            }, MuiTableCell: {
                sizeSmall: {
                    paddingRight: 2,
                    paddingLeft: 2,
                }
            }, MuiPaper: {
                root: {
                    backgroundColor: '#1e293b'
                }
            } }), palette: {
            type: 'dark',
            primary: {
                main: '#c400c4',
                dark: '#c400c4'
            },
            secondary: {
                main: '#1e293b',
                dark: '#1e293b'
            },
            error: {
                main: red.A400,
            },
            background: {
                default: "#121217"
            },
        } })))
};
export default themes;
