import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ChainConfigs } from './sharedTypes';
import { getChainIcon, getChainIconOutlined } from '../icons/chains/utils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        iconContainer: {
            width: theme.spacing(6),
            height: theme.spacing(6),
            padding: theme.spacing(1),
        },
        textContainer: {
            lineHeight: theme.spacing(2),
        },
        disabled: {
            color: theme.palette.text.disabled,
        },
        outline: {
            color: theme.palette.text.disabled,
            '&:hover': {
                color: theme.palette.text.primary,
                cursor: 'pointer',
            }
        },
        text: {
            display: 'flex',
        },
        icon: {
            width: '100%',
            height: '100%',
        },
    });
});
var ChainIcon = function (props) {
    var classes = useStyles();
    var className = [classes.container];
    if (props.className) {
        className.push(props.className);
    }
    if (!props.selected && !props.disabled) {
        className.push(classes.outline);
    }
    if (props.disabled) {
        className.push(classes.disabled);
    }
    var ChainIcon = (props.selected && !props.disabled) ? getChainIcon(props.chainId) : getChainIconOutlined(props.chainId);
    var handleClick = function () {
        if (props.onClick && !props.disabled) {
            props.onClick(props.chainId);
        }
    };
    return (React.createElement("div", { className: className.join(' '), onClick: handleClick },
        React.createElement("div", { className: classes.iconContainer },
            React.createElement(ChainIcon, { className: classes.icon })),
        React.createElement("div", { className: classes.textContainer },
            React.createElement(Typography, { variant: "caption", className: classes.text }, (ChainConfigs[props.chainId] || {}).shortName))));
};
export default ChainIcon;
