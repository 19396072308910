var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import i18n from 'i18next';
import Web3 from 'web3';
import { ChainConfigs } from './sharedTypes';
import { isSameAddress, shortenAddress } from './utils';
import erc20Abi from '../swap/ABI/erc-20-abi.json';
import { ZERO_ADDR } from './constants';
export var isProviderInChain = function (connection, chainId, tryToAddToMetaMask) {
    if (tryToAddToMetaMask === void 0) { tryToAddToMetaMask = true; }
    return __awaiter(void 0, void 0, void 0, function () {
        var provider, chainConfig, requiredChainId, chainHex, currentChainId, switchError_1, error_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    provider = (_a = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _a === void 0 ? void 0 : _a.provider;
                    if (!provider || !chainId) {
                        return [2 /*return*/, false];
                    }
                    chainConfig = ChainConfigs[chainId];
                    requiredChainId = chainConfig.chainId;
                    chainHex = "0x".concat(requiredChainId.toString(16));
                    currentChainId = connection.chainId || provider.chainId;
                    if (currentChainId == requiredChainId) {
                        return [2 /*return*/, true];
                    }
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 10]);
                    return [4 /*yield*/, provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: chainHex }],
                        })];
                case 2:
                    _d.sent();
                    return [2 /*return*/, true];
                case 3:
                    switchError_1 = _d.sent();
                    if (!(tryToAddToMetaMask && (switchError_1.code === 4902 || ((_c = (_b = switchError_1.data) === null || _b === void 0 ? void 0 : _b.originalError) === null || _c === void 0 ? void 0 : _c.code) == 4902))) return [3 /*break*/, 8];
                    _d.label = 4;
                case 4:
                    _d.trys.push([4, 6, , 7]);
                    return [4 /*yield*/, provider.request({
                            method: "wallet_addEthereumChain",
                            params: [{
                                    chainId: chainHex,
                                    chainName: chainConfig.displayName,
                                    nativeCurrency: chainConfig.nativeCurrency,
                                    rpcUrls: [chainConfig.rpc],
                                    blockExplorerUrls: [chainConfig.explorerUrl]
                                }],
                        })];
                case 5:
                    _d.sent();
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _d.sent();
                    console.error(error_1);
                    return [3 /*break*/, 7];
                case 7: return [3 /*break*/, 9];
                case 8:
                    console.error(switchError_1);
                    _d.label = 9;
                case 9: return [3 /*break*/, 10];
                case 10: return [2 /*return*/, false];
            }
        });
    });
};
/**
 * This helper function checks that the connected wallet is matching the given wallet address, since WalletNow supports
 * multiple wallet addresses, we want to make sure that the wallet being operated is the same that is connected.
 * It will inform the user about the different situations and guide it to correct the problems.
 *
 * @param connection Wallet connection context
 * @param dialog muibox dialog hook instance to display user messages
 * @param chainId Chain id
 * @param walletAddress Wallet address to check
 * @returns Promise
 */
export var checkWalletConnected = function (connection, dialog, chainId, walletAddress) { return __awaiter(void 0, void 0, void 0, function () {
    var handleLogin, provider_1, connectedAddress, currentAddress, chainConfig_1, chainNetworkId, chainHex, currentChainId, showWrongNetwork, switchError_2, error_2;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                handleLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!connection.connect) return [3 /*break*/, 2];
                                return [4 /*yield*/, connection.connect()];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); };
                if (!((_a = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _a === void 0 ? void 0 : _a.provider)) return [3 /*break*/, 13];
                provider_1 = (_b = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _b === void 0 ? void 0 : _b.provider;
                if (connection.address && !isSameAddress(connection.address, walletAddress)) {
                    connectedAddress = shortenAddress(connection.address);
                    currentAddress = shortenAddress(walletAddress);
                    dialog.confirm({
                        title: i18n.t('WARNING', 'Warning'),
                        message: i18n.t('DIFFERENT_ADDRESS_FOR_WALLET_OPERATION', 'Your connected wallet address {{connectedAddress}} does not match the account address you are trying to operate {{currentAddress}}. Please connect again using the correct wallet.', { connectedAddress: connectedAddress, currentAddress: currentAddress }),
                        ok: {
                            text: i18n.t('RECONNECT', 'Reconnect'),
                            variant: 'contained',
                            color: 'primary'
                        },
                        cancel: {
                            text: i18n.t('CANCEL', 'Cancel'),
                            variant: 'outlined',
                            color: 'default'
                        }
                    })
                        .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                        var error_3;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 6]);
                                    return [4 /*yield*/, provider_1.request({
                                            method: 'wallet_requestPermissions',
                                            params: [{ eth_accounts: {} }]
                                        })];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 6];
                                case 2:
                                    error_3 = _a.sent();
                                    if (!connection.disconnect) return [3 /*break*/, 4];
                                    return [4 /*yield*/, connection.disconnect()];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4: return [4 /*yield*/, handleLogin()];
                                case 5:
                                    _a.sent();
                                    return [3 /*break*/, 6];
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); });
                    throw new Error('Different wallet address');
                }
                chainConfig_1 = ChainConfigs[chainId];
                chainNetworkId = chainConfig_1.chainId;
                chainHex = "0x".concat(chainNetworkId.toString(16));
                currentChainId = connection.chainId || provider_1.chainId;
                if (!(currentChainId != chainNetworkId)) return [3 /*break*/, 12];
                showWrongNetwork = function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dialog.alert({
                                    title: i18n.t('WRONG_NETWORK', 'Wrong network'),
                                    message: i18n.t('WRONG_NETWORK_MESSAGE', 'Please select the network {{network}} on your wallet application to continue.', { network: chainConfig_1.displayName }),
                                    ok: {
                                        text: i18n.t('OK', 'Ok'),
                                        variant: 'contained',
                                        color: 'primary'
                                    },
                                })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); };
                _e.label = 1;
            case 1:
                _e.trys.push([1, 3, , 12]);
                return [4 /*yield*/, provider_1.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: chainHex }],
                    })];
            case 2:
                _e.sent();
                return [3 /*break*/, 12];
            case 3:
                switchError_2 = _e.sent();
                if (!(switchError_2.code === 4902 || ((_d = (_c = switchError_2.data) === null || _c === void 0 ? void 0 : _c.originalError) === null || _d === void 0 ? void 0 : _d.code) == 4902)) return [3 /*break*/, 9];
                _e.label = 4;
            case 4:
                _e.trys.push([4, 6, , 8]);
                return [4 /*yield*/, provider_1.request({
                        method: "wallet_addEthereumChain",
                        params: [{
                                chainId: chainHex,
                                chainName: chainConfig_1.displayName,
                                nativeCurrency: chainConfig_1.nativeCurrency,
                                rpcUrls: [chainConfig_1.rpc],
                                blockExplorerUrls: [chainConfig_1.explorerUrl]
                            }],
                    })];
            case 5:
                _e.sent();
                return [3 /*break*/, 8];
            case 6:
                error_2 = _e.sent();
                console.error(error_2);
                return [4 /*yield*/, showWrongNetwork()];
            case 7:
                _e.sent();
                throw new Error('Wrong wallet network');
            case 8: return [3 /*break*/, 11];
            case 9:
                console.error(switchError_2);
                return [4 /*yield*/, showWrongNetwork()];
            case 10:
                _e.sent();
                throw new Error('Wrong wallet network');
            case 11: return [3 /*break*/, 12];
            case 12: return [2 /*return*/, connection.address];
            case 13:
                dialog.confirm({
                    title: i18n.t('WALLET_NOT_CONNECTED', 'Wallet not connected'),
                    message: i18n.t('CONNECT_WALLET_TO_OPERATE', 'Please connect your wallet in order to operate'),
                    ok: {
                        text: i18n.t('CONNECT', 'Connect'),
                        variant: 'contained',
                        color: 'primary'
                    },
                    cancel: {
                        text: i18n.t('CANCEL', 'Cancel'),
                        variant: 'outlined',
                        color: 'default'
                    }
                })
                    .then(function () {
                    handleLogin();
                });
                throw new Error('Wallet not connected');
        }
    });
}); };
export var getSwapTokenInfo = function (chainId, address) { return __awaiter(void 0, void 0, void 0, function () {
    var chainConfig, web3, contract, symbol, name_1, decimals, tokenInfo, tokenInfoError_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                chainConfig = ChainConfigs[chainId];
                web3 = new Web3(chainConfig.rpc);
                contract = new web3.eth.Contract(erc20Abi, address);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                return [4 /*yield*/, contract.methods.symbol().call()];
            case 2:
                symbol = _a.sent();
                return [4 /*yield*/, contract.methods.name().call()];
            case 3:
                name_1 = _a.sent();
                return [4 /*yield*/, contract.methods.decimals().call()];
            case 4:
                decimals = _a.sent();
                tokenInfo = {
                    chainId: chainId,
                    token: address,
                    symbol: symbol,
                    name: name_1,
                    decimals: decimals
                };
                return [2 /*return*/, tokenInfo];
            case 5:
                tokenInfoError_1 = _a.sent();
                // XXX: There is no reliable way to know if given address is a
                // valid ERC20 contract, for now we check the error message to
                // differentiate from actual network problems, which usually returns
                // "Invalid JSON RPC response"
                if ("".concat(tokenInfoError_1).includes("Returned values aren't valid")) {
                    return [2 /*return*/, {
                            chainId: chainId,
                            token: ZERO_ADDR,
                            symbol: '',
                            name: '',
                            decimals: 0
                        }];
                }
                return [3 /*break*/, 6];
            case 6: throw new Error('Error retrieving token info');
        }
    });
}); };
