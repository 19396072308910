var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var HECOOutline = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "4 3 12 13", key: "heco_outline" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.208 4c.311 0 .564.253.564.565v1.568C6.602 6.6 5.59 7.581 5 8.682V4h2.208zm1.27 7.13c0-.708.574-1.283 1.283-1.283h.542a1.95 1.95 0 001.951-1.95V4h2.208c.312 0 .565.253.565.565v3.331a4.723 4.723 0 01-4.724 4.723H8.478V11.13zm3.083-3.256c0 .708-.574 1.283-1.283 1.283h-.542a1.95 1.95 0 00-1.951 1.95v3.897H5.577a.565.565 0 01-.565-.565v-3.331a4.723 4.723 0 014.724-4.723h1.825v1.489zm1.27 7.13a.565.565 0 01-.564-.565v-1.568c1.17-.466 2.183-1.448 2.772-2.549v4.682h-2.208z", fill: "currentColor" })));
};
export default HECOOutline;
