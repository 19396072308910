import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            display: 'flex',
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        succeeded: {
            color: '#66bb6a',
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
        failed: {
            color: '#f44336',
            width: theme.spacing(2),
            height: theme.spacing(2),
        },
    });
});
export var DexQuoteProgressStates;
(function (DexQuoteProgressStates) {
    DexQuoteProgressStates["CREATING_PAIR_FACTORY"] = "CREATING_PAIR_FACTORY";
    DexQuoteProgressStates["PAIR_FACTORY_CREATED"] = "PAIR_FACTORY_CREATED";
    DexQuoteProgressStates["RETRIEVING_AMOUNT"] = "RETRIEVING_AMOUNT";
    DexQuoteProgressStates["SUCCEEDED"] = "SUCCEEDED";
    DexQuoteProgressStates["FAILED"] = "FAILED";
})(DexQuoteProgressStates || (DexQuoteProgressStates = {}));
var DexQuoteProgress = function (props) {
    var classes = useStyles();
    var state = props.state;
    return (React.createElement("div", { className: (props.className) ? "".concat(props.className, " ").concat(classes.container) : classes.container },
        (state != DexQuoteProgressStates.SUCCEEDED) && (state != DexQuoteProgressStates.FAILED) && React.createElement(CircularProgress, { size: 16 }),
        (state == DexQuoteProgressStates.SUCCEEDED) && React.createElement(DoneIcon, { className: classes.succeeded }),
        (state == DexQuoteProgressStates.FAILED) && React.createElement(CloseIcon, { className: classes.failed })));
};
export default DexQuoteProgress;
