var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var HECO = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 32 32", key: "heco" }),
        React.createElement("path", { d: "M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z", fill: "#fff" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zM12.489 8.293a.88.88 0 00-.88-.88h-3.44v7.295c.918-1.715 2.496-3.245 4.32-3.97V8.292zm3.099 8.231a2 2 0 00-2 2v2.32h2.845a7.36 7.36 0 007.36-7.36V8.293a.88.88 0 00-.88-.88h-3.44v6.071a3.04 3.04 0 01-3.04 3.04h-.845zm.805-1.075a2 2 0 002-2v-2.32h-2.845a7.36 7.36 0 00-7.36 7.36v5.191c0 .486.394.88.88.88h3.44V18.49a3.04 3.04 0 013.04-3.04h.845zm3.1 8.231c0 .486.393.88.88.88h3.44v-7.295c-.919 1.715-2.498 3.245-4.32 3.97v2.445z", fill: "#00943E" })));
};
export default HECO;
