var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var CronosOutline = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 24 24", key: "cronos_outline" }),
        React.createElement("path", { d: "M11.973 0L2 5.765v11.538l9.985 5.761 9.965-5.76V5.764L11.973 0zm7.029 15.584l-7.029 4.038-7.025-4.038V7.469l7.025-4.053 7.029 4.053v8.115z", fill: "currentColor" }),
        React.createElement("path", { d: "M16.628 14.213l-4.66 2.704-4.663-2.705V8.838l4.664-2.705 4.66 2.705-1.933 1.12-2.704-1.572-2.705 1.572v3.138l2.705 1.572 2.704-1.572 1.932 1.117z", fill: "currentColor" })));
};
export default CronosOutline;
