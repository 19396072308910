import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { ChainConfigs } from './sharedTypes';
import ChainIcon from './ChainIcon';
import Hidden from '@material-ui/core/Hidden';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getChainIcon } from '../icons/chains/utils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(0.25),
        },
        flex: {
            "& div": {
                display: 'flex',
                alignItems: 'center',
            }
        },
        icon: {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        }
    });
});
var ChainSelector = function (props) {
    var classes = useStyles();
    var handleClickChain = function (chainId) {
        if (props.onClickChain) {
            props.onClickChain(chainId);
        }
    };
    var handleChangeChain = function (event) {
        if (props.onClickChain) {
            props.onClickChain(event.target.value);
        }
    };
    return (React.createElement(Box, { className: classes.container },
        React.createElement(Hidden, { xsDown: true }, props.chainIds.map(function (chainId) {
            return (React.createElement(ChainIcon, { key: chainId, chainId: chainId, selected: chainId == props.chainId, onClick: handleClickChain, disabled: props.disabled }));
        })),
        React.createElement(Hidden, { smUp: true },
            React.createElement(Select, { id: "chain-select", value: props.chainId, onChange: handleChangeChain, disabled: props.disabled, className: classes.flex }, props.chainIds.map(function (chainId) {
                var ChainIcon = getChainIcon(chainId);
                return (React.createElement(MenuItem, { key: chainId, value: chainId },
                    React.createElement(ChainIcon, { className: classes.icon }),
                    " ",
                    (ChainConfigs[chainId] || {}).shortName));
            })))));
};
export default ChainSelector;
