var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { useDialog } from 'muibox';
import { useHistory, useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import RepeatIcon from '@material-ui/icons/Repeat';
import GetAppIcon from '@material-ui/icons/GetApp';
import CandlestickChartIcon from '../icons/CandlestickChart';
import Medal from '../icons/Medal';
import { setShowPlan } from '../store/actions';
import { PLAN_COLORS } from './constants';
import { accountFromCurrentPath } from './routes';
import GlobalActions from './GlobalActions';
import { downloadCsv } from './utils';
import { Link } from '@material-ui/core';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        list: {
            minWidth: 250,
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
        fullList: {
            width: 'auto',
        },
        menuTitle: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#732973',
            color: 'white',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        text: {
            fontWeight: 'bold'
        },
        grow: {
            flex: '1 1 auto',
        },
    });
});
;
var Drawer = function (props) {
    var classes = useStyles();
    var history = useHistory();
    var location = useLocation();
    var t = useTranslation().t;
    var dialog = useDialog();
    var membershipInfo = props.accountData.membershipInfo;
    var activeMembershipPlan = (membershipInfo || {}).activeMembershipPlan || 0;
    var underSettings = location.pathname.endsWith('/settings/') || location.pathname.endsWith('/settings');
    var underAccount = location.pathname.startsWith('/account/');
    var underAccountNotSettings = underAccount && !underSettings;
    var handleRefreshPage = function () {
        var account = accountFromCurrentPath();
        history.replace("/account/".concat(account), {
            refreshTimestamp: (new Date()).getTime()
        });
    };
    var handleAccountClick = function () {
        var account = accountFromCurrentPath();
        if (account) {
            history.push("/account/".concat(account, "/settings"));
        }
    };
    var handleSwap = function () {
        history.push('/swap');
    };
    var handleChart = function () {
        history.push('/chart');
    };
    var handlePlanClick = function () {
        props.setShowPlan(true);
    };
    var handleDownloadCsv = function () {
        if (!downloadCsv()) {
            dialog.alert({
                title: t('WARNING', 'Warning'),
                message: React.createElement(Trans, { i18nKey: "THIS_FEATURE_IS_AVAILABLE_ON_DIAMOND_UPGRADE_NOW" },
                    "This feature is only available on our ",
                    React.createElement("b", null, "Diamond tier"),
                    ". ",
                    React.createElement(Link, { href: "https://docs.walletnow.net/pricing", target: "_blank", rel: "noopener" }, "Upgrade your plan now!")),
                ok: {
                    text: t('OK', 'Ok'),
                    variant: 'contained',
                    color: 'primary'
                },
            });
        }
    };
    var toggleDrawer = function (open) { return function (event) {
        if (event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' ||
                event.key === 'Shift')) {
            return;
        }
        props.onToggle(open);
    }; };
    var sideList = function () { return (React.createElement("div", { className: classes.list, role: "presentation", onClick: toggleDrawer(false), onKeyDown: toggleDrawer(false) },
        React.createElement(Divider, null),
        React.createElement(List, null,
            React.createElement(ListItem, { button: true, onClick: handleSwap },
                React.createElement(ListItemIcon, null,
                    React.createElement(RepeatIcon, null)),
                React.createElement(ListItemText, { primary: t('SWAP', 'Swap') }))),
        React.createElement(List, null,
            React.createElement(ListItem, { button: true, onClick: handleChart },
                React.createElement(ListItemIcon, null,
                    React.createElement(CandlestickChartIcon, null)),
                React.createElement(ListItemText, { primary: t('CHART', 'Chart') }))),
        underAccountNotSettings && (React.createElement(React.Fragment, null,
            React.createElement(Divider, null),
            React.createElement(List, null,
                React.createElement(ListItem, { button: true, onClick: handleRefreshPage },
                    React.createElement(ListItemIcon, null,
                        React.createElement(RefreshIcon, null)),
                    React.createElement(ListItemText, { primary: t('REFRESH', 'Refresh') }))))),
        underAccount && (React.createElement(React.Fragment, null,
            React.createElement(Divider, null),
            React.createElement(List, null,
                React.createElement(ListItem, { button: true, onClick: handleAccountClick },
                    React.createElement(ListItemIcon, null,
                        React.createElement(AccountCircleIcon, null)),
                    React.createElement(ListItemText, { primary: t('ACCOUNT', 'Account') }),
                    React.createElement(ChevronRightIcon, null))))),
        membershipInfo && (React.createElement(React.Fragment, null,
            React.createElement(Divider, null),
            React.createElement(List, null,
                React.createElement(ListItem, { button: true, onClick: handlePlanClick },
                    React.createElement(ListItemIcon, null,
                        React.createElement(Medal, __assign({}, PLAN_COLORS[activeMembershipPlan]))),
                    React.createElement(ListItemText, { primary: t('PLAN', 'Plan') }),
                    React.createElement(ChevronRightIcon, null))))),
        underAccountNotSettings && (React.createElement(React.Fragment, null,
            React.createElement(Divider, null),
            React.createElement(List, null,
                React.createElement(ListItem, { button: true, onClick: handleDownloadCsv },
                    React.createElement(ListItemIcon, null,
                        React.createElement(GetAppIcon, null)),
                    React.createElement(ListItemText, { primary: t('EXPORT_TO_CSV', 'Export data to CSV (Excel)') }))))),
        React.createElement(Divider, null))); };
    return (React.createElement("div", null,
        React.createElement(SwipeableDrawer, { anchor: "right", open: props.open, onClose: toggleDrawer(false), onOpen: toggleDrawer(true) },
            React.createElement(Box, { className: classes.menuTitle },
                React.createElement(IconButton, { color: "inherit", "aria-label": t('CLOSE_MENU', 'Close Menu'), onClick: toggleDrawer(false) },
                    React.createElement(MenuIcon, null)),
                React.createElement("div", { className: classes.grow },
                    React.createElement(Typography, { variant: "h5", className: classes.text }, t('MENU', 'Menu'))),
                React.createElement(IconButton, { color: "inherit", "aria-label": t('CLOSE_MENU', 'Close Menu'), onClick: toggleDrawer(false) },
                    React.createElement(CloseIcon, null))),
            React.createElement(GlobalActions, { hideAccount: true, hideSwap: true, hideChart: true }),
            sideList())));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
var mapDispatchToProps = function (dispatch) {
    return bindActionCreators({ setShowPlan: setShowPlan }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
