var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSnackbar } from 'notistack';
var useSnackbarRef;
export var SnackbarUtilsConfigurator = function () {
    useSnackbarRef = useSnackbar();
    return null;
};
export default {
    success: function (msg, options) {
        if (options === void 0) { options = {}; }
        this.toast(msg, __assign(__assign({}, options), { variant: 'success' }));
    },
    warning: function (msg, options) {
        if (options === void 0) { options = {}; }
        this.toast(msg, __assign(__assign({}, options), { variant: 'warning' }));
    },
    info: function (msg, options) {
        if (options === void 0) { options = {}; }
        this.toast(msg, __assign(__assign({}, options), { variant: 'info' }));
    },
    error: function (msg, options) {
        if (options === void 0) { options = {}; }
        this.toast(msg, __assign(__assign({}, options), { variant: 'error' }));
    },
    toast: function (msg, options) {
        if (options === void 0) { options = {}; }
        useSnackbarRef.enqueueSnackbar(msg, options);
    }
};
