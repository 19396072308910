import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ReactImageFallback from 'react-image-fallback';
import { getTokenIconUrl } from './utils';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        tokenIcon: {
            borderRadius: '20%',
            marginRight: theme.spacing(1),
            width: theme.spacing(3.5),
            height: theme.spacing(3.5),
        },
        tokenIconSkeleton: {
            marginRight: theme.spacing(1),
            width: theme.spacing(3.5),
            height: theme.spacing(3.5),
            display: 'flex',
        },
        tokenIconEmpty: {
            marginRight: theme.spacing(1),
            width: theme.spacing(3.5),
            height: theme.spacing(3.5),
        },
        tokenDescription: {
            display: 'flex',
            flexDirection: 'column',
        },
        tokenSymbol: {
            lineHeight: 'initial',
        },
        tokenName: {
            display: 'flex',
            color: theme.palette.text.disabled,
        },
    });
});
var TokenImage = function (props) {
    var classes = useStyles();
    var token = props.token;
    return (React.createElement("div", { className: (props.className) ? "".concat(props.className, " ").concat(classes.container) : classes.container },
        React.createElement(ReactImageFallback, { className: classes.tokenIcon, src: getTokenIconUrl(props.chainId, token.token), fallbackImage: React.createElement("div", { className: classes.tokenIconEmpty }), initialImage: React.createElement("div", { className: classes.tokenIconSkeleton },
                React.createElement(Skeleton, { variant: "circle", width: 24, height: 24 })), alt: "" }),
        React.createElement("div", { className: classes.tokenDescription },
            props.showSymbol && (React.createElement("div", null,
                React.createElement(Typography, { variant: "subtitle2", className: classes.tokenSymbol }, token.symbol))),
            props.showName && (React.createElement("div", null,
                React.createElement(Typography, { variant: "caption", className: classes.tokenName }, token.name))))));
};
export default TokenImage;
