var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Ethereum = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 1920 1920", key: "ethereum" }),
        React.createElement("g", null,
            React.createElement("polygon", { fill: "#8A92B2", points: "959.8,80.7 420.1,976.3 959.8,731" }),
            React.createElement("polygon", { fill: "#62688F", points: "959.8,731 420.1,976.3 959.8,1295.4" }),
            React.createElement("polygon", { fill: "#62688F", points: "1499.6,976.3 959.8,80.7 959.8,731" }),
            React.createElement("polygon", { fill: "#454A75", points: "959.8,1295.4 1499.6,976.3 959.8,731" }),
            React.createElement("polygon", { fill: "#8A92B2", points: "420.1,1078.7 959.8,1839.3 959.8,1397.6" }),
            React.createElement("polygon", { fill: "#62688F", points: "959.8,1397.6 959.8,1839.3 1499.9,1078.7" }))));
};
export default Ethereum;
