var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarProvider } from 'notistack';
import { DialogProvider } from 'muibox';
import themes from '../theme/themes';
import Header from './Header';
import { getRoutes } from './routes';
import SnackbarCloseButton from './SnackbarCloseButton';
import Footer from './Footer';
import PlanDialog from './PlanDialog';
import { SnackbarUtilsConfigurator } from './SnackbarUtils';
import ErrorBoundary from './ErrorBoundary';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        snackContainer: {
            left: theme.spacing(2),
            right: theme.spacing(2),
        },
        progress: {
            position: 'absolute',
            left: 0,
            right: 0,
            zIndex: 10000,
        }
    });
});
var App = function (props) {
    var classes = useStyles();
    var routes = getRoutes();
    var mode = props.settings.mode;
    var loading = props.busy > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(MuiThemeProvider, { theme: themes[mode] },
            React.createElement(DialogProvider, null,
                React.createElement(SnackbarProvider, { preventDuplicate: true, action: function (key) { return React.createElement(SnackbarCloseButton, { id: key }); }, classes: { containerAnchorOriginBottomLeft: classes.snackContainer } },
                    React.createElement(PlanDialog, null),
                    React.createElement(SnackbarUtilsConfigurator, null),
                    React.createElement(BrowserRouter, null,
                        React.createElement(CssBaseline, null),
                        React.createElement(Header, null),
                        loading && React.createElement(LinearProgress, { className: classes.progress, color: "primary" }),
                        React.createElement(ErrorBoundary, null,
                            React.createElement(Suspense, { fallback: React.createElement(Box, { p: 3, width: "100%", textAlign: "center" },
                                    React.createElement(CircularProgress, { size: 24 })) }, renderRoutes(routes))),
                        React.createElement(Footer, null)))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(App);
