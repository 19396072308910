var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { FixedNumber } from 'ethers';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FiatSelector from './FiatSelector';
import FiatImage from './FiatImage';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        labelContainer: {
            top: theme.spacing(2),
            position: 'absolute',
            display: 'flex',
            right: theme.spacing(1.75),
            left: theme.spacing(1.75),
            fontSize: '75%',
        },
        hiddenInput: {
            width: 0,
            paddingRight: theme.spacing(1),
        },
        fullWidth: {
            width: '100%',
        },
        spacer: {
            flexGrow: 1,
        },
        inputRoot: {
            paddingTop: theme.spacing(4),
        },
        input: {},
        inputLabel: {
            width: '100%',
        },
        fiatSelectButton: {
            minWidth: theme.spacing(15),
        },
        fiatSymbol: {
            marginLeft: theme.spacing(1),
        },
    });
});
var FiatInput = function (props) {
    var classes = useStyles({});
    var t = useTranslation().t;
    var amountFN = useRef(FixedNumber.fromString('0'));
    var _a = useState(false), openFiatSelector = _a[0], setOpenFiatSelector = _a[1];
    var _b = useState(false), inputError = _b[0], setInputError = _b[1];
    useEffect(function () {
        amountFN.current = FixedNumber.fromString(props.amount || '0');
        updateInputError();
    }, [props.amount]);
    var updateInputError = function () {
        var hasError = amountFN.current.isNegative();
        // TODO: check minimum and maximum
        if (inputError != hasError) {
            setInputError(hasError);
            if (props.onChangeError) {
                props.onChangeError(hasError);
            }
        }
    };
    var handleChangeInputAmount = function (event) {
        var amount = event.target.value;
        amountFN.current = FixedNumber.fromString(amount || '0');
        props.onChangeAmount(amount, amountFN.current);
    };
    var handleSelectFiat = function (fiat) {
        props.onChangeFiat(fiat);
    };
    var handleOpenFiatSelector = function () {
        setOpenFiatSelector(true);
    };
    var handleCloseFiatSelector = function () {
        setOpenFiatSelector(false);
    };
    var inputErrorText = (inputError) ? t('INVALID_AMOUNT', 'Invalid amount') : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(FiatSelector, { fiats: props.fiats, open: openFiatSelector, onSelectFiat: handleSelectFiat, onCloseDialog: handleCloseFiatSelector }),
        React.createElement(TextField, { id: "value", value: props.amount, onChange: handleChangeInputAmount, variant: "outlined", fullWidth: true, inputMode: "decimal", placeholder: "0", error: inputError, helperText: inputErrorText, disabled: Boolean(props.disabled), InputLabelProps: {
                classes: {
                    root: classes.inputLabel,
                },
                shrink: true,
            }, InputProps: {
                autoFocus: props.autoFocus,
                disabled: Boolean(props.hiddenInput) || Boolean(props.disabled),
                classes: {
                    root: (props.label) ? classes.inputRoot : '',
                    input: (props.hiddenInput) ? "".concat(classes.input, " ").concat(classes.hiddenInput) : classes.input,
                },
                startAdornment: (React.createElement(InputAdornment, { position: "start", className: (props.hiddenInput) ? classes.fullWidth : '' },
                    React.createElement("div", { className: classes.labelContainer },
                        React.createElement("div", null, props.label),
                        React.createElement("div", { className: classes.spacer })),
                    React.createElement(Button, { color: "primary", size: "small", variant: "text", onClick: handleOpenFiatSelector, className: classes.fiatSelectButton, fullWidth: props.hiddenInput, disabled: Boolean(props.disabled) },
                        props.fiat &&
                            React.createElement(FiatImage, { showCode: true, fiat: props.fiat }),
                        !props.fiat && t('SELECT_FIAT', 'Select fiat'),
                        React.createElement("div", { className: classes.spacer }),
                        React.createElement(ArrowDropDownIcon, null)),
                    props.fiat && React.createElement("div", { className: classes.fiatSymbol }, props.fiat.symbol)))
            } })));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(FiatInput);
