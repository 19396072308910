var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Alert from '@material-ui/lab/Alert';
import Logo from '../icons/Logo';
import Drawer from './Drawer';
import Ad from './Ad';
import GlobalActions from './GlobalActions';
import './varela-round-font.css';
import { IS_BETA } from './constants';
var useStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        '@global': {
            '#main-content': {
                outline: 0,
            },
        },
        appBar: (_a = {
                boxShadow: 'none'
            },
            _a[theme.breakpoints.down('xs')] = {
                '& .MuiToolbar-gutters': {
                    paddingLeft: 0,
                    paddingRight: 0,
                }
            },
            _a),
        toolbar: {
            backgroundColor: 'inherit'
        },
        logo: {
            transform: 'scale(1.6)',
            verticalAlign: 'middle',
        },
        grow: {
            flex: '1 1 auto',
        },
        title: {
            margin: theme.spacing(0, 1),
            fontFamily: "'Varela Round', sans-serif",
        },
        hide: {
            display: 'none',
        },
        homeLink: {
            textDecoration: 'none',
            display: 'flex',
            color: 'inherit',
            '&:hover': {
                textDecoration: 'none',
            }
        }
    });
});
var Header = function (props) {
    var classes = useStyles();
    var t = useTranslation().t;
    var history = useHistory();
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var handleToggleDrawer = function (open) {
        setOpen(open);
    };
    var handleOpenDrawer = function () {
        setOpen(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Drawer, { open: open, onToggle: handleToggleDrawer }),
        (IS_BETA) && (React.createElement(React.Fragment, null,
            React.createElement(Ad, null),
            React.createElement(Alert, { severity: "error" },
                React.createElement("b", null, "WARNING!!!"),
                " THIS IS A TEST ENVIRONMENT. PLEASE USE ",
                React.createElement("a", { href: "https://walletnow.net/" }, "walletnow.net")))),
        React.createElement(AppBar, { id: "top", position: "static", className: classes.appBar },
            React.createElement(Toolbar, { className: classes.toolbar },
                React.createElement(IconButton, { color: "primary", "aria-label": t('HOME', 'Home') },
                    React.createElement(Link, { href: "/", className: classes.homeLink },
                        React.createElement(Logo, { className: classes.logo }))),
                React.createElement("div", null,
                    React.createElement(Typography, { className: classes.title, variant: "h5" },
                        React.createElement(Link, { href: "/", className: classes.homeLink }, "WalletNow"))),
                React.createElement("div", { className: classes.grow }),
                !isSmall && React.createElement(GlobalActions, null),
                isSmall && (React.createElement(IconButton, { color: "inherit", "aria-label": t('OPEN_DRAWER', 'Open drawer'), onClick: handleOpenDrawer, className: open ? classes.hide : '' },
                    React.createElement(MenuIcon, null)))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(Header);
