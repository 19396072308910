import { TradePath } from '../../enums/trade-path';
export function getTradePath(fromToken, toToken, nativeTokenAddress) {
    var checksumAddress = nativeTokenAddress.toLowerCase();
    if (fromToken.contractAddress.toLowerCase() === checksumAddress) {
        return TradePath.ethToErc20;
    }
    if (toToken.contractAddress.toLowerCase() === checksumAddress) {
        return TradePath.erc20ToEth;
    }
    return TradePath.erc20ToErc20;
}
