var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var MathWallet = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: 'http://www.w3.org/2000/svg', width: 128, height: 128, viewBox: "0 0 128 128" }, props, { key: "math-wallet" }),
        React.createElement("path", { d: "M90.847 57.761a5.83 5.83 0 118.246-8.246 5.83 5.83 0 01-8.246 8.246zM71.263 77.345a4.373 4.373 0 116.184-6.184 4.373 4.373 0 01-6.184 6.184zm30.922-10.307a4.373 4.373 0 116.184-6.185 4.373 4.373 0 01-6.184 6.185zM91.878 77.345a4.373 4.373 0 116.184-6.184 4.373 4.373 0 01-6.184 6.184zm21.645-1.03a2.915 2.915 0 114.123-4.124 2.915 2.915 0 01-4.123 4.123zm-10.307 10.307a2.915 2.915 0 114.123-4.123 2.915 2.915 0 01-4.123 4.123zM80.54 68.068a5.83 5.83 0 118.246-8.246 5.83 5.83 0 01-8.246 8.246zm0-20.614a5.83 5.83 0 118.246-8.246 5.83 5.83 0 01-8.246 8.246zM70.232 57.76a5.83 5.83 0 118.246-8.246 5.83 5.83 0 01-8.246 8.246zm-20.71 0a5.83 5.83 0 118.246-8.246 5.83 5.83 0 01-8.246 8.246zM29.938 77.345a4.373 4.373 0 116.184-6.184 4.373 4.373 0 01-6.184 6.184zM60.86 67.038a4.373 4.373 0 116.184-6.185 4.373 4.373 0 01-6.184 6.185zM50.553 77.345a4.373 4.373 0 116.184-6.184 4.373 4.373 0 01-6.184 6.184zm-40.2-1.03a2.915 2.915 0 114.124-4.124 2.915 2.915 0 01-4.123 4.123zm10.308 10.307a2.915 2.915 0 114.123-4.123 2.915 2.915 0 01-4.123 4.123zm41.23 0a2.915 2.915 0 114.123-4.123 2.915 2.915 0 01-4.123 4.123zM19.63 67.038a4.373 4.373 0 116.184-6.185 4.373 4.373 0 01-6.184 6.185zm19.583 1.03a5.83 5.83 0 118.246-8.246 5.83 5.83 0 01-8.246 8.246zm0-20.614a5.83 5.83 0 118.246-8.246 5.83 5.83 0 01-8.246 8.246zM28.907 57.76a5.83 5.83 0 118.246-8.246 5.83 5.83 0 01-8.246 8.246z", fill: "#000", fillRule: "evenodd" })));
};
export default MathWallet;
