var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ChainConfigs } from './sharedTypes';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { getTokenInfo } from './swapUtils';
import TokenImage from './TokenImage';
import { formatPercent, isSameAddress, shortenAddress } from './utils';
import { ConnectContext } from './ConnectionProvider';
import Web3 from 'web3';
import walletNowSwapAbi from '../swap/ABI/walletnow-swap.json';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import { ZERO_ADDR } from './constants';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        loadingCashbackProgress: {
            marginRight: theme.spacing(1)
        },
        tokenImage: {
            paddingLeft: theme.spacing(1)
        }
    });
});
var SwapCashBack = function (props) {
    var _a;
    var locale = props.settings.locale;
    var classes = useStyles({});
    var connection = useContext(ConnectContext);
    var _b = useState([]), rewardTokens = _b[0], setRewardTokens = _b[1];
    var _c = useState(0), loading = _c[0], setLoading = _c[1];
    var _d = useState(), rewardToken = _d[0], setRewardToken = _d[1];
    var _e = useState(), cashBackBPS = _e[0], setCashBackBPS = _e[1];
    var t = useTranslation().t;
    useEffect(function () {
        var _a, _b;
        var canProcess = true;
        var chainConfig = ChainConfigs[props.chainId];
        if (props.token && chainConfig.walletNowSwap && ((_a = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _a === void 0 ? void 0 : _a.provider)) {
            var provider = (_b = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _b === void 0 ? void 0 : _b.provider;
            var web3 = new Web3(provider);
            var contract = new web3.eth.Contract(walletNowSwapAbi, chainConfig.walletNowSwap);
            var methods = contract.methods;
            setLoading(function (loading) { return loading + 1; });
            methods
                .getCashBack(props.token.token)
                .call()
                .then(function (response) {
                var featuredRewardToken = response.featuredRewardToken, cashBackBPS = response.cashBackBPS, rewardTokens = response.rewardTokens, eligible = response.eligible;
                var validRewardTokens = rewardTokens.filter(function (token) { return token != ZERO_ADDR; });
                if (canProcess) {
                    if (eligible) {
                        var partialRewardTokens = validRewardTokens
                            .map(function (token) { return ({
                            chainId: props.chainId,
                            token: token,
                            symbol: shortenAddress(token),
                            name: '',
                            decimals: 0,
                        }); });
                        setRewardTokens(partialRewardTokens);
                        setCashBackBPS(parseInt(cashBackBPS));
                        // select default token
                        var defaultRewardToken = validRewardTokens.includes(featuredRewardToken)
                            ? featuredRewardToken
                            : validRewardTokens[Math.floor(Math.random() * validRewardTokens.length)];
                        setRewardToken(defaultRewardToken);
                        props.onCashBackRewardTokenChange(defaultRewardToken);
                        // retrieve token info asynchronously
                        validRewardTokens.forEach(function (token) {
                            getTokenInfo(props.chainId, token).then(function (tokenInfo) {
                                if (tokenInfo) {
                                    setRewardTokens(function (previousRewardTokens) {
                                        return previousRewardTokens.map(function (rewardToken) {
                                            if (!isSameAddress(tokenInfo.token, rewardToken.token)) {
                                                return rewardToken;
                                            }
                                            else {
                                                return __assign(__assign({}, tokenInfo), { token: rewardToken.token });
                                            }
                                        });
                                    });
                                }
                            });
                        });
                    }
                    else {
                        setRewardTokens([]);
                        setRewardToken(undefined);
                        props.onCashBackRewardTokenChange(undefined);
                    }
                }
                setLoading(function (loading) { return loading - 1; });
            })
                .catch(function () {
                setLoading(function (loading) { return loading - 1; });
            });
            return function () {
                canProcess = false;
            };
        }
        else {
            setRewardTokens([]);
            setRewardToken(undefined);
            props.onCashBackRewardTokenChange(undefined);
        }
    }, [props.chainId, props.token, (_a = connection === null || connection === void 0 ? void 0 : connection.provider) === null || _a === void 0 ? void 0 : _a.provider]);
    var handleChangeRewardToken = function (event) {
        var selectedRewardToken = event.target.value;
        setRewardToken(selectedRewardToken);
        props.onCashBackRewardTokenChange(selectedRewardToken);
    };
    if (!props.token || !ChainConfigs[props.chainId].cashBackEnabled) {
        return null;
    }
    if (loading > 0) {
        return (React.createElement("div", null,
            React.createElement(CircularProgress, { className: classes.loadingCashbackProgress, size: 12 }),
            t('GETTING_CASHBACK_REWARDS', 'Getting cash-back rewards')));
    }
    if (!rewardTokens.length) {
        return (React.createElement("div", null, t('NO_CASHBACK_REWARDS_FOR_TOKEN', 'No cash-back rewards found for selected token')));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Typography, { variant: "caption", color: "primary" }, t('SWAP_ELIGIBLE_FOR_CASHBACK', 'This swap is eligible for {{percent}} of cash-back! You can change your reward token below:', { percent: formatPercent(locale, (cashBackBPS || 0) / 100) }))),
        React.createElement(FormControl, null,
            React.createElement(Select, { id: "reward-token", value: rewardToken, label: t('REWARD_TOKEN', 'Reward Token'), onChange: handleChangeRewardToken }, rewardTokens.map((function (rewardToken) { return (React.createElement(MenuItem, { key: rewardToken.token, value: rewardToken.token },
                React.createElement(TokenImage, { className: classes.tokenImage, showSymbol: true, showName: true, token: rewardToken, chainId: props.chainId }))); }))))));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(SwapCashBack);
