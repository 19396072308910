import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ReactImageFallback from 'react-image-fallback';
import { getFiatIconUrl } from './utils';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        fiatIcon: {
            marginRight: theme.spacing(1),
            width: theme.spacing(4.5),
            height: theme.spacing(3),
        },
        fiatIconSkeleton: {
            marginRight: theme.spacing(1),
            width: theme.spacing(4.5),
            height: theme.spacing(3),
            display: 'flex',
        },
        fiatIconEmpty: {
            marginRight: theme.spacing(1),
            width: theme.spacing(4.5),
            height: theme.spacing(3),
        },
        fiatDescription: {
            display: 'flex',
            flexDirection: 'column',
        },
        fiatCode: {
            lineHeight: 'initial',
        },
        fiatName: {
            display: 'flex',
            color: theme.palette.text.disabled,
        },
    });
});
var FiatImage = function (props) {
    var classes = useStyles();
    var fiat = props.fiat;
    return (React.createElement("div", { className: (props.className) ? "".concat(props.className, " ").concat(classes.container) : classes.container },
        React.createElement(ReactImageFallback, { className: classes.fiatIcon, src: getFiatIconUrl(fiat.code), fallbackImage: React.createElement("div", { className: classes.fiatIconEmpty }), initialImage: React.createElement("div", { className: classes.fiatIconSkeleton },
                React.createElement(Skeleton, { width: 36, height: 24 })), alt: "" }),
        React.createElement("div", { className: classes.fiatDescription },
            props.showCode && (React.createElement("div", null,
                React.createElement(Typography, { variant: "subtitle2", className: classes.fiatCode }, fiat.code))),
            props.showName && (React.createElement("div", null,
                React.createElement(Typography, { variant: "caption", className: classes.fiatName }, fiat.name))))));
};
export default FiatImage;
