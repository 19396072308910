import { ACTION_TYPES } from '../app/constants';
export var DefaultShowPlan = false;
export default function showPlanReducer(state, action) {
    if (state === void 0) { state = DefaultShowPlan; }
    switch (action.type) {
        case ACTION_TYPES.SET_SHOW_PLAN:
            return action.payload;
    }
    return state;
}
