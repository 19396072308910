var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Fantom = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 32 32", key: "fantom" }),
        React.createElement("path", { d: "M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z", fill: "#0062F9" }),
        React.createElement("path", { d: "M15.094 7.043c.492-.247 1.24-.247 1.732 0l5.019 2.521c.3.151.463.377.489.609h.004v12.731h-.001c-.012.247-.177.49-.492.65L16.826 26.1c-.491.25-1.24.25-1.732 0l-5.018-2.544c-.32-.163-.475-.415-.482-.666v.015-12.731h.001c.02-.235.175-.455.48-.609l5.02-2.521zm-4.739 10.224v5.582l4.74 2.39c.273.14.557.278.835.288h.03c.252.002.496-.108.743-.224l.089-.041 4.784-2.433v-5.513l-4.693 2.353c-.484.242-1.216.247-1.709.013l-.027-.013-4.792-2.402zm-1.564 5.372c0 .485.06.803.178 1.027.098.186.245.328.513.501l.015.01c.059.038.123.077.202.123l.093.054.286.163-.41.642-.32-.183-.053-.031a6.933 6.933 0 01-.24-.146c-.764-.488-1.05-1.02-1.055-2.126v-.034h.791zm7.59-8.75v5.166a.783.783 0 00.132-.05l5.03-2.523a.42.42 0 00.015-.007l.005-.003-.008-.004-.012-.006-5.03-2.522a.783.783 0 00-.132-.05zm-.762.01a.743.743 0 00-.103.041l-5.03 2.522a.676.676 0 00-.015.008l-.004.002.008.005.011.005 5.03 2.522c.032.016.066.03.103.042v-5.147zm-5.264-2.845v4.624l4.606-2.31-4.606-2.314zm11.22-.005l-4.561 2.293 4.562 2.287v-4.58zm-5.117-3.342c-.261-.13-.734-.13-.995 0l-5.018 2.522a.67.67 0 00-.015.008l-.005.002.008.004.012.006 5.018 2.522c.261.131.734.131.995 0l5.018-2.522a.676.676 0 00.015-.008l.004-.002-.008-.004-.011-.006-5.018-2.522zm5.874.278l.32.183.053.03c.093.055.17.101.24.147.764.488 1.05 1.02 1.055 2.126v.033h-.791c0-.484-.06-.802-.178-1.027-.098-.186-.244-.328-.512-.5l-.016-.01a6.263 6.263 0 00-.202-.124l-.093-.054-.286-.163.41-.641z", fill: "#fff" })));
};
export default Fantom;
