var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { FixedNumber } from "ethers";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectContext } from "./ConnectionProvider";
import FiatInput from "./FiatInput";
import { ChainIds } from "./sharedTypes";
import TokenInput from "./TokenInput";
var SwapFiatNative = function (props) {
    var t = useTranslation().t;
    var connection = useContext(ConnectContext);
    var isConnected = connection.connect && connection.address;
    var amountFrom = useRef(FixedNumber.fromString('0'));
    var amountTo = useRef(FixedNumber.fromString('0'));
    var _a = useState(), inputFiat = _a[0], setInputFiat = _a[1];
    var _b = useState(''), inputAmount = _b[0], setInputAmount = _b[1];
    var _c = useState(false), inputError = _c[0], setInputError = _c[1];
    var _d = useState(''), outputAmount = _d[0], setOutputAmount = _d[1];
    var _e = useState(), outputToken = _e[0], setOutputToken = _e[1];
    var _f = useState([]), fiats = _f[0], setFiats = _f[1];
    var _g = useState([]), tokens = _g[0], setTokens = _g[1];
    useEffect(function () {
        if (props.swapFiatToTokenData) {
            setFiats(props.swapFiatToTokenData.fiats.map(function (f) { return ({ code: f.fiat_code, name: f.fiat_name, symbol: f.fiat_symbol }); }));
            //
            setTokens([
                {
                    chainId: ChainIds.BSC,
                    symbol: 'BNB',
                    name: 'Binance Coin',
                    token: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
                    decimals: 18,
                },
                {
                    chainId: ChainIds.BSC,
                    symbol: 'WNOW',
                    name: 'WalletNow',
                    token: '0x56aa0237244c67b9a854b4efe8479cca0b105289',
                    decimals: 18,
                },
            ]);
        }
    }, [props.swapFiatToTokenData]);
    useEffect(function () {
        if (props.swapFiatToTokenData && inputFiat) {
            /*
            const filteredTokens = props.swapFiatToTokenData.tokens
              .filter((token) => token.blockchains)
              .map((token) => {
                token.
              });
            */
        }
    }, [props.swapFiatToTokenData, inputFiat]);
    var handleChangeAmountFrom = function (amount, amountFN) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            amountFrom.current = amountFN;
            setInputAmount(amount);
            return [2 /*return*/];
        });
    }); };
    var handleChangeFiatFrom = function (fiat) {
        setInputFiat(fiat);
    };
    var handleChangeError = function (hasInputError) {
        setInputError(hasInputError);
    };
    var handleChangeAmountTo = function (amount, amountFN) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // output amount is hidden in the first version
            amountTo.current = amountFN;
            setOutputAmount(amount);
            return [2 /*return*/];
        });
    }); };
    var handleChangeTokenTo = function (tokenInfo) {
        setOutputToken(tokenInfo);
    };
    var disabled = !props.swapFiatToTokenData || !isConnected;
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(FiatInput, { autoFocus: true, amount: inputAmount, fiat: inputFiat, fiats: fiats, label: t('SWAP_FROM_FIAT', 'From Fiat'), onChangeAmount: handleChangeAmountFrom, onChangeFiat: handleChangeFiatFrom, onChangeError: handleChangeError, disabled: disabled })),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { textAlign: "center" },
                React.createElement(IconButton, { color: "inherit", "aria-label": t('SWAP_FROM_TO', 'Swap from/to'), disabled: disabled },
                    React.createElement(ArrowDownwardIcon, null)))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(TokenInput, { tokens: tokens, amount: outputAmount, token: outputToken, label: t('SWAP_TO', 'To'), chainId: props.chainId, onChangeAmount: handleChangeAmountTo, onChangeToken: handleChangeTokenTo, hiddenInput: true, disabled: disabled || !tokens.length }))));
};
export default SwapFiatNative;
