var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var PolygonOutline = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "3 3 14 14", key: "polygon_outline" }),
        React.createElement("path", { d: "M13.215 8.056a.905.905 0 00-.784 0l-1.827.98-1.24.623-1.795.979a.905.905 0 01-.784 0l-1.403-.772a.714.714 0 01-.392-.623V7.76c0-.238.13-.475.392-.623l1.403-.742a.905.905 0 01.784 0l1.403.771a.714.714 0 01.392.623v.98l1.24-.653v-1.01c0-.237-.13-.474-.392-.622l-2.61-1.395a.905.905 0 00-.784 0L4.142 6.513a.647.647 0 00-.392.594v2.79c0 .236.13.474.392.622l2.643 1.395a.905.905 0 00.784 0l1.795-.95 1.24-.652 1.795-.95a.905.905 0 01.783 0l1.403.742a.714.714 0 01.392.623v1.484c0 .237-.13.474-.392.623l-1.37.742a.905.905 0 01-.784 0l-1.403-.742a.714.714 0 01-.392-.623v-.95l-1.24.653v.98c0 .237.13.474.392.622l2.643 1.395a.905.905 0 00.784 0l2.643-1.395a.714.714 0 00.392-.623v-2.819c0-.237-.13-.475-.392-.623l-2.643-1.395z", fill: "currentColor" })));
};
export default PolygonOutline;
