var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var Avalanche = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 28 28", key: "avalanche" }),
        React.createElement("path", { d: "M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z", fill: "#E84142" }),
        React.createElement("path", { d: "M17.983 14.204c.391-.712 1.023-.712 1.414 0l2.436 4.504c.391.712.071 1.292-.711 1.292h-4.908c-.774 0-1.094-.58-.711-1.292l2.48-4.504zm-4.712-8.67c.391-.712 1.013-.712 1.405 0l.542 1.03 1.28 2.369c.312.674.312 1.47 0 2.144l-4.294 7.837A2.24 2.24 0 0110.444 20H6.878c-.782 0-1.102-.571-.711-1.292l7.104-13.174z", fill: "#fff" })));
};
export default Avalanche;
