/*
 * These types are shared between the different systems
 * (front-end, back-end and telegram bot)
 */
var _a;
/**
 * JSON schema for the user settings
 */
export var UpdateUserSettingsJsonSchema = {
    type: 'object',
    properties: {
        binanceIntegration: {
            type: 'object',
            properties: {
                apiKey: { type: 'string' },
                apiSecret: { type: 'string' },
            },
            additionalProperties: false,
        },
        accountRequiresLogin: { type: 'boolean' },
        customInvestments: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: { type: 'string' },
                    chain: { type: 'string' },
                    tokenAddress: { type: 'string' },
                    amount: { type: 'string' },
                    link: { type: 'string' },
                },
                required: ['name', 'tokenAddress', 'amount'],
                additionalProperties: false,
            }
        },
        customVaults: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: { type: 'string' },
                    vaultAddress: { type: 'string' },
                    link: { type: 'string' },
                    rewardToken: { type: 'string' },
                    pendingApi: { type: 'string' },
                },
                required: ['name', 'vaultAddress'],
                additionalProperties: false,
            }
        },
        ignoredTokens: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    token: { type: 'string' },
                    reportScam: { type: 'boolean' },
                },
                required: ['token'],
                additionalProperties: false,
            }
        },
        addIgnoredTokens: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    token: { type: 'string' },
                    reportScam: { type: 'boolean' },
                },
                required: ['token'],
                additionalProperties: false,
            }
        },
        walletAlias: { type: 'string' },
        wallets: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    address: { type: 'string' },
                    alias: { type: 'string' },
                },
                required: ['address'],
                additionalProperties: false,
            }
        },
        telegramUserId: { type: 'integer' },
        preferredCurrency: { type: 'string' },
        locale: { type: 'string' },
        activeMonitoring: {
            type: 'object',
            properties: {
                enabled: { type: 'boolean' },
                timeWindow: { type: 'integer' },
                priceIncreaseThreshold: { type: 'integer' },
                priceDecreaseThreshold: { type: 'integer' },
            },
            required: ['enabled', 'timeWindow'],
            additionalProperties: false,
        },
        investedCapital: {
            type: 'object',
            properties: {
                currency: { type: 'string' },
                invested: { type: 'number' },
            },
            required: ['currency', 'invested'],
            additionalProperties: false,
        }
    },
    additionalProperties: false,
};
/**
 * Flags for an investment
 */
export var InvestmentFlags;
(function (InvestmentFlags) {
    InvestmentFlags[InvestmentFlags["None"] = 0] = "None";
    /** This investment represents a wallet HODL */
    InvestmentFlags[InvestmentFlags["IsWalletHold"] = 1] = "IsWalletHold";
    /** This investment represents a CeFi HODL (such as Binance Exchange) */
    InvestmentFlags[InvestmentFlags["IsCeFiHold"] = 2] = "IsCeFiHold";
    /** This investment can only be seen if the user is a Silver or Gold member */
    InvestmentFlags[InvestmentFlags["RequiresSilver"] = 4] = "RequiresSilver";
    /** This investment can only be seen if the user is a Gold member */
    InvestmentFlags[InvestmentFlags["RequiresGold"] = 8] = "RequiresGold";
    /** Tokens on this investment counts towards the membership plan calculation */
    InvestmentFlags[InvestmentFlags["CountsForMembership"] = 16] = "CountsForMembership";
    /** This investment represents NFTs held by the account */
    InvestmentFlags[InvestmentFlags["IsNftsHold"] = 32] = "IsNftsHold";
    /** This investment should be displayed even if it has a very low USD amount */
    InvestmentFlags[InvestmentFlags["NoDustFilter"] = 64] = "NoDustFilter";
    /** This investment can only be seen if the user is a Diamond member */
    InvestmentFlags[InvestmentFlags["RequiresDiamond"] = 128] = "RequiresDiamond";
    /** This investment should not have any token details filtered, even on the free plan */
    InvestmentFlags[InvestmentFlags["NoTokensFilter"] = 256] = "NoTokensFilter";
    /** This investment should not have the "harvest" details filtered, even on the free plan */
    InvestmentFlags[InvestmentFlags["NoHarvestFilter"] = 512] = "NoHarvestFilter";
    /** This investment should not have the "earned" amount calculated (used internally) */
    InvestmentFlags[InvestmentFlags["NoEarnedAmount"] = 1024] = "NoEarnedAmount";
    /** Include all harvested tokens on this investment, even the ones with zero USD amount */
    InvestmentFlags[InvestmentFlags["IncludeAllHarvested"] = 2048] = "IncludeAllHarvested";
})(InvestmentFlags || (InvestmentFlags = {}));
/**
 * Account plans
 */
export var AccountPlan;
(function (AccountPlan) {
    AccountPlan[AccountPlan["Free"] = 0] = "Free";
    AccountPlan[AccountPlan["Bronze"] = 1] = "Bronze";
    AccountPlan[AccountPlan["Silver"] = 2] = "Silver";
    AccountPlan[AccountPlan["Gold"] = 3] = "Gold";
    AccountPlan[AccountPlan["Diamond"] = 4] = "Diamond";
})(AccountPlan || (AccountPlan = {}));
/**
 * IDs of all supported chains
 */
export var ChainIds;
(function (ChainIds) {
    ChainIds["BSC"] = "bsc";
    ChainIds["Polygon"] = "poly";
    ChainIds["Ethereum"] = "eth";
    ChainIds["Fantom"] = "ftm";
    ChainIds["Heco"] = "heco";
    ChainIds["Avalanche"] = "avax";
    ChainIds["Cronos"] = "cro";
    ChainIds["Cube"] = "cube";
})(ChainIds || (ChainIds = {}));
/**
 * Hard-coded chain configurations
 */
export var ChainConfigs = (_a = {},
    _a[ChainIds.BSC] = {
        chainId: 56,
        rpc: 'https://bsc-dataseed.binance.org',
        displayName: 'Binance Smart Chain',
        shortName: 'BSC',
        trustChainName: 'smartchain',
        explorerUrl: 'https://bscscan.com',
        nativeCurrency: {
            name: 'Binance Coin',
            symbol: 'BNB',
            decimals: 18,
        },
        nativeToken: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        distributedExchanges: [
            { name: 'PancakeSwap', factory: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73', router: '0x10ed43c718714eb63d5aa57b78b54704e256024e' },
            { name: 'ApeSwap', factory: '0x0841bd0b734e4f5853f0dd8d7ea041c241fb0da6', router: '0xcf0febd3f17cef5b47b0cd257acf6025c5bff3b7' },
            { name: 'AutoShark', factory: '0xe759dd4b9f99392be64f1050a6a8018f73b53a13', router: '0xb0eeb0632bab15f120735e5838908378936bd484' },
            { name: 'HyperJump', factory: '0xac653ce27e04c6ac565fd87f18128ad33ca03ba2', router: '0x3bc677674df90a9e5d741f28f6ca303357d0e4ec' },
            { name: 'PantherSwap', factory: '0x670f55c6284c629c23bae99f585e3f17e8b9fc31', router: '0x24f7c33ae5f77e2a9eceed7ea858b4ca2fa1b7ec' },
            { name: 'BakerySwap', factory: '0x01bf7c66c6bd861915cdaae475042d3c4bae16a7', router: '0xcde540d7eafe93ac5fe6233bee57e1270d3e330f' },
            { name: 'WaultSwap', factory: '0xb42e3fe71b7e0673335b3331b3e1053bd9822570', router: '0xd48745e39bbed146eec15b79cbf964884f9877c2' },
            { name: 'CheeseSwap', factory: '0xdd538e4fd1b69b7863e1f741213276a6cf1efb3b', router: '0x3047799262d8d2ef41ed2a222205968bc9b0d895' },
            { name: 'JulSwap', factory: '0x553990f2cba90272390f62c5bdb1681ffc899675', router: '0xbd67d157502a23309db761c41965600c2ec788b2' },
            { name: 'CobraSwap', factory: '0x3165d94dd2f71381495cb897832de02710a0dce5', router: '0xb2e8d6bd3eca4fd91fdc14c43580e80183979f2c' },
            { name: 'Biswap', factory: '0x858e3312ed3a876947ea49d572a7c42de08af7ee', router: '0x3a6d8ca21d1cf76f653a67577fa0d27453350dd8' },
            { name: 'DonkSwap', factory: '0x04d6b20f805e2bd537dde84482983aabf59536ff', router: '0xe779e189a865e880cccebc75bc353e38de487030' },
            { name: 'ShibaNova', factory: '0x251912de998ec91dfdf67efbe032d6f4ab5ec485', router: '0xeb17dd35e47b1a41ba4d86b3506ec1f9b680b56a' },
            { name: 'BabySwap', factory: '0x86407bea2078ea5f5eb5a52b2caa963bc1f889da', router: '0x325e343f1de602396e256b67efd1f61c3a6b38bd' },
            { name: 'Treedefi', factory: '0x103b1df1d7287bb341a04396e7ae5b97b0307e91', router: '0xf2ad5cacf437a69765eb590271be86d573cdbf0f' },
            { name: 'MDEX', factory: '0x3cd1c46068daea5ebb0d3f55f6915b10648062b8', router: '0x0384e9ad329396c3a6a401243ca71633b2bc4333' },
            { name: 'YuklaSwap', factory: '0xb30333b3983eebd968adff63eaf6c39378f8f375', router: '0x4279af04121f948398fa0a2204e75d86d5aaccc6' },
            { name: 'MarsSwap', factory: '0x6f12482d9869303b998c54d91bcd8bccba81f3be', router: '0xb68825c810e67d4e444ad5b9deb55ba56a66e72d' },
            { name: 'Kronos', factory: '0xcc045ebc2664daf316aa0652e72237609ea6cb4f', router: '0x98ae5a2f6c3c39b4171a84bee4c36c80e024053d' },
            { name: 'FarmaSwap', factory: '0xee638aa41e12b6c6df71f88410eb1ddd2fb28973', router: '0xf909649f05e26211ec16d4131349492f066cae7a' },
            { name: 'SMBSwap', factory: '0x2af5c23798fec8e433e11cce4a8822d95cd90565', router: '0x009d611490ecfed2dc3f306231bba7e7f3e9196e' },
        ],
        walletNowSwap: '0x2b76b3afbed72b80fe6d4d190a8798248f965d6a',
        cashBackEnabled: true,
    },
    _a[ChainIds.Polygon] = {
        chainId: 137,
        rpc: 'https://polygon-rpc.com',
        displayName: 'Polygon MATIC',
        shortName: 'Polygon',
        trustChainName: 'polygon',
        explorerUrl: 'https://polygonscan.com',
        nativeCurrency: {
            name: 'Polygon',
            symbol: 'MATIC',
            decimals: 18,
        },
        nativeToken: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
        distributedExchanges: [
            { name: 'QuickSwap', factory: '0x5757371414417b8c6caad45baef941abc7d3ab32', router: '0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff' },
            { name: 'SushiSwap', factory: '0xc35dadb65012ec5796536bd9864ed8773abc74c4', router: '0x1b02da8cb0d097eb8d57a175b88c7d8b47997506' },
            { name: 'ApeSwap', factory: '0xcf083be4164828f00cae704ec15a36d711491284', router: '0xc0788a3ad43d79aa53b09c2eacc313a787d1d607' },
            { name: 'WaultSwap', factory: '0xa98ea6356a316b44bf710d5f9b6b4ea0081409ef', router: '0x3a1d87f206d12415f5b0a33e786967680aab4f6d' },
            //{name: 'Dfyn', factory: '0xe7fb3e833efe5f9c441105eb65ef8b261266423b', router: '0xa102072a4c07f06ec3b4900fdc4c7b80b6c57429'},
            { name: 'Cometh', factory: '0x800b052609c355ca8103e06f022aa30647ead60a', router: '0x93bcdc45f7e62f89a8e901dc4a0e2c6c427d9f25' },
            { name: 'ProtoFi', factory: '0xd109af9eea5849f1a3c7a976995a5c315534dd9c', router: '0xd3b580c6653a36e09d4356448ff8714b6ffe9bdb' },
            { name: 'JetSwap', factory: '0x668ad0ed2622c62e24f0d5ab6b6ac1b9d2cd4ac7', router: '0x5c6ec38fb0e2609672bdf628b1fd605a523e5923' },
            // Firebird uses a very different strategy, where the "path" contains pairs instead of tokens. Don't know if we can support that
            // {name: 'Firebird', factory: '0x5de74546d3b86c8df7feec30253865e1149818c8', router: '0xb31d1b1ea48ce4bf10ed697d44b747287e785ad4'},
            { name: 'DinoSwap', factory: '0x624ccf581371f8a4493e6abde46412002555a1b6', router: '0x6ac823102cb347e1f5925c634b80a98a3aee7e03' },
            { name: 'Magician', factory: '0xf08ae17c2a2728a788bb1b6c243fe7eb3e5bbadc', router: '0xd0b5335be74480f9303b88f5b55acd676598882a' },
        ],
        walletNowSwap: '0x8f6b21410e2d2aa0b93b4324605fdae1c38697ba'
    },
    _a[ChainIds.Ethereum] = {
        chainId: 1,
        rpc: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        displayName: 'Ethereum',
        shortName: 'ETH',
        trustChainName: 'ethereum',
        explorerUrl: 'https://etherscan.io',
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18,
        },
        nativeToken: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        distributedExchanges: [
            { name: 'UniswapV2', factory: '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f', router: '0x7a250d5630b4cf539739df2c5dacb4c659f2488d' },
            { name: 'PancakeSwap', factory: '0x1097053fd2ea711dad45caccc45eff7548fcb362', router: '0xeff92a263d31888d860bd50809a8d171709b7b1c' },
            { name: 'SushiSwap', factory: '0xc0aee478e3658e2610c5f7a4a2e1777ce9e4f2ac', router: '0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f' },
            { name: 'ShibaSwap', factory: '0x115934131916C8b277DD010Ee02de363c09d037c', router: '0x03f7724180aa6b939894b5ca4314783b0b36b329' },
        ],
        walletNowSwap: '0x8f6b21410e2d2aa0b93b4324605fdae1c38697ba',
    },
    _a[ChainIds.Fantom] = {
        chainId: 250,
        rpc: 'https://rpcapi.fantom.network',
        displayName: 'Fantom Opera',
        shortName: 'Fantom',
        trustChainName: 'fantom',
        explorerUrl: 'https://ftmscan.com',
        nativeCurrency: {
            name: 'Fantom',
            symbol: 'FTM',
            decimals: 18,
        },
        nativeToken: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
        distributedExchanges: [
            { name: 'SushiSwap', factory: '0xc35dadb65012ec5796536bd9864ed8773abc74c4', router: '0x1b02da8cb0d097eb8d57a175b88c7d8b47997506' },
            { name: 'SpookySwap', factory: '0x152ee697f2e276fa89e96742e9bb9ab1f2e61be3', router: '0xf491e7b69e4244ad4002bc14e878a34207e38c29' },
            { name: 'SpiritSwap', factory: '0xef45d134b73241eda7703fa787148d9c9f4950b0', router: '0x16327e3fbdaca3bcf7e38f5af2599d2ddc33ae52' },
            { name: 'DarkNightSwap', factory: '0x7d82f56ea0820a9d42b01c3c28f1997721732218', router: '0x045312c737a6b7a115906be0ad0ef53a6aa38106' },
            { name: 'JetSwap', factory: '0xf6488205957f0b4497053d6422f49e27944ee3dd', router: '0x845e76a8691423fbc4ecb8dd77556cb61c09ee25' },
        ],
        walletNowSwap: '0x74b99b34a67bb0e0a748d8c465e0e2d0f20e1956',
        multiCall: '0xa447a16d0c6b00650001f263e0954e12d541c088',
    },
    _a[ChainIds.Heco] = {
        chainId: 128,
        rpc: 'https://http-mainnet.hecochain.com/',
        displayName: 'HECO Chain',
        shortName: 'HECO',
        trustChainName: 'heco',
        explorerUrl: 'https://hecoinfo.com',
        nativeCurrency: {
            name: 'Heco Token',
            symbol: 'HT',
            decimals: 18,
        },
        nativeToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
        distributedExchanges: [
            { name: 'MDex', factory: '0xb0b670fc1f7724119963018db0bfa86adb22d941', router: '0xed7d5f38c79115ca12fe6c0041abb22f0a06c300' },
            { name: 'BXH', factory: '0xe0367ec2bd4ba22b1593e4fefcb91d29de6c512a', router: '0x00efb96dbfe641246e961b472c0c3fc472f6a694' },
            { name: 'MakiSwap', factory: '0x11cdc9bd86ff68b6a6152037342bae0c3a717f56', router: '0x7f88bc12aa1ed9ff4605123649ac90f2cd9407eb' },
            { name: 'Elk', factory: '0x997fce9164d630cc58ee366d4d275b9d773d54a4', router: '0x62710d18596c808c70864695c77480de252dd9d5' },
            { name: 'PippiShrimp', factory: '0x979efe7ca072b72d6388f415d042951ddf13036e', router: '0xbe4ab2603140f134869cb32ab4bc56d762ae900b' },
            { name: 'HSwap', factory: '0x13d1ea691c8153f5bc9a491d41b927e2baf8a6b1', router: '0x7b7fb50541dc2a5f9ce06a67408db0e0e677cd06' },
            { name: 'ButterSwap', factory: '0x874d01ca682c9c26ba7e6d9f6f801d1a1fb49201', router: '0x4577321b36a3b64ea83a1d92ed5e3ffab3106342' },
        ],
        walletNowSwap: '0xb8db7ac122a9bd512cf14e65bdbc64c5df4f41a8',
        multiCall: '0x23d4dced0bcf4c84771477f71a73b34830fe5071',
    },
    _a[ChainIds.Avalanche] = {
        chainId: 43114,
        rpc: 'https://api.avax.network/ext/bc/C/rpc',
        displayName: 'Avalanche Chain',
        shortName: 'Avalanche',
        trustChainName: 'avalanchec',
        explorerUrl: 'https://snowtrace.io',
        nativeCurrency: {
            name: 'AVAX Token',
            symbol: 'AVAX',
            decimals: 18,
        },
        nativeToken: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
        distributedExchanges: [
            { name: 'PangolinExchange', factory: '0xefa94de7a4656d787667c749f7e1223d71e9fd88', router: '0xe54ca86531e17ef3616d22ca28b0d458b6c89106' },
            { name: 'SushiSwap', factory: '0xc35dadb65012ec5796536bd9864ed8773abc74c4', router: '0x1b02da8cb0d097eb8d57a175b88c7d8b47997506' },
            { name: 'TraderJoe', factory: '0x9ad6c38be94206ca50bb0d90783181662f0cfa10', router: '0x60ae616a2155ee3d9a68541ba4544862310933d4' },
            { name: 'Thorus', factory: '0xa98ea6356a316b44bf710d5f9b6b4ea0081409ef', router: '0xb5b2444edf79b00d40f463f79158d1187a0d0c25' },
        ],
        walletNowSwap: '0xb8db7ac122a9bd512cf14e65bdbc64c5df4f41a8',
        multiCall: '0x23d4dced0bcf4c84771477f71a73b34830fe5071',
    },
    _a[ChainIds.Cronos] = {
        chainId: 25,
        rpc: 'https://evm.cronos.org',
        displayName: 'Cronos Chain',
        shortName: 'Cronos',
        trustChainName: 'cronos',
        explorerUrl: 'https://cronoscan.com',
        nativeCurrency: {
            name: 'Cronos Token',
            symbol: 'CRO',
            decimals: 18,
        },
        nativeToken: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23',
        distributedExchanges: [
            { name: 'VVSFinance', factory: '0x3b44b2a187a7b3824131f8db5a74194d0a42fc15', router: '0x145863eb42cf62847a6ca784e6416c1682b1b2ae' },
            { name: 'MM.Finance', factory: '0xd590cc180601aecd6eeadd9b7f2b7611519544f4', router: '0x145677fc4d9b8f19b5d56d1820c48e0443049a30' },
            { name: 'Crodex', factory: '0xe9c29cb475c0ade80be0319b74ad112f1e80058f', router: '0xec0a7a0c2439e8cb67b992b12ecd020ea943c7be' },
            { name: 'CronaSwap', factory: '0x73a48f8f521eb31c55c0e1274db0898de599cb11', router: '0xcd7d16fb918511bf7269ec4f48d61d79fb26f918' },
            { name: 'Annex', factory: '0x1cc79ecb3a6f9b6d6faf7298ec6d8667e814d592', router: '0x77befde82eba4bdc4d3e4a853bf3ea4ffb49dd58' },
            { name: 'CyborgSwap', factory: '0x6c50ee65cfcfc59b09c570e55d76daa7c67d6da5', router: '0xd3b68a35002b1d328a5414cb60ad95f458a8cf92' },
            { name: 'Elk', factory: '0xeea0e2830d09d8786cb9f484ca20898b61819ef1', router: '0xdb02a597b283eacb9436cd2a2d15039a11a3299d' },
            { name: 'SmolSwap', factory: '0x7aa2149ff9ef4a09d4ace72c49c26aae8c89fb48', router: '0x8118dd9fed86523bf724e2ec5f56055da0668af4' },
        ],
        walletNowSwap: '0x044ca19c88729122535b0240135fa3694ead9526',
        multiCall: '0xbf053d73a0d76b4d69e925892d6c26e8bf0e7022',
    },
    _a);
