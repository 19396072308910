import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
export var ALL_LOCALES = {
    'en-US': {
        code: 'en-US',
        name: 'English',
        englishName: 'English',
        translation: require('./en-US/translation.json'),
    },
    'de': {
        code: 'de',
        name: 'Deutsch',
        englishName: 'German',
        translation: require('./de/translation.json'),
    },
    'es': {
        code: 'es',
        name: 'Español',
        englishName: 'Spanish',
        translation: require('./es/translation.json'),
    },
    'pt-BR': {
        code: 'pt-BR',
        name: 'Português (Brasil)',
        englishName: 'Portuguese (Brazil)',
        translation: require('./pt-BR/translation.json'),
    },
    'ru': {
        code: 'ru',
        name: 'русский',
        englishName: 'Russian',
        translation: require('./ru/translation.json'),
    },
    'id': {
        code: 'id',
        name: 'Indonesia',
        englishName: 'Indonesian',
        translation: require('./id/translation.json'),
    },
    'it': {
        code: 'it',
        name: 'Italiano',
        englishName: 'Italian',
        translation: require('./it/translation.json'),
    },
};
export var initI18n = function () {
    i18n.use(initReactI18next).init({
        lng: localStorage.getItem('locale') || 'en-US',
        resources: ALL_LOCALES,
        interpolation: {
            escapeValue: false
        }
    });
};
