var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var AvalancheOutline = function (props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "3 3 14 14", key: "avalanche_outline" }),
        React.createElement("path", { d: "M12.987 11.363c.294-.569.767-.569 1.06 0l1.828 3.603c.293.57.053 1.034-.534 1.034h-3.68c-.58 0-.82-.464-.534-1.034l1.86-3.603zM9.453 4.427c.294-.57.76-.57 1.054 0l.407.824.96 1.895c.233.54.233 1.176 0 1.715l-3.221 6.27c-.293.51-.78.832-1.32.869H4.659c-.587 0-.827-.457-.534-1.034L9.453 4.427z", fill: "currentColor" })));
};
export default AvalancheOutline;
