import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import FiatImage from './FiatImage';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        iconButtonClose: {
            float: 'right',
            marginTop: 2,
        },
        searchInput: {
            marginBottom: theme.spacing(2),
        },
        spacer: {
            flexGrow: 1,
        },
        infoLink: {
            display: 'inline-block',
        },
        openIcon: {
            verticalAlign: 'middle',
        },
        importContainer: {
            display: 'flex',
            alignItems: 'center',
        }
    });
});
var FiatSelector = function (props) {
    var classes = useStyles({});
    var t = useTranslation().t;
    var _a = useState(''), search = _a[0], setSearch = _a[1];
    var handleSelectFiat = function (fiat) { return function () {
        props.onSelectFiat(fiat);
        props.onCloseDialog();
    }; };
    var handleClose = function () {
        props.onCloseDialog();
    };
    var handleChangeSearch = function (event) {
        setSearch(event.target.value);
    };
    var fiatMatches = function (fiat, q) {
        var sanitized = (q || '').trim().toLowerCase();
        if (sanitized == '') {
            return true;
        }
        // TODO: optimize by converting to lower-case everything only once
        return (fiat.code.toLowerCase().includes(sanitized) ||
            fiat.name.toLowerCase().includes(sanitized));
    };
    var renderFiats = function (fiats) {
        var items = fiats.map(function (fiat) {
            if (!fiatMatches(fiat, search)) {
                return;
            }
            return (React.createElement(MenuItem, { key: fiat.code, onClick: handleSelectFiat(fiat) },
                React.createElement(FiatImage, { showCode: true, showName: true, fiat: fiat })));
        }).filter(function (el) { return el; });
        return items;
    };
    return (React.createElement(Dialog, { onClose: handleClose, "aria-labelledby": "dialog-title", open: props.open, maxWidth: "xs", fullWidth: true },
        React.createElement(DialogTitle, { id: "dialog-title", disableTypography: true },
            React.createElement(Typography, { variant: "h6", color: "inherit" },
                t('SELECT_FIAT', 'Select Fiat'),
                React.createElement(IconButton, { color: "inherit", className: classes.iconButtonClose, "aria-label": t('CLOSE', 'Close'), size: "small", onClick: handleClose },
                    React.createElement(CloseIcon, null)))),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { className: classes.searchInput, label: t('SEARCH_FIAT', 'Search Fiat'), id: "value", value: search, onChange: handleChangeSearch, variant: "outlined", fullWidth: true }),
            renderFiats(props.fiats))));
};
export default FiatSelector;
