var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var TrustWallet = function (props) {
    return (React.createElement(SvgIcon, __assign({ xmlns: 'http://www.w3.org/2000/svg', width: 65, height: 64, viewBox: "0 0 65 64", fill: "none" }, props, { key: "trust-wallet" }),
        React.createElement("g", { clipPath: "url(#prefix__clip0)" },
            React.createElement("path", { d: "M32.4 64c17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32C14.727 0 .4 14.327.4 32c0 17.673 14.327 32 32 32z", fill: "#3375BB" }),
            React.createElement("path", { d: "M32.627 14c6.324 5.282 13.576 4.956 15.648 4.956-.453 30.038-3.907 24.081-15.648 32.504C20.885 43.037 17.453 48.994 17 18.956c2.05 0 9.303.326 15.627-4.956z", stroke: "#fff", strokeWidth: 4, strokeMiterlimit: 10, strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "prefix__clip0" },
                React.createElement("path", { fill: "#fff", transform: "translate(.4)", d: "M0 0h64v64H0z" })))));
};
export default TrustWallet;
