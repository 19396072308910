var _a, _b;
import HelpIcon from '@material-ui/icons/Help';
import { ChainIds } from '../../app/sharedTypes';
import BSC from './BSC';
import BSCOutline from './BSCOutline';
import Ethereum from './Ethereum';
import EthereumOutline from './EthereumOutline';
import Fantom from './Fantom';
import FantomOutline from './FantomOutline';
import HECO from './HECO';
import HECOOutline from './HECOOutline';
import Polygon from './Polygon';
import PolygonOutline from './PolygonOutline';
import Avalanche from './Avalanche';
import AvalancheOutline from './AvalancheOutline';
import Cronos from './Cronos';
import CronosOutline from './CronosOutline';
import Cube from './Cube';
import CubeOutline from './CubeOutline';
var chainIcons = (_a = {},
    _a[ChainIds.BSC] = BSC,
    _a[ChainIds.Ethereum] = Ethereum,
    _a[ChainIds.Fantom] = Fantom,
    _a[ChainIds.Heco] = HECO,
    _a[ChainIds.Polygon] = Polygon,
    _a[ChainIds.Avalanche] = Avalanche,
    _a[ChainIds.Cronos] = Cronos,
    _a[ChainIds.Cube] = Cube,
    _a);
var chainIconsOutlined = (_b = {},
    _b[ChainIds.BSC] = BSCOutline,
    _b[ChainIds.Ethereum] = EthereumOutline,
    _b[ChainIds.Fantom] = FantomOutline,
    _b[ChainIds.Heco] = HECOOutline,
    _b[ChainIds.Polygon] = PolygonOutline,
    _b[ChainIds.Avalanche] = AvalancheOutline,
    _b[ChainIds.Cronos] = CronosOutline,
    _b[ChainIds.Cube] = CubeOutline,
    _b);
export var getChainIcon = function (chainId) {
    return chainIcons[chainId] || HelpIcon;
};
export var getChainIconOutlined = function (chainId) {
    return chainIconsOutlined[chainId] || HelpIcon;
};
