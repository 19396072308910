import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TelegramIcon from '../icons/Telegram';
import TwitterIcon from '../icons/Twitter';
import GitHubIcon from '../icons/GitHub';
import GitBookIcon from '../icons/GitBook';
import Copyright from './Copyright';
import RequestFeatureButton from './RequestFeatureButton';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            display: 'flex',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            alignItems: 'center',
        },
        grow: {
            flexGrow: 1
        },
        divider: {
            marginTop: theme.spacing(2),
        }
    });
});
var Footer = function () {
    var classes = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, { className: classes.divider }),
        React.createElement(Container, { maxWidth: "lg" },
            React.createElement(Box, { className: classes.container },
                React.createElement(Copyright, null),
                React.createElement(Box, { className: classes.grow }),
                React.createElement(IconButton, { target: "_blank", rel: "noopener", href: "https://docs.walletnow.net/" },
                    React.createElement(GitBookIcon, null)),
                React.createElement(IconButton, { target: "_blank", rel: "noopener", href: "https://t.me/WalletNow" },
                    React.createElement(TelegramIcon, null)),
                React.createElement(IconButton, { target: "_blank", rel: "noopener", href: "https://twitter.com/WalletNow" },
                    React.createElement(TwitterIcon, null)),
                React.createElement(IconButton, { target: "_blank", rel: "noopener", href: "https://github.com/wallet-now/" },
                    React.createElement(GitHubIcon, null)),
                React.createElement(IconButton, { target: "_blank", rel: "noopener", href: "https://www.youtube.com/channel/UCAf-bYEwWeWJ67qvbeWEFfg/" },
                    React.createElement(YouTubeIcon, null))),
            React.createElement(Box, { pb: 2 },
                React.createElement(RequestFeatureButton, null)))));
};
export default Footer;
