/* eslint-disable no-underscore-dangle */
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import settingsReducer from './settingsReducer';
import busyReducer from './busyReducer';
import accountDataReducer from './accountDataReducer';
import connectedAccountsReducer from './connectedAccountsReducer';
import summaryDataReducer from './summaryDataReducer';
import showPlanReducer from './showPlanReducer';
var composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
var store = createStore(combineReducers({
    settings: settingsReducer,
    connectedAccounts: connectedAccountsReducer,
    busy: busyReducer,
    accountData: accountDataReducer,
    summaryData: summaryDataReducer,
    showPlan: showPlanReducer,
}), {}, composeEnhancers(applyMiddleware(thunk)));
export default store;
