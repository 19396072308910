var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import i18next from 'i18next';
import { ACTION_TYPES } from '../app/constants';
export var SortModes;
(function (SortModes) {
    SortModes["ASC"] = "ASC";
    SortModes["DESC"] = "DESC";
})(SortModes || (SortModes = {}));
export var WalletColumns;
(function (WalletColumns) {
    WalletColumns["protocol"] = "protocol";
    WalletColumns["name"] = "name";
    WalletColumns["totalAmountUSD"] = "totalAmountUSD";
    WalletColumns["totalYieldUSD"] = "totalYieldUSD";
    WalletColumns["pendingYieldUSD"] = "pendingYieldUSD";
    WalletColumns["totalDebtUSD"] = "totalDebtUSD";
    WalletColumns["totalFeeSpentUSD"] = "totalFeeSpentUSD";
    WalletColumns["totalNetProfitUS"] = "totalNetProfitUSD";
})(WalletColumns || (WalletColumns = {}));
;
var prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
var defaultWalletHiddenColumns = {
    protocol: false,
    name: false,
    totalAmountUSD: false,
    totalYieldUSD: false,
    pendingYieldUSD: true,
    totalDebtUSD: true,
    totalFeeSpentUSD: true,
    totalNetProfitUSD: true,
};
export var DefaultSettings = {
    mode: (_a = localStorage.getItem('mode')) !== null && _a !== void 0 ? _a : ((prefersDarkMode) ? 'dark' : 'light'),
    locale: localStorage.getItem('locale') || 'en-US',
    preferredCurrency: localStorage.getItem('preferredCurrency') || 'USD',
    walletHiddenColumns: __assign(__assign({}, defaultWalletHiddenColumns), JSON.parse(localStorage.getItem('walletHiddenColumns') || '{}')),
    maskedSummary: localStorage.getItem('maskedSummary') == 'true',
};
var equalWalletHiddenColumns = function (object1, object2) {
    return (object1.protocol == object2.protocol &&
        object1.name == object2.name &&
        object1.totalAmountUSD == object2.totalAmountUSD &&
        object1.totalYieldUSD == object2.totalYieldUSD &&
        object1.pendingYieldUSD == object2.pendingYieldUSD &&
        object1.totalDebtUSD == object2.totalDebtUSD &&
        object1.totalFeeSpentUSD == object2.totalFeeSpentUSD &&
        object1.totalNetProfitUSD == object2.totalNetProfitUSD);
};
export default function settingsReducer(state, action) {
    var _a;
    if (state === void 0) { state = DefaultSettings; }
    switch (action.type) {
        case ACTION_TYPES.SETTINGS_CHANGE:
            var newState = {
                mode: action.payload.mode || state.mode,
                locale: action.payload.locale || state.locale,
                preferredCurrency: action.payload.preferredCurrency || state.preferredCurrency,
                walletHiddenColumns: action.payload.walletHiddenColumns || state.walletHiddenColumns,
                maskedSummary: (_a = action.payload.maskedSummary) !== null && _a !== void 0 ? _a : state.maskedSummary,
            };
            if (state.locale != newState.locale) {
                i18next.changeLanguage(newState.locale);
            }
            if (state.mode != newState.mode) {
                localStorage.setItem('mode', newState.mode);
            }
            if (state.maskedSummary != newState.maskedSummary) {
                localStorage.setItem('maskedSummary', String(newState.maskedSummary));
            }
            if (!equalWalletHiddenColumns(state.walletHiddenColumns, newState.walletHiddenColumns)) {
                localStorage.setItem('walletHiddenColumns', JSON.stringify(newState.walletHiddenColumns));
            }
            return newState;
        case ACTION_TYPES.SET_ACCOUNT_DATA:
            var userSettings = (action.payload || {}).userSettings || {};
            var locale = userSettings.locale || localStorage.getItem('locale') || 'en-US';
            var preferredCurrency = userSettings.preferredCurrency || localStorage.getItem('preferredCurrency') || 'USD';
            if (locale == state.locale && preferredCurrency == state.preferredCurrency) {
                return state;
            }
            return __assign(__assign({}, state), { locale: locale, preferredCurrency: preferredCurrency });
    }
    return state;
}
