var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { ChainConfigs, ChainIds } from './sharedTypes';
import { DEFAULT_SWAP_SETTINGS, DexMode, ensureValidChainIds } from './swapUtils';
import ChainSelector from './ChainSelector';
import Checkbox from '@material-ui/core/Checkbox';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        container: {
            display: 'flex',
        },
        spacer: {
            flexGrow: 1,
        },
        iconButtonClose: {
            float: 'right',
            marginTop: 2,
        },
        tabs: {
            marginBottom: theme.spacing(2),
            borderBottom: "1px solid ".concat((theme.palette.type == 'light') ? '#c7c8d6' : theme.palette.secondary.dark),
        },
        scrollableContainer: {
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
        },
    });
});
var SwapSettingsDialog = function (props) {
    var classes = useStyles();
    var _a = useState('dexes'), tab = _a[0], setTab = _a[1];
    var _b = useState(ChainIds.BSC), chainId = _b[0], setChainId = _b[1];
    var _c = useState([]), filterChainIds = _c[0], setFilterChainIds = _c[1];
    var _d = useState(DEFAULT_SWAP_SETTINGS), swapSettings = _d[0], setSwapSettings = _d[1];
    var t = useTranslation().t;
    useEffect(function () {
        var _a;
        // get user settings from local storage
        var settings = JSON.parse((_a = localStorage.getItem('swapSettings')) !== null && _a !== void 0 ? _a : JSON.stringify(DEFAULT_SWAP_SETTINGS));
        setSwapSettings(settings);
    }, []);
    useEffect(function () {
        setFilterChainIds(ensureValidChainIds(props.filterChainIds || []));
    }, [props.filterChainIds]);
    var handleClose = function () {
        props.onClose();
    };
    var handleChangeTab = function (event, newValue) {
        setTab(newValue);
    };
    var onClickChain = function (chainId) {
        setChainId(chainId);
    };
    var handleChangeDexMode = function (event) {
        var newDexMode = event.target.value;
        var newSwapSettings = __assign(__assign({}, swapSettings), { dexMode: newDexMode });
        setSwapSettings(newSwapSettings);
        localStorage.setItem('swapSettings', JSON.stringify(newSwapSettings));
    };
    var handleSetPreferredDex = function (chainId, dex) { return function (event) {
        var _a, _b;
        var preferredDexes = swapSettings.preferredDexes[chainId];
        var index = preferredDexes.indexOf(dex.factory);
        if (!event.target.checked) {
            if (index > -1) {
                preferredDexes.splice(index, 1);
                var newSwapSettings = {
                    dexMode: swapSettings.dexMode,
                    preferredDexes: __assign(__assign({}, swapSettings.preferredDexes), (_a = {}, _a[chainId] = preferredDexes, _a))
                };
                setSwapSettings(newSwapSettings);
                localStorage.setItem('swapSettings', JSON.stringify(newSwapSettings));
            }
        }
        else {
            if (index === -1) {
                preferredDexes.push(dex.factory);
                var newSwapSettings = {
                    dexMode: swapSettings.dexMode,
                    preferredDexes: __assign(__assign({}, swapSettings.preferredDexes), (_b = {}, _b[chainId] = preferredDexes, _b))
                };
                setSwapSettings(newSwapSettings);
                localStorage.setItem('swapSettings', JSON.stringify(newSwapSettings));
            }
        }
    }; };
    var isDexPreferred = function (chainId, dex) {
        if (swapSettings.dexMode == DexMode.DEX_MODE_ALL) {
            return true;
        }
        return swapSettings.preferredDexes[chainId].includes(dex.factory);
    };
    return (React.createElement(Dialog, { onClose: handleClose, "aria-labelledby": "swap-config-dialog-title", open: props.show },
        React.createElement(DialogTitle, { id: "swap-config-dialog-title", disableTypography: true },
            React.createElement(Typography, { variant: "h6", color: "inherit" },
                t('SWAP_SETTINGS', 'Swap Settings'),
                React.createElement(IconButton, { color: "inherit", className: classes.iconButtonClose, "aria-label": t('CLOSE', 'Close'), size: "small", onClick: handleClose },
                    React.createElement(CloseIcon, null)))),
        React.createElement(DialogContent, null,
            React.createElement(Tabs, { value: tab, onChange: handleChangeTab, className: classes.tabs },
                React.createElement(Tab, { value: "dexes", label: t('PREFERRED_DEXES', 'Preferred DEXes') })),
            (tab == "dexes") && (React.createElement(Box, null,
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(FormControl, { component: "fieldset" },
                            React.createElement(RadioGroup, { "aria-label": "dexes", name: "dexes", value: swapSettings.dexMode, onChange: handleChangeDexMode },
                                React.createElement(FormControlLabel, { value: DexMode.DEX_MODE_ALL, control: React.createElement(Radio, { color: "primary" }), label: t('USE_ALL_DEXES', 'Use all DEXes') }),
                                React.createElement(FormControlLabel, { value: DexMode.DEX_MODE_CUSTOM, control: React.createElement(Radio, { color: "primary" }), label: t('SELECT_PREFERRED_DEXES', 'Select preferred DEXes') })))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("div", { className: classes.scrollableContainer },
                            React.createElement("div", { className: classes.spacer }),
                            React.createElement(ChainSelector, { chainId: chainId, chainIds: filterChainIds, onClickChain: onClickChain, disabled: swapSettings.dexMode == DexMode.DEX_MODE_ALL }))),
                    ChainConfigs[chainId].distributedExchanges.map(function (dex) {
                        return (React.createElement(Grid, { key: "".concat(chainId, "-").concat(dex.factory), item: true, xs: 12 },
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: isDexPreferred(chainId, dex), onChange: handleSetPreferredDex(chainId, dex), name: dex.factory, color: "primary", disabled: swapSettings.dexMode == DexMode.DEX_MODE_ALL }), label: dex.name })));
                    })))))));
};
export default SwapSettingsDialog;
