export var BSCToken;
(function (BSCToken) {
    BSCToken["BUSD"] = "0xe9e7cea3dedca5984780bafc599bd69add087d56";
    BSCToken["USDT"] = "0x55d398326f99059ff775485246999027b3197955";
    BSCToken["WBNB"] = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
    BSCToken["DAI"] = "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3";
    BSCToken["VAI"] = "0x4bd17003473389a42daf6a0a729f6fdb328bbbd7";
    BSCToken["USDC"] = "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d";
    BSCToken["TUSD"] = "0x14016e85a25aeb13065688cafb43044c2ef86784";
})(BSCToken || (BSCToken = {}));
export var PolygonToken;
(function (PolygonToken) {
    PolygonToken["WMATIC"] = "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";
    PolygonToken["USDT"] = "0xc2132d05d31c914a87c6611c10748aeb04b58e8f";
    PolygonToken["USDC"] = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
    PolygonToken["WETH"] = "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619";
    PolygonToken["DAI"] = "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063";
    PolygonToken["WBTC"] = "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6";
})(PolygonToken || (PolygonToken = {}));
export var EthereumToken;
(function (EthereumToken) {
    EthereumToken["WETH"] = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
    EthereumToken["USDT"] = "0xdac17f958d2ee523a2206206994597c13d831ec7";
    EthereumToken["USDC"] = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
})(EthereumToken || (EthereumToken = {}));
export var FantomToken;
(function (FantomToken) {
    FantomToken["WFTM"] = "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83";
    FantomToken["fUSDT"] = "0x049d68029688eabf473097a2fc38ef61633a3c7a";
    FantomToken["USDC"] = "0x04068da6c83afcfa0e13ba15a6696662335d5b75";
    FantomToken["DAI"] = "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e";
    FantomToken["BTC"] = "0x321162Cd933E2Be498Cd2267a90534A804051b11";
    FantomToken["ETH"] = "0x74b23882a30290451a17c44f4f05243b6b58c76d";
    FantomToken["MIM"] = "0x82f0b8b456c1a451378467398982d4834b6829c1";
})(FantomToken || (FantomToken = {}));
export var HecoToken;
(function (HecoToken) {
    HecoToken["WHT"] = "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f";
    HecoToken["USDT"] = "0xa71edc38d189767582c38a3145b5873052c3e47a";
    HecoToken["HUSD"] = "0x0298c2b32eae4da002a15f36fdf7615bea3da047";
    HecoToken["USDC_HECO"] = "0x9362bbef4b8313a8aa9f0c9808b80577aa26b73b";
    HecoToken["TUSD"] = "0x5ee41ab6edd38cdfb9f6b4e6cf7f75c87e170d98";
})(HecoToken || (HecoToken = {}));
export var AvalancheToken;
(function (AvalancheToken) {
    AvalancheToken["WAVAX"] = "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7";
    AvalancheToken["USDT_e"] = "0xc7198437980c041c805a1edcba50c1ce5db95118";
    AvalancheToken["USDC_e"] = "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664";
    AvalancheToken["DAI_e"] = "0xd586e7f844cea2f87f50152665bcbc2c279d8d70";
    AvalancheToken["WBTC_e"] = "0x50b7545627a5162f82a992c33b87adc75187b218";
    AvalancheToken["WETH_e"] = "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab";
})(AvalancheToken || (AvalancheToken = {}));
export var CronosToken;
(function (CronosToken) {
    CronosToken["WCRO"] = "0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23";
    CronosToken["USDT"] = "0x66e428c3f67a68878562e79a0234c1f83c208770";
    CronosToken["USDC"] = "0xc21223249ca28397b4b6541dffaecc539bff0c59";
    CronosToken["DAI"] = "0xf2001b145b43032aaf5ee2884e456ccd805f677d";
    CronosToken["TUSD"] = "0x87efb3ec1576dec8ed47e58b832bedcd86ee186e";
    CronosToken["WBTC"] = "0x062e66477faf219f25d27dced647bf57c3107d52";
    CronosToken["WETH"] = "0xe44fd7fcb2b1581822d0c862b68222998a0c299a";
})(CronosToken || (CronosToken = {}));
