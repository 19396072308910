var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { FixedNumber } from 'ethers';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TokenSelector from './TokenSelector';
import TokenImage from './TokenImage';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        labelContainer: {
            top: theme.spacing(2),
            position: 'absolute',
            display: 'flex',
            right: theme.spacing(1.75),
            left: theme.spacing(1.75),
            fontSize: '75%',
        },
        hiddenInput: {
            width: 0,
            paddingRight: theme.spacing(1),
        },
        fullWidth: {
            width: '100%',
        },
        spacer: {
            flexGrow: 1,
        },
        inputRoot: {
            paddingTop: theme.spacing(4),
            borderRadius: theme.spacing(3),
        },
        input: {
            fontSize: theme.spacing(3),
        },
        inputLabel: {
            width: '100%',
        },
        tokenSelectButton: {
            minWidth: theme.spacing(15),
        },
    });
});
var TokenInput = function (props) {
    var classes = useStyles({});
    var t = useTranslation().t;
    var amountFN = useRef(FixedNumber.fromString('0'));
    var balanceFN = useRef(FixedNumber.fromString('0'));
    var _a = useState(false), openTokenSelector = _a[0], setOpenTokenSelector = _a[1];
    var _b = useState(false), inputError = _b[0], setInputError = _b[1];
    useEffect(function () {
        amountFN.current = FixedNumber.fromString(props.amount || '0');
        updateInputError();
    }, [props.amount]);
    useEffect(function () {
        if (props.balance) {
            balanceFN.current = FixedNumber.fromString(props.balance);
            updateInputError();
        }
    }, [props.balance, inputError]);
    var updateInputError = function () {
        var hasBalance = Boolean(props.balance);
        var hasError = hasBalance && balanceFN.current.subUnsafe(amountFN.current).isNegative();
        if (inputError != hasError) {
            setInputError(hasError);
            if (props.onChangeError) {
                props.onChangeError(hasError);
            }
        }
    };
    var handleChangeInputAmount = function (event) {
        var amount = event.target.value;
        amountFN.current = FixedNumber.fromString(amount || '0');
        props.onChangeAmount(amount, amountFN.current);
    };
    var handleSelectToken = function (tokenInfo) {
        props.onChangeToken(tokenInfo);
    };
    var handleOpenTokenSelector = function () {
        setOpenTokenSelector(true);
    };
    var handleCloseTokenSelector = function () {
        setOpenTokenSelector(false);
    };
    var handleClickMax = function () {
        if (!balanceFN.current.isZero()) {
            var amount = balanceFN.current.toString();
            props.onChangeAmount(amount, balanceFN.current);
        }
    };
    var inputErrorText = (inputError) ? t('NOT_ENOUGH_BALANCE', 'Not enough balance') : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(TokenSelector, { tokens: props.tokens, allowUserDefinedTokens: props.allowUserDefinedTokens, chainId: props.chainId, open: openTokenSelector, onSelectToken: handleSelectToken, onCloseDialog: handleCloseTokenSelector }),
        React.createElement(TextField, { id: "value", value: props.amount, onChange: handleChangeInputAmount, variant: "outlined", fullWidth: true, inputMode: "decimal", placeholder: "0", error: inputError, helperText: inputErrorText, disabled: Boolean(props.disabled), InputLabelProps: {
                classes: {
                    root: classes.inputLabel,
                },
                shrink: true,
            }, InputProps: {
                autoFocus: props.autoFocus,
                disabled: Boolean(props.hiddenInput) || Boolean(props.disabled),
                classes: {
                    root: (props.label) ? classes.inputRoot : '',
                    input: (props.hiddenInput) ? "".concat(classes.input, " ").concat(classes.hiddenInput) : classes.input,
                },
                startAdornment: (React.createElement(InputAdornment, { position: "start", className: (props.hiddenInput) ? classes.fullWidth : '' },
                    React.createElement("div", { className: classes.labelContainer },
                        React.createElement("div", null, props.label),
                        React.createElement("div", { className: classes.spacer }),
                        React.createElement("div", null, props.balance && t('SWAP_BALANCE', 'Balance: {{balance}}', { balance: props.balance }))),
                    React.createElement(Button, { color: "primary", size: "small", variant: "text", onClick: handleOpenTokenSelector, className: classes.tokenSelectButton, fullWidth: props.hiddenInput, disabled: Boolean(props.disabled) },
                        props.token &&
                            React.createElement(TokenImage, { showSymbol: true, token: props.token, chainId: props.chainId }),
                        !props.token && t('SELECT_TOKEN', 'Select token'),
                        React.createElement("div", { className: classes.spacer }),
                        React.createElement(ArrowDropDownIcon, null)))),
                endAdornment: props.balance ? (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(Button, { color: "primary", size: "small", variant: "outlined", onClick: handleClickMax, disabled: Boolean(props.disabled) }, t('MAX', 'Max')))) : undefined,
            } })));
};
var mapStateToProps = function (state) { return (__assign({}, state)); };
export default connect(mapStateToProps)(TokenInput);
