import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initI18n } from './i18n/config';
import App from './app/App';
import { ConnectionProvider } from './app/ConnectionProvider';
import store from './store/store';
import { isSwapEmbeddedUrl } from './app/utils';
import qs from 'qs';
import { renderSwap } from './app/swapBundle';
initI18n();
var rootElement = document.querySelector('#root');
if (rootElement) {
    if (isSwapEmbeddedUrl()) {
        var queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
        renderSwap(rootElement, {
            chain: queryString.chain,
            inputToken: queryString.inputToken,
            outputToken: queryString.outputToken,
            slippage: queryString.slippage,
            allowedChains: queryString.allowedChains,
            referrer: queryString.referrer,
            fixedOutput: queryString.fixedOutput,
            mode: queryString.mode,
        });
    }
    else {
        ReactDOM.render(React.createElement(Provider, { store: store },
            React.createElement(ConnectionProvider, null,
                React.createElement(App, null))), rootElement);
    }
}
